import { z } from "zod";
import { ExternalServicingActorsSchema, InternalServicingActorsSchema } from "./servicing_actors";
import { HealthcareEntityCoverageSchema, HealthcareEntityCyberCoverageSchema, HealthcareEntitySCMMACoverageSchema, HealthcareEntityPCFPACoverageSchema, } from "./entity/coverage";
import { EntityInsuredItemSchema } from "./entity/insured_item";
import { EntityListOfRisksForWebAPISchema } from "./entity/risk";
import { EntityMidlevelCoverageSchema, EntityMidlevelCyberCoverageSchema, EntityMidlevelSCMMACoverageSchema, EntityMidlevelPCFPAICoverageSchema, } from "./midlevel/coverage";
import { MidlevelPremiumFactorSchema } from "./midlevel/premium_factor";
import { MidlevelInsuredItemSchema } from "./midlevel/insured_item";
import { EntityProviderCoverageSchema, EntityProviderCyberCoverageSchema, EntityProviderSCMMACoverageSchema, EntityProviderPCFPAICoverageSchema, } from "./provider/coverage";
import { ProviderInsuredItemSchema } from "./provider/insured_item";
import { EntityAmaProviderPremiumFactorSchema, ProviderPremiumFactorSchema, } from "./provider/premium_factor";
export var EntityCoverageSchema = z.union([
    HealthcareEntityCoverageSchema,
    HealthcareEntityCyberCoverageSchema,
    HealthcareEntitySCMMACoverageSchema,
    HealthcareEntityPCFPACoverageSchema,
    EntityMidlevelCoverageSchema,
    EntityMidlevelCyberCoverageSchema,
    EntityMidlevelSCMMACoverageSchema,
    EntityMidlevelPCFPAICoverageSchema,
    EntityProviderCoverageSchema,
    EntityProviderCyberCoverageSchema,
    EntityProviderSCMMACoverageSchema,
    EntityProviderPCFPAICoverageSchema,
]);
export var EntityPremiumFactorSchema = z.union([
    ProviderPremiumFactorSchema,
    EntityAmaProviderPremiumFactorSchema,
    MidlevelPremiumFactorSchema,
]);
export var CreateEntityQuoteInputSchema = z.object({
    PolicyJSON: z.object({
        BillingFrequency: z.literal("3"),
        EffectiveDate: z.string(),
        ExpirationDate: z.string(),
        "MPL-ExcessMinimumPremium": z.literal("1901"),
        ListofProductVersion: z.tuple([
            z.object({ ProductVersionId: z.literal("Admitted - Medical Professional Liability Fleet") }),
        ]),
        ListofExternalServicingActors: ExternalServicingActorsSchema,
        ListofInternalServicingActors: InternalServicingActorsSchema,
        ListOfRisksForWebAPI: EntityListOfRisksForWebAPISchema,
        ListofInsuredItems: z.array(EntityInsuredItemSchema.or(ProviderInsuredItemSchema).or(MidlevelInsuredItemSchema)),
        ListofCoverages: z.array(EntityCoverageSchema),
        MPLPolicyForm: z.literal("1901"),
        MPLPolicyRenewalType: z.literal("1902"),
        MPLPolicyTransaction: z.literal("1901"),
        PolicyTerm: z.literal("1"),
        PolicyType: z.literal("1901"),
        PolicyHolderNumber: z.string().min(1),
        QuoteNumber: z.literal(1),
        PolicyTypeAsLongLabel: z.literal("1901"),
        ListofPremiumFactors: z.array(EntityPremiumFactorSchema),
        AgentBillType: z.literal("3"),
        ModelID: z.string(),
        InquiryIDQuote: z.string(),
        QuoteTargetPremium: z.number(),
        QuoteExpiringPremium: z.number(),
        QuoteRenewalPremium: z.number(),
        PreviousCarrier: z.string(),
        InitialSubmissionFull: z.boolean(),
        "Status–UserAssigned": z.string().optional(),
        DeclineQuoteReason: z.string().optional(),
        OtherDeclineReason: z.string().optional(),
    }),
});
