import { gql } from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
};

export type AddMarkdownMessageAction = {
  __typename?: 'AddMarkdownMessageAction';
  message: Scalars['String']['output'];
  type: SlackEventType;
};

export type AddMessageAction = {
  __typename?: 'AddMessageAction';
  message: Scalars['String']['output'];
  type: SlackEventType;
};

export type AddReactionAction = {
  __typename?: 'AddReactionAction';
  messageTs: Scalars['String']['output'];
  reaction: Scalars['String']['output'];
  type: SlackEventType;
};

export type Address = {
  __typename?: 'Address';
  city?: Maybe<Scalars['String']['output']>;
  countyFips?: Maybe<Scalars['String']['output']>;
  countyName?: Maybe<Scalars['String']['output']>;
  isSecondary?: Maybe<Scalars['Boolean']['output']>;
  secondary?: Maybe<Scalars['String']['output']>;
  state?: Maybe<Scalars['String']['output']>;
  streetName?: Maybe<Scalars['String']['output']>;
  zip?: Maybe<Scalars['String']['output']>;
};

export type AddressInput = {
  city?: InputMaybe<Scalars['String']['input']>;
  countyFips?: InputMaybe<Scalars['String']['input']>;
  countyName?: InputMaybe<Scalars['String']['input']>;
  isSecondary?: InputMaybe<Scalars['Boolean']['input']>;
  secondary?: InputMaybe<Scalars['String']['input']>;
  state?: InputMaybe<Scalars['String']['input']>;
  streetName?: InputMaybe<Scalars['String']['input']>;
  zip?: InputMaybe<Scalars['String']['input']>;
};

export type AddressUpdateInput = {
  city?: InputMaybe<Scalars['String']['input']>;
  countyFips?: InputMaybe<Scalars['String']['input']>;
  countyName?: InputMaybe<Scalars['String']['input']>;
  isSecondary?: InputMaybe<Scalars['Boolean']['input']>;
  secondary?: InputMaybe<Scalars['String']['input']>;
  state?: InputMaybe<Scalars['String']['input']>;
  streetName?: InputMaybe<Scalars['String']['input']>;
  zip?: InputMaybe<Scalars['String']['input']>;
};

export type Agency = {
  __typename?: 'Agency';
  agencyId?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

export type AgencyInput = {
  agencyId?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

export type AgencyUpdateInput = {
  agencyId?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

export type BoardAction = {
  __typename?: 'BoardAction';
  details: Scalars['String']['output'];
  npi: Scalars['String']['output'];
  npiSource?: Maybe<Scalars['String']['output']>;
  orderDate?: Maybe<Scalars['String']['output']>;
  reportingEntity: Scalars['String']['output'];
  severity?: Maybe<Scalars['Int']['output']>;
  state?: Maybe<Scalars['String']['output']>;
  summary?: Maybe<Scalars['String']['output']>;
  termLength?: Maybe<Scalars['String']['output']>;
};

export type BoardActionInput = {
  details?: InputMaybe<Scalars['String']['input']>;
  npi?: InputMaybe<Scalars['String']['input']>;
  npiSource?: InputMaybe<Scalars['String']['input']>;
  orderDate?: InputMaybe<Scalars['String']['input']>;
  reportingEntity?: InputMaybe<Scalars['String']['input']>;
  severity?: InputMaybe<Scalars['Int']['input']>;
  state?: InputMaybe<Scalars['String']['input']>;
  summary?: InputMaybe<Scalars['String']['input']>;
  termLength?: InputMaybe<Scalars['String']['input']>;
};

export type BoardActionRaw = {
  __typename?: 'BoardActionRaw';
  DETAILS: Scalars['String']['output'];
  NPI: Scalars['String']['output'];
  NPI_SOURCE?: Maybe<Scalars['String']['output']>;
  ORDER_DATE: Scalars['String']['output'];
  REPORTING_ENTITY: Scalars['String']['output'];
  SEVERITY?: Maybe<Scalars['Int']['output']>;
  SUMMARY: Scalars['String']['output'];
};

export type BoardActionUpdateInput = {
  details?: InputMaybe<Scalars['String']['input']>;
  npi?: InputMaybe<Scalars['String']['input']>;
  npiSource?: InputMaybe<Scalars['String']['input']>;
  orderDate?: InputMaybe<Scalars['String']['input']>;
  reportingEntity?: InputMaybe<Scalars['String']['input']>;
  severity?: InputMaybe<Scalars['Int']['input']>;
  state?: InputMaybe<Scalars['String']['input']>;
  summary?: InputMaybe<Scalars['String']['input']>;
  termLength?: InputMaybe<Scalars['String']['input']>;
};

export type CreateEntityCustomerPayload = {
  __typename?: 'CreateEntityCustomerPayload';
  address: SunlightCreationAddressPayload;
  currentPremium?: Maybe<Scalars['Float']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  entityCharges?: Maybe<LimitType>;
  id: Scalars['ID']['output'];
  limit?: Maybe<Limit>;
  name: Scalars['String']['output'];
  npi?: Maybe<Scalars['String']['output']>;
  retroDate: Scalars['String']['output'];
  simplifyCustomerId?: Maybe<Scalars['String']['output']>;
  targetPremium?: Maybe<Scalars['Float']['output']>;
};

export type CreateEntityQuotePayload = {
  __typename?: 'CreateEntityQuotePayload';
  address: SunlightCreationAddressPayload;
  currentPremium?: Maybe<Scalars['Float']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  entityCharges: LimitType;
  id: Scalars['ID']['output'];
  limit: Limit;
  name: Scalars['String']['output'];
  npi?: Maybe<Scalars['String']['output']>;
  retroDate: Scalars['String']['output'];
  simplifyCustomerId: Scalars['String']['output'];
  targetPremium?: Maybe<Scalars['Float']['output']>;
};

export type CreateEventInput = {
  createdAt?: InputMaybe<Scalars['String']['input']>;
  createdBy: Scalars['String']['input'];
  eventId?: InputMaybe<Scalars['ID']['input']>;
  payload?: InputMaybe<Scalars['String']['input']>;
  retriesRemaining?: InputMaybe<Scalars['Int']['input']>;
  state: EventState;
  stateReason?: InputMaybe<Scalars['String']['input']>;
  submissionId: Scalars['String']['input'];
  type: EventType;
  updatedAt?: InputMaybe<Scalars['String']['input']>;
  updatedBy?: InputMaybe<Scalars['String']['input']>;
};

export type CreateMidlevelCustomerPayload = {
  __typename?: 'CreateMidlevelCustomerPayload';
  address: SunlightCreationAddressPayload;
  email?: Maybe<Scalars['String']['output']>;
  firstName: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  indigoSpecialty: Scalars['String']['output'];
  lastName: Scalars['String']['output'];
  licenseNumber?: Maybe<Scalars['String']['output']>;
  licenseState?: Maybe<Scalars['String']['output']>;
  limit?: Maybe<Limit>;
  limitType?: Maybe<LimitType>;
  npi?: Maybe<Scalars['String']['output']>;
  retroDate?: Maybe<Scalars['String']['output']>;
  simplifyCustomerId?: Maybe<Scalars['String']['output']>;
  specialty?: Maybe<Scalars['String']['output']>;
  suffix?: Maybe<Scalars['String']['output']>;
};

export type CreateMidlevelQuotePayload = {
  __typename?: 'CreateMidlevelQuotePayload';
  address: SunlightCreationAddressPayload;
  email?: Maybe<Scalars['String']['output']>;
  firstName: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  indigoSpecialty: Scalars['String']['output'];
  lastName: Scalars['String']['output'];
  licenseNumber?: Maybe<Scalars['String']['output']>;
  licenseState?: Maybe<Scalars['String']['output']>;
  limit: Limit;
  limitType: LimitType;
  npi?: Maybe<Scalars['String']['output']>;
  retroDate: Scalars['String']['output'];
  simplifyCustomerId: Scalars['String']['output'];
  specialty?: Maybe<Scalars['String']['output']>;
  suffix: Scalars['String']['output'];
};

export type CreateProviderCustomerPayload = {
  __typename?: 'CreateProviderCustomerPayload';
  address: SunlightCreationAddressPayload;
  ama?: Maybe<Scalars['Boolean']['output']>;
  boardActions?: Maybe<Array<BoardAction>>;
  deductible?: Maybe<Deductible>;
  email?: Maybe<Scalars['String']['output']>;
  excludedNpi?: Maybe<Scalars['Boolean']['output']>;
  expiringPremium?: Maybe<Scalars['Float']['output']>;
  firstName: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  ignoreLocationInconsistency?: Maybe<Scalars['Boolean']['output']>;
  indigoSpecialty: Scalars['String']['output'];
  iqvia?: Maybe<IqviaData>;
  lastName: Scalars['String']['output'];
  licenseNumber?: Maybe<Scalars['String']['output']>;
  licenseState?: Maybe<Scalars['String']['output']>;
  limit?: Maybe<Limit>;
  limitType?: Maybe<LimitType>;
  mrs?: Maybe<Scalars['Float']['output']>;
  newToPractice?: Maybe<Scalars['Float']['output']>;
  npi?: Maybe<Scalars['String']['output']>;
  nppesCity?: Maybe<Scalars['String']['output']>;
  nppesSpecialty?: Maybe<Scalars['String']['output']>;
  nppesState?: Maybe<Scalars['String']['output']>;
  partTimePractice?: Maybe<Scalars['String']['output']>;
  residencyAndFellowshipPrograms?: Maybe<Scalars['Float']['output']>;
  retiredVolunteered?: Maybe<Scalars['Boolean']['output']>;
  retroDate: Scalars['String']['output'];
  scheduledRatingFactor?: Maybe<Scalars['Float']['output']>;
  simplifyCustomerId?: Maybe<Scalars['String']['output']>;
  specialty?: Maybe<Scalars['String']['output']>;
  stateLicenses?: Maybe<Array<License>>;
  suffix?: Maybe<Scalars['String']['output']>;
  targetPremium?: Maybe<Scalars['Float']['output']>;
  teachingPhysicians?: Maybe<Scalars['Float']['output']>;
};

export type CreateProviderQuotePayload = {
  __typename?: 'CreateProviderQuotePayload';
  address: SunlightCreationAddressPayload;
  ama: Scalars['Boolean']['output'];
  boardActions?: Maybe<Array<BoardAction>>;
  deductible: Deductible;
  email?: Maybe<Scalars['String']['output']>;
  excludedNpi?: Maybe<Scalars['Boolean']['output']>;
  expiringPremium?: Maybe<Scalars['Float']['output']>;
  firstName: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  ignoreLocationInconsistency?: Maybe<Scalars['Boolean']['output']>;
  indigoSpecialty: Scalars['String']['output'];
  iqvia?: Maybe<IqviaData>;
  lastName: Scalars['String']['output'];
  licenseNumber?: Maybe<Scalars['String']['output']>;
  licenseState?: Maybe<Scalars['String']['output']>;
  limit: Limit;
  limitType: LimitType;
  mrs?: Maybe<Scalars['Float']['output']>;
  newToPractice: Scalars['Float']['output'];
  npi: Scalars['String']['output'];
  nppesCity?: Maybe<Scalars['String']['output']>;
  nppesSpecialty?: Maybe<Scalars['String']['output']>;
  nppesState?: Maybe<Scalars['String']['output']>;
  partTimePractice?: Maybe<Scalars['String']['output']>;
  residencyAndFellowshipPrograms: Scalars['Float']['output'];
  retiredVolunteered: Scalars['Boolean']['output'];
  retroDate: Scalars['String']['output'];
  scheduledRatingFactor?: Maybe<Scalars['Float']['output']>;
  simplifyCustomerId: Scalars['String']['output'];
  specialty?: Maybe<Scalars['String']['output']>;
  stateLicenses?: Maybe<Array<License>>;
  suffix: Scalars['String']['output'];
  targetPremium?: Maybe<Scalars['Float']['output']>;
  teachingPhysicians?: Maybe<Scalars['Float']['output']>;
};

export type CreateQuoteAgencyPayload = {
  __typename?: 'CreateQuoteAgencyPayload';
  agencyId: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

export type CreateQuoteModelInfoPayload = {
  __typename?: 'CreateQuoteModelInfoPayload';
  modelId: Scalars['String']['output'];
  modelVersion: Scalars['String']['output'];
};

export type CreateQuoteOrchestrationEventPayload = {
  __typename?: 'CreateQuoteOrchestrationEventPayload';
  sunlightUserId: Scalars['String']['output'];
};

export type CreateQuoteProducerPayload = {
  __typename?: 'CreateQuoteProducerPayload';
  agency: CreateQuoteAgencyPayload;
  id: Scalars['ID']['output'];
  manager?: Maybe<Scalars['String']['output']>;
  managerId?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  producerId?: Maybe<Scalars['String']['output']>;
};

export type CreateQuoteQuotePropertiesPayload = {
  __typename?: 'CreateQuoteQuotePropertiesPayload';
  commissionRate?: Maybe<Scalars['String']['output']>;
  contactEmail?: Maybe<Scalars['String']['output']>;
  currentCarrier: Scalars['String']['output'];
  effectiveDate: Scalars['String']['output'];
  expiringPremium: Scalars['Float']['output'];
  initialSubmissionFull: Scalars['Boolean']['output'];
  modelInfo: CreateQuoteModelInfoPayload;
  renewalPremium: Scalars['Float']['output'];
  submissionEmail?: Maybe<Scalars['String']['output']>;
  sunlightUserId: Scalars['String']['output'];
  targetPremium: Scalars['Float']['output'];
};

export type CreateQuoteSubmissionPayload = {
  __typename?: 'CreateQuoteSubmissionPayload';
  declination?: Maybe<Declination>;
  entities: Array<CreateEntityQuotePayload>;
  id: Scalars['ID']['output'];
  midlevels: Array<CreateMidlevelQuotePayload>;
  notes?: Maybe<Scalars['String']['output']>;
  producer: CreateQuoteProducerPayload;
  providers: Array<CreateProviderQuotePayload>;
  quoteProperties: CreateQuoteQuotePropertiesPayload;
  srfAdjustment: Scalars['Float']['output'];
  status?: Maybe<StatusType>;
  underwriterNotes?: Maybe<Array<Note>>;
};

export type CreateSunlightCustomerEventPayload = {
  __typename?: 'CreateSunlightCustomerEventPayload';
  email?: Maybe<Scalars['String']['output']>;
  entity?: Maybe<CreateEntityCustomerPayload>;
  midlevel?: Maybe<CreateMidlevelCustomerPayload>;
  provider?: Maybe<CreateProviderCustomerPayload>;
  type: SunlightCustomerType;
};

export type Declination = {
  __typename?: 'Declination';
  emailAddresses?: Maybe<Emails>;
  otherReason?: Maybe<Scalars['String']['output']>;
  reason?: Maybe<DeclinationReason>;
};

export type DeclinationInput = {
  emailAddresses?: InputMaybe<EmailsInput>;
  otherReason?: InputMaybe<Scalars['String']['input']>;
  reason?: InputMaybe<DeclinationReason>;
};

export enum DeclinationReason {
  BoardAction = 'BoardAction',
  Claims = 'Claims',
  MrsScoreTooHigh = 'MRSScoreTooHigh',
  NotEnoughInformation = 'NotEnoughInformation',
  OtherMultiple = 'OtherMultiple',
  PracticeProfile = 'PracticeProfile',
  SubmittedByAnotherBroker = 'SubmittedByAnotherBroker',
  TooManyRisks = 'TooManyRisks',
  VlExposure = 'VLExposure'
}

export enum Deductible {
  None = 'None',
  '10K' = '_10K',
  '25K' = '_25K',
  '50K' = '_50K'
}

export type DeleteEntityInput = {
  entityId: Scalars['ID']['input'];
  submissionId: Scalars['ID']['input'];
};

export type DeleteMidlevelInput = {
  midlevelId: Scalars['ID']['input'];
  submissionId: Scalars['ID']['input'];
};

export type DeleteNoteInput = {
  noteId: Scalars['ID']['input'];
  submissionId: Scalars['ID']['input'];
};

export type DeleteProviderInput = {
  providerId: Scalars['ID']['input'];
  submissionId: Scalars['ID']['input'];
};

export type Emails = {
  __typename?: 'Emails';
  bcc?: Maybe<Scalars['String']['output']>;
  cc?: Maybe<Scalars['String']['output']>;
  to?: Maybe<Scalars['String']['output']>;
};

export type EmailsInput = {
  bcc?: InputMaybe<Scalars['String']['input']>;
  cc?: InputMaybe<Scalars['String']['input']>;
  to?: InputMaybe<Scalars['String']['input']>;
};

export type Entity = {
  __typename?: 'Entity';
  address?: Maybe<Address>;
  currentPremium?: Maybe<Scalars['Float']['output']>;
  entityCharges?: Maybe<LimitType>;
  id: Scalars['ID']['output'];
  limit?: Maybe<Limit>;
  name?: Maybe<Scalars['String']['output']>;
  npi?: Maybe<Scalars['String']['output']>;
  retroDate?: Maybe<Scalars['String']['output']>;
  simplifyCustomerId?: Maybe<Scalars['String']['output']>;
  targetPremium?: Maybe<Scalars['Float']['output']>;
};

export type EntityInput = {
  address?: InputMaybe<AddressInput>;
  currentPremium?: InputMaybe<Scalars['Float']['input']>;
  entityCharges?: InputMaybe<LimitType>;
  id: Scalars['ID']['input'];
  limit?: InputMaybe<Limit>;
  name?: InputMaybe<Scalars['String']['input']>;
  npi?: InputMaybe<Scalars['String']['input']>;
  retroDate?: InputMaybe<Scalars['String']['input']>;
  simplifyCustomerId?: InputMaybe<Scalars['String']['input']>;
  targetPremium?: InputMaybe<Scalars['Float']['input']>;
};

export type EntityUpdateInput = {
  address?: InputMaybe<AddressUpdateInput>;
  currentPremium?: InputMaybe<Scalars['Float']['input']>;
  entityCharges?: InputMaybe<LimitType>;
  id?: InputMaybe<Scalars['ID']['input']>;
  limit?: InputMaybe<Limit>;
  name?: InputMaybe<Scalars['String']['input']>;
  npi?: InputMaybe<Scalars['String']['input']>;
  retroDate?: InputMaybe<Scalars['String']['input']>;
  simplifyCustomerId?: InputMaybe<Scalars['String']['input']>;
  targetPremium?: InputMaybe<Scalars['Float']['input']>;
};

export type Event = {
  __typename?: 'Event';
  createdAt: Scalars['String']['output'];
  createdBy: Scalars['String']['output'];
  eventId: Scalars['ID']['output'];
  payload?: Maybe<EventPayload>;
  retriesRemaining: Scalars['Int']['output'];
  state: EventState;
  stateReason?: Maybe<Scalars['String']['output']>;
  submissionId: Scalars['String']['output'];
  type: EventType;
  updatedAt?: Maybe<Scalars['String']['output']>;
  updatedBy?: Maybe<Scalars['String']['output']>;
};

export type EventPayload = CreateQuoteOrchestrationEventPayload | CreateQuoteSubmissionPayload | CreateSunlightCustomerEventPayload | MrsSearchEventPayload | NpiSearchEventPayload | ProcessDocumentEventPayload | SlackEventPayload | TableImportEventPayload;

export enum EventState {
  Complete = 'COMPLETE',
  Failed = 'FAILED',
  Processing = 'PROCESSING',
  Queued = 'QUEUED'
}

export enum EventType {
  CreateQuoteOrchestration = 'CREATE_QUOTE_ORCHESTRATION',
  CreateSlCustomer = 'CREATE_SL_CUSTOMER',
  CreateSlQuote = 'CREATE_SL_QUOTE',
  ModelRatingSearch = 'MODEL_RATING_SEARCH',
  NpiSearch = 'NPI_SEARCH',
  ProcessDocument = 'PROCESS_DOCUMENT',
  Slack = 'SLACK',
  TableImport = 'TABLE_IMPORT'
}

export enum ImportedDdbTable {
  ExcludedNpis = 'EXCLUDED_NPIS',
  IqviaAddressData = 'IQVIA_ADDRESS_DATA',
  IqviaAffiliationData = 'IQVIA_AFFILIATION_DATA',
  PhysiciansBoardActions = 'PHYSICIANS_BOARD_ACTIONS',
  PhysiciansStateLicensures = 'PHYSICIANS_STATE_LICENSURES',
  PredictionMeans = 'PREDICTION_MEANS',
  ScoredCompilations = 'SCORED_COMPILATIONS'
}

export type InsightsData = {
  __typename?: 'InsightsData';
  averageAdjustedMrs: Scalars['Float']['output'];
  averageMrs: Scalars['Float']['output'];
  bookedSrf: Scalars['Float']['output'];
  calculatedSrfAdjustment: Scalars['Float']['output'];
  providers?: Maybe<Array<ProviderInsightsData>>;
  scheduledRatingFactor: Scalars['Float']['output'];
};

export type IqviaData = {
  __typename?: 'IqviaData';
  location?: Maybe<IqviaLocation>;
};

export type IqviaDataInput = {
  location?: InputMaybe<IqviaLocationInput>;
};

export type IqviaDataUpdateInput = {
  location?: InputMaybe<IqviaLocationUpdateInput>;
};

export type IqviaLocation = {
  __typename?: 'IqviaLocation';
  countyFips: Scalars['String']['output'];
  countyName: Scalars['String']['output'];
};

export type IqviaLocationInput = {
  countyFips?: InputMaybe<Scalars['String']['input']>;
  countyName?: InputMaybe<Scalars['String']['input']>;
};

export type IqviaLocationUpdateInput = {
  countyFips?: InputMaybe<Scalars['String']['input']>;
  countyName?: InputMaybe<Scalars['String']['input']>;
};

export type License = {
  __typename?: 'License';
  expirationDate?: Maybe<Scalars['String']['output']>;
  issueDate?: Maybe<Scalars['String']['output']>;
  number?: Maybe<Scalars['String']['output']>;
  state?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['String']['output']>;
};

export type LicenseInput = {
  expirationDate?: InputMaybe<Scalars['String']['input']>;
  issueDate?: InputMaybe<Scalars['String']['input']>;
  number?: InputMaybe<Scalars['String']['input']>;
  state?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
};

export type LicenseRaw = {
  __typename?: 'LicenseRaw';
  EXPIRATION_DATE: Scalars['String']['output'];
  FIRST_NAME: Scalars['String']['output'];
  ISSUE_DATE: Scalars['String']['output'];
  LAST_NAME: Scalars['String']['output'];
  NPI?: Maybe<Scalars['Int']['output']>;
  PROVIDER_LICENSE_NUMBER: Scalars['String']['output'];
  PROVIDER_LICENSE_STATE: Scalars['String']['output'];
  STATUS: Scalars['String']['output'];
};

export type LicenseUpdateInput = {
  expirationDate?: InputMaybe<Scalars['String']['input']>;
  issueDate?: InputMaybe<Scalars['String']['input']>;
  number?: InputMaybe<Scalars['String']['input']>;
  state?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
};

export enum Limit {
  '1M_3M' = '_1M_3M',
  '100K_300K' = '_100K_300K',
  '200K_600K' = '_200K_600K',
  '250K_750K' = '_250K_750K',
  '500K_1500K' = '_500K_1500K'
}

export enum LimitType {
  Separate = 'Separate',
  Shared = 'Shared'
}

export type Midlevel = {
  __typename?: 'Midlevel';
  address?: Maybe<Address>;
  firstName?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  indigoSpecialty?: Maybe<Scalars['String']['output']>;
  lastName?: Maybe<Scalars['String']['output']>;
  licenseNumber?: Maybe<Scalars['String']['output']>;
  licenseState?: Maybe<Scalars['String']['output']>;
  limit?: Maybe<Limit>;
  limitType?: Maybe<LimitType>;
  npi?: Maybe<Scalars['String']['output']>;
  previewPremium?: Maybe<Scalars['Float']['output']>;
  retroDate?: Maybe<Scalars['String']['output']>;
  simplifyCustomerId?: Maybe<Scalars['String']['output']>;
  specialty?: Maybe<Scalars['String']['output']>;
  suffix?: Maybe<Scalars['String']['output']>;
};

export type MidlevelInput = {
  address?: InputMaybe<AddressInput>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  indigoSpecialty?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  licenseNumber?: InputMaybe<Scalars['String']['input']>;
  licenseState?: InputMaybe<Scalars['String']['input']>;
  limit?: InputMaybe<Limit>;
  limitType?: InputMaybe<LimitType>;
  npi?: InputMaybe<Scalars['String']['input']>;
  previewPremium?: InputMaybe<Scalars['Float']['input']>;
  retroDate?: InputMaybe<Scalars['String']['input']>;
  simplifyCustomerId?: InputMaybe<Scalars['String']['input']>;
  specialty?: InputMaybe<Scalars['String']['input']>;
  suffix?: InputMaybe<Scalars['String']['input']>;
};

export type MidlevelUpdateInput = {
  address?: InputMaybe<AddressUpdateInput>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  indigoSpecialty?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  licenseNumber?: InputMaybe<Scalars['String']['input']>;
  licenseState?: InputMaybe<Scalars['String']['input']>;
  limit?: InputMaybe<Limit>;
  limitType?: InputMaybe<LimitType>;
  npi?: InputMaybe<Scalars['String']['input']>;
  previewPremium?: InputMaybe<Scalars['Float']['input']>;
  retroDate?: InputMaybe<Scalars['String']['input']>;
  simplifyCustomerId?: InputMaybe<Scalars['String']['input']>;
  specialty?: InputMaybe<Scalars['String']['input']>;
  suffix?: InputMaybe<Scalars['String']['input']>;
};

export type ModelInfo = {
  __typename?: 'ModelInfo';
  modelId?: Maybe<Scalars['String']['output']>;
  modelVersion?: Maybe<Scalars['String']['output']>;
};

export type ModelInfoInput = {
  modelId?: InputMaybe<Scalars['String']['input']>;
  modelVersion?: InputMaybe<Scalars['String']['input']>;
};

export type ModelInfoUpdateInput = {
  modelId?: InputMaybe<Scalars['String']['input']>;
  modelVersion?: InputMaybe<Scalars['String']['input']>;
};

export type MrsSearchEventPayload = {
  __typename?: 'MrsSearchEventPayload';
  slackMessageTs?: Maybe<Scalars['String']['output']>;
  submissionId: Scalars['String']['output'];
};

export type Mutation = {
  __typename?: 'Mutation';
  addDeclination?: Maybe<Scalars['ID']['output']>;
  addQuoteCreationDetails?: Maybe<Scalars['ID']['output']>;
  addSimplifyCustomerId?: Maybe<Scalars['ID']['output']>;
  calculateInsightsData?: Maybe<InsightsData>;
  createEntity?: Maybe<Scalars['ID']['output']>;
  createEvent?: Maybe<Scalars['ID']['output']>;
  createMidlevel?: Maybe<Scalars['ID']['output']>;
  createNote?: Maybe<Scalars['ID']['output']>;
  createProducer?: Maybe<Scalars['ID']['output']>;
  createProvider?: Maybe<Scalars['ID']['output']>;
  createQuoteProperties?: Maybe<Scalars['ID']['output']>;
  deleteEntity?: Maybe<Scalars['ID']['output']>;
  deleteEvent?: Maybe<Scalars['ID']['output']>;
  deleteMidlevel?: Maybe<Scalars['ID']['output']>;
  deleteNote?: Maybe<Scalars['ID']['output']>;
  deleteProvider?: Maybe<Scalars['ID']['output']>;
  deleteSubmission?: Maybe<Scalars['ID']['output']>;
  putSubmission?: Maybe<Scalars['ID']['output']>;
  updateEntities?: Maybe<Scalars['ID']['output']>;
  updateEventRetries?: Maybe<Scalars['ID']['output']>;
  updateEventState?: Maybe<Scalars['ID']['output']>;
  updateMidlevels?: Maybe<Scalars['ID']['output']>;
  updateProducer?: Maybe<Scalars['ID']['output']>;
  updateProviders?: Maybe<Scalars['ID']['output']>;
  updateQuoteProperties?: Maybe<Scalars['ID']['output']>;
  updateSrfAdjustment?: Maybe<UpdatedSrfAdjustment>;
  updateSubmission?: Maybe<Scalars['ID']['output']>;
};


export type MutationAddDeclinationArgs = {
  declination: DeclinationInput;
  submissionId: Scalars['ID']['input'];
};


export type MutationAddQuoteCreationDetailsArgs = {
  initialPremium: Scalars['Float']['input'];
  primaryCustomerId: Scalars['String']['input'];
  simplifyQuoteId: Scalars['String']['input'];
  submissionId: Scalars['ID']['input'];
};


export type MutationAddSimplifyCustomerIdArgs = {
  id: Scalars['ID']['input'];
  simplifyCustomerId: Scalars['String']['input'];
  submissionId: Scalars['ID']['input'];
};


export type MutationCalculateInsightsDataArgs = {
  submissionId: Scalars['ID']['input'];
};


export type MutationCreateEntityArgs = {
  entity: EntityInput;
  submissionId: Scalars['ID']['input'];
};


export type MutationCreateEventArgs = {
  input: CreateEventInput;
};


export type MutationCreateMidlevelArgs = {
  midlevel: MidlevelInput;
  submissionId: Scalars['ID']['input'];
};


export type MutationCreateNoteArgs = {
  note: NoteInput;
  submissionId: Scalars['ID']['input'];
};


export type MutationCreateProducerArgs = {
  producer: ProducerInput;
  submissionId: Scalars['ID']['input'];
};


export type MutationCreateProviderArgs = {
  provider: ProviderInput;
  submissionId: Scalars['ID']['input'];
};


export type MutationCreateQuotePropertiesArgs = {
  quoteProperties: QuotePropertiesInput;
  submissionId: Scalars['ID']['input'];
};


export type MutationDeleteEntityArgs = {
  input: DeleteEntityInput;
};


export type MutationDeleteEventArgs = {
  eventId: Scalars['ID']['input'];
  submissionId: Scalars['String']['input'];
};


export type MutationDeleteMidlevelArgs = {
  input: DeleteMidlevelInput;
};


export type MutationDeleteNoteArgs = {
  input: DeleteNoteInput;
};


export type MutationDeleteProviderArgs = {
  input: DeleteProviderInput;
};


export type MutationDeleteSubmissionArgs = {
  id: Scalars['ID']['input'];
};


export type MutationPutSubmissionArgs = {
  input: SubmissionInput;
};


export type MutationUpdateEntitiesArgs = {
  entities?: InputMaybe<Array<EntityUpdateInput>>;
  submissionId: Scalars['ID']['input'];
};


export type MutationUpdateEventRetriesArgs = {
  eventId: Scalars['ID']['input'];
  retriesRemaining: Scalars['Int']['input'];
  submissionId: Scalars['String']['input'];
  updatedBy: Scalars['String']['input'];
};


export type MutationUpdateEventStateArgs = {
  eventId: Scalars['ID']['input'];
  state: EventState;
  stateReason?: InputMaybe<Scalars['String']['input']>;
  submissionId: Scalars['String']['input'];
  updatedBy: Scalars['String']['input'];
};


export type MutationUpdateMidlevelsArgs = {
  midlevels?: InputMaybe<Array<MidlevelUpdateInput>>;
  submissionId: Scalars['ID']['input'];
};


export type MutationUpdateProducerArgs = {
  producer: ProducerUpdateInput;
  submissionId: Scalars['ID']['input'];
};


export type MutationUpdateProvidersArgs = {
  providers?: InputMaybe<Array<ProviderUpdateInput>>;
  submissionId: Scalars['ID']['input'];
};


export type MutationUpdateQuotePropertiesArgs = {
  quoteProperties: QuotePropertiesUpdateInput;
  submissionId: Scalars['ID']['input'];
};


export type MutationUpdateSrfAdjustmentArgs = {
  calculatedSrfAdjustment?: InputMaybe<Scalars['Float']['input']>;
  srfAdjustment?: InputMaybe<Scalars['Float']['input']>;
  submissionId: Scalars['ID']['input'];
  useCalculatedSrfAdjustment?: InputMaybe<Scalars['Boolean']['input']>;
};


export type MutationUpdateSubmissionArgs = {
  input: SubmissionUpdateInput;
};

export type Note = {
  __typename?: 'Note';
  author?: Maybe<Scalars['String']['output']>;
  content?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  type?: Maybe<NoteType>;
};

export type NoteInput = {
  author?: InputMaybe<Scalars['String']['input']>;
  content?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  type?: InputMaybe<NoteType>;
};

export enum NoteType {
  Broker = 'Broker',
  Subjectivity = 'Subjectivity',
  WriteUp = 'WriteUp'
}

export type NoteUpdateInput = {
  author?: InputMaybe<Scalars['String']['input']>;
  content?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  type?: InputMaybe<NoteType>;
};

export type NpiSearchEventPayload = {
  __typename?: 'NpiSearchEventPayload';
  slackMessageTs?: Maybe<Scalars['String']['output']>;
  submissionId: Scalars['String']['output'];
};

export enum PolicyType {
  ClaimsMade = 'ClaimsMade',
  Occurrence = 'Occurrence'
}

export type PreCreationEvent = {
  __typename?: 'PreCreationEvent';
  createdBy: Scalars['String']['output'];
  payload?: Maybe<EventPayload>;
  retriesRemaining: Scalars['Int']['output'];
  submissionId: Scalars['String']['output'];
  type: EventType;
};

export type ProcessDocumentEventPayload = {
  __typename?: 'ProcessDocumentEventPayload';
  bucketName: Scalars['String']['output'];
  files: Array<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  outputPath: Scalars['String']['output'];
  slackMessageTs?: Maybe<Scalars['String']['output']>;
};

export type Producer = {
  __typename?: 'Producer';
  agency?: Maybe<Agency>;
  email?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  manager?: Maybe<Scalars['String']['output']>;
  managerId?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  producerId?: Maybe<Scalars['String']['output']>;
};

export type ProducerInput = {
  agency?: InputMaybe<AgencyInput>;
  email?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  manager?: InputMaybe<Scalars['String']['input']>;
  managerId?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  producerId?: InputMaybe<Scalars['String']['input']>;
};

export type ProducerUpdateInput = {
  agency?: InputMaybe<AgencyUpdateInput>;
  email?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  manager?: InputMaybe<Scalars['String']['input']>;
  managerId?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  producerId?: InputMaybe<Scalars['String']['input']>;
};

export type Provider = {
  __typename?: 'Provider';
  address?: Maybe<Address>;
  ama?: Maybe<Scalars['Boolean']['output']>;
  boardActions?: Maybe<Array<BoardAction>>;
  cyberOptOut?: Maybe<Scalars['Boolean']['output']>;
  deductible?: Maybe<Deductible>;
  excludedNpi?: Maybe<Scalars['Boolean']['output']>;
  expiringPremium?: Maybe<Scalars['Float']['output']>;
  firstName?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  ignoreLocationInconsistency?: Maybe<Scalars['Boolean']['output']>;
  indigoSpecialty?: Maybe<Scalars['String']['output']>;
  iqvia?: Maybe<IqviaData>;
  isUserClient?: Maybe<Scalars['Boolean']['output']>;
  lastName?: Maybe<Scalars['String']['output']>;
  licenseNumber?: Maybe<Scalars['String']['output']>;
  licenseState?: Maybe<Scalars['String']['output']>;
  limit?: Maybe<Limit>;
  limitType?: Maybe<LimitType>;
  mrs?: Maybe<Scalars['Float']['output']>;
  newToPractice?: Maybe<Scalars['Float']['output']>;
  npi?: Maybe<Scalars['String']['output']>;
  nppesCity?: Maybe<Scalars['String']['output']>;
  nppesSpecialty?: Maybe<Scalars['String']['output']>;
  nppesState?: Maybe<Scalars['String']['output']>;
  partTimePractice?: Maybe<Scalars['String']['output']>;
  previewPremium?: Maybe<Scalars['Float']['output']>;
  residencyAndFellowshipPrograms?: Maybe<Scalars['Float']['output']>;
  retiredVolunteered?: Maybe<Scalars['Boolean']['output']>;
  retroDate?: Maybe<Scalars['String']['output']>;
  scheduledRatingFactor?: Maybe<Scalars['Float']['output']>;
  simplifyCustomerId?: Maybe<Scalars['String']['output']>;
  specialty?: Maybe<Scalars['String']['output']>;
  stateLicenses?: Maybe<Array<License>>;
  suffix?: Maybe<Scalars['String']['output']>;
  targetPremium?: Maybe<Scalars['Float']['output']>;
  teachingPhysicians?: Maybe<Scalars['Float']['output']>;
};

export type ProviderInput = {
  address?: InputMaybe<AddressInput>;
  ama?: InputMaybe<Scalars['Boolean']['input']>;
  boardActions?: InputMaybe<Array<BoardActionInput>>;
  cyberOptOut?: InputMaybe<Scalars['Boolean']['input']>;
  deductible?: InputMaybe<Deductible>;
  excludedNpi?: InputMaybe<Scalars['Boolean']['input']>;
  expiringPremium?: InputMaybe<Scalars['Float']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  ignoreLocationInconsistency?: InputMaybe<Scalars['Boolean']['input']>;
  indigoSpecialty?: InputMaybe<Scalars['String']['input']>;
  iqvia?: InputMaybe<IqviaDataInput>;
  isUserClient?: InputMaybe<Scalars['Boolean']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  licenseNumber?: InputMaybe<Scalars['String']['input']>;
  licenseState?: InputMaybe<Scalars['String']['input']>;
  limit?: InputMaybe<Limit>;
  limitType?: InputMaybe<LimitType>;
  mrs?: InputMaybe<Scalars['Float']['input']>;
  newToPractice?: InputMaybe<Scalars['Float']['input']>;
  npi?: InputMaybe<Scalars['String']['input']>;
  nppesCity?: InputMaybe<Scalars['String']['input']>;
  nppesSpecialty?: InputMaybe<Scalars['String']['input']>;
  nppesState?: InputMaybe<Scalars['String']['input']>;
  partTimePractice?: InputMaybe<Scalars['String']['input']>;
  previewPremium?: InputMaybe<Scalars['Float']['input']>;
  residencyAndFellowshipPrograms?: InputMaybe<Scalars['Float']['input']>;
  retiredVolunteered?: InputMaybe<Scalars['Boolean']['input']>;
  retroDate?: InputMaybe<Scalars['String']['input']>;
  scheduledRatingFactor?: InputMaybe<Scalars['Float']['input']>;
  simplifyCustomerId?: InputMaybe<Scalars['String']['input']>;
  specialty?: InputMaybe<Scalars['String']['input']>;
  stateLicenses?: InputMaybe<Array<LicenseInput>>;
  suffix?: InputMaybe<Scalars['String']['input']>;
  targetPremium?: InputMaybe<Scalars['Float']['input']>;
  teachingPhysicians?: InputMaybe<Scalars['Float']['input']>;
};

export type ProviderInsightsData = {
  __typename?: 'ProviderInsightsData';
  id: Scalars['ID']['output'];
  localAdjustedPercent: Scalars['Float']['output'];
  srfAdjustment: Scalars['Float']['output'];
};

export type ProviderUpdateInput = {
  address?: InputMaybe<AddressUpdateInput>;
  ama?: InputMaybe<Scalars['Boolean']['input']>;
  boardActions?: InputMaybe<Array<BoardActionUpdateInput>>;
  cyberOptOut?: InputMaybe<Scalars['Boolean']['input']>;
  deductible?: InputMaybe<Deductible>;
  excludedNpi?: InputMaybe<Scalars['Boolean']['input']>;
  expiringPremium?: InputMaybe<Scalars['Float']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  ignoreLocationInconsistency?: InputMaybe<Scalars['Boolean']['input']>;
  indigoSpecialty?: InputMaybe<Scalars['String']['input']>;
  iqvia?: InputMaybe<IqviaDataUpdateInput>;
  isUserClient?: InputMaybe<Scalars['Boolean']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  licenseNumber?: InputMaybe<Scalars['String']['input']>;
  licenseState?: InputMaybe<Scalars['String']['input']>;
  limit?: InputMaybe<Limit>;
  limitType?: InputMaybe<LimitType>;
  mrs?: InputMaybe<Scalars['Float']['input']>;
  newToPractice?: InputMaybe<Scalars['Float']['input']>;
  npi?: InputMaybe<Scalars['String']['input']>;
  nppesCity?: InputMaybe<Scalars['String']['input']>;
  nppesSpecialty?: InputMaybe<Scalars['String']['input']>;
  nppesState?: InputMaybe<Scalars['String']['input']>;
  partTimePractice?: InputMaybe<Scalars['String']['input']>;
  previewPremium?: InputMaybe<Scalars['Float']['input']>;
  residencyAndFellowshipPrograms?: InputMaybe<Scalars['Float']['input']>;
  retiredVolunteered?: InputMaybe<Scalars['Boolean']['input']>;
  retroDate?: InputMaybe<Scalars['String']['input']>;
  scheduledRatingFactor?: InputMaybe<Scalars['Float']['input']>;
  simplifyCustomerId?: InputMaybe<Scalars['String']['input']>;
  specialty?: InputMaybe<Scalars['String']['input']>;
  stateLicenses?: InputMaybe<Array<LicenseUpdateInput>>;
  suffix?: InputMaybe<Scalars['String']['input']>;
  targetPremium?: InputMaybe<Scalars['Float']['input']>;
  teachingPhysicians?: InputMaybe<Scalars['Float']['input']>;
};

export type Query = {
  __typename?: 'Query';
  getEvent?: Maybe<Event>;
  getEvents?: Maybe<Array<Event>>;
  getEventsByState?: Maybe<Array<Event>>;
  getEventsByStateAndType?: Maybe<Array<Event>>;
  getEventsByType?: Maybe<Array<Event>>;
  getLastQuoteCreationEvents?: Maybe<Array<Event>>;
  getSubmission?: Maybe<Submission>;
  getSubmissions?: Maybe<Array<Submission>>;
};


export type QueryGetEventArgs = {
  eventId: Scalars['ID']['input'];
  submissionId: Scalars['String']['input'];
};


export type QueryGetEventsArgs = {
  submissionId: Scalars['String']['input'];
};


export type QueryGetEventsByStateArgs = {
  state: EventState;
  submissionId: Scalars['String']['input'];
};


export type QueryGetEventsByStateAndTypeArgs = {
  state: EventState;
  submissionId: Scalars['String']['input'];
  type: EventType;
};


export type QueryGetEventsByTypeArgs = {
  submissionId: Scalars['String']['input'];
  type: EventType;
};


export type QueryGetLastQuoteCreationEventsArgs = {
  submissionId: Scalars['String']['input'];
};


export type QueryGetSubmissionArgs = {
  id: Scalars['ID']['input'];
};


export type QueryGetSubmissionsArgs = {
  idList: Array<Scalars['ID']['input']>;
};

export type QuoteProperties = {
  __typename?: 'QuoteProperties';
  commissionRate?: Maybe<Scalars['String']['output']>;
  contactEmail?: Maybe<Scalars['String']['output']>;
  currentCarrier?: Maybe<Scalars['String']['output']>;
  effectiveDate?: Maybe<Scalars['String']['output']>;
  emailAddresses?: Maybe<Emails>;
  expiringPremium?: Maybe<Scalars['Float']['output']>;
  hasSentDocuments?: Maybe<Scalars['Boolean']['output']>;
  initialPremium?: Maybe<Scalars['Float']['output']>;
  initialSubmissionFull?: Maybe<Scalars['Boolean']['output']>;
  isQIP?: Maybe<Scalars['Boolean']['output']>;
  modelInfo?: Maybe<ModelInfo>;
  policyExclusions?: Maybe<Scalars['String']['output']>;
  primaryCustomerId?: Maybe<Scalars['String']['output']>;
  renewalPremium?: Maybe<Scalars['Float']['output']>;
  simplifyQuoteId?: Maybe<Scalars['String']['output']>;
  submissionEmail?: Maybe<Scalars['String']['output']>;
  sunlightUserId?: Maybe<Scalars['String']['output']>;
  targetPremium?: Maybe<Scalars['Float']['output']>;
};

export type QuotePropertiesInput = {
  commissionRate?: InputMaybe<Scalars['String']['input']>;
  contactEmail?: InputMaybe<Scalars['String']['input']>;
  currentCarrier?: InputMaybe<Scalars['String']['input']>;
  effectiveDate?: InputMaybe<Scalars['String']['input']>;
  emailAddresses?: InputMaybe<EmailsInput>;
  expiringPremium?: InputMaybe<Scalars['Float']['input']>;
  hasSentDocuments?: InputMaybe<Scalars['Boolean']['input']>;
  initialPremium?: InputMaybe<Scalars['Float']['input']>;
  initialSubmissionFull?: InputMaybe<Scalars['Boolean']['input']>;
  isQIP?: InputMaybe<Scalars['Boolean']['input']>;
  modelInfo?: InputMaybe<ModelInfoInput>;
  policyExclusions?: InputMaybe<Scalars['String']['input']>;
  primaryCustomerId?: InputMaybe<Scalars['String']['input']>;
  renewalPremium?: InputMaybe<Scalars['Float']['input']>;
  simplifyQuoteId?: InputMaybe<Scalars['String']['input']>;
  submissionEmail?: InputMaybe<Scalars['String']['input']>;
  sunlightUserId?: InputMaybe<Scalars['String']['input']>;
  targetPremium?: InputMaybe<Scalars['Float']['input']>;
};

export type QuotePropertiesUpdateInput = {
  commissionRate?: InputMaybe<Scalars['String']['input']>;
  contactEmail?: InputMaybe<Scalars['String']['input']>;
  currentCarrier?: InputMaybe<Scalars['String']['input']>;
  effectiveDate?: InputMaybe<Scalars['String']['input']>;
  emailAddresses?: InputMaybe<EmailsInput>;
  expiringPremium?: InputMaybe<Scalars['Float']['input']>;
  hasSentDocuments?: InputMaybe<Scalars['Boolean']['input']>;
  initialPremium?: InputMaybe<Scalars['Float']['input']>;
  initialSubmissionFull?: InputMaybe<Scalars['Boolean']['input']>;
  isQIP?: InputMaybe<Scalars['Boolean']['input']>;
  modelInfo?: InputMaybe<ModelInfoUpdateInput>;
  policyExclusions?: InputMaybe<Scalars['String']['input']>;
  primaryCustomerId?: InputMaybe<Scalars['String']['input']>;
  renewalPremium?: InputMaybe<Scalars['Float']['input']>;
  simplifyQuoteId?: InputMaybe<Scalars['String']['input']>;
  submissionEmail?: InputMaybe<Scalars['String']['input']>;
  sunlightUserId?: InputMaybe<Scalars['String']['input']>;
  targetPremium?: InputMaybe<Scalars['Float']['input']>;
};

export type RemoveReactionAction = {
  __typename?: 'RemoveReactionAction';
  messageTs: Scalars['String']['output'];
  reaction: Scalars['String']['output'];
  type: SlackEventType;
};

export type ReplyToMessageAction = {
  __typename?: 'ReplyToMessageAction';
  messageTs: Scalars['String']['output'];
  reply: Scalars['String']['output'];
  type: SlackEventType;
};

export type SlackEventAction = AddMarkdownMessageAction | AddMessageAction | AddReactionAction | RemoveReactionAction | ReplyToMessageAction;

export type SlackEventPayload = {
  __typename?: 'SlackEventPayload';
  action: SlackEventAction;
  channel: Scalars['String']['output'];
};

export enum SlackEventType {
  AddMarkdownMessage = 'ADD_MARKDOWN_MESSAGE',
  AddMessage = 'ADD_MESSAGE',
  AddReaction = 'ADD_REACTION',
  RemoveReaction = 'REMOVE_REACTION',
  ReplyToMessage = 'REPLY_TO_MESSAGE'
}

export type SnowflakeConfig = {
  __typename?: 'SnowflakeConfig';
  database: Scalars['String']['output'];
  schema: Scalars['String']['output'];
  table: Scalars['String']['output'];
  warehouse: Scalars['String']['output'];
};

export enum SnowflakeImportType {
  Dynamic = 'DYNAMIC',
  Static = 'STATIC'
}

export enum StatusType {
  ActuaryReview = 'ActuaryReview',
  AgencyPending = 'AgencyPending',
  DsInput = 'DSInput',
  Declined = 'Declined',
  InQueue = 'InQueue',
  InternalDiscussion = 'InternalDiscussion',
  NeedBrokerInfo = 'NeedBrokerInfo',
  RatOfficial = 'RATOfficial',
  Ratwip = 'RATWIP',
  StatePending = 'StatePending',
  SystemBug = 'SystemBug',
  UwAnalyzing = 'UWAnalyzing'
}

export type Submission = {
  __typename?: 'Submission';
  calculatedSrfAdjustment?: Maybe<Scalars['Float']['output']>;
  declination?: Maybe<Declination>;
  entities?: Maybe<Array<Entity>>;
  id: Scalars['ID']['output'];
  midlevels?: Maybe<Array<Midlevel>>;
  notes?: Maybe<Scalars['String']['output']>;
  policyType?: Maybe<PolicyType>;
  previewPremium?: Maybe<Scalars['Float']['output']>;
  previewPremiumsAreStale?: Maybe<Scalars['Boolean']['output']>;
  producer?: Maybe<Producer>;
  providers?: Maybe<Array<Provider>>;
  quoteProperties?: Maybe<QuoteProperties>;
  srfAdjustment?: Maybe<Scalars['Float']['output']>;
  status?: Maybe<StatusType>;
  underwriterNotes?: Maybe<Array<Note>>;
  useCalculatedSrfAdjustment?: Maybe<Scalars['Boolean']['output']>;
};

export type SubmissionInput = {
  calculatedSrfAdjustment?: InputMaybe<Scalars['Float']['input']>;
  declination?: InputMaybe<DeclinationInput>;
  entities?: InputMaybe<Array<EntityInput>>;
  id: Scalars['ID']['input'];
  midlevels?: InputMaybe<Array<MidlevelInput>>;
  notes?: InputMaybe<Scalars['String']['input']>;
  policyType?: InputMaybe<PolicyType>;
  previewPremium?: InputMaybe<Scalars['Float']['input']>;
  previewPremiumsAreStale?: InputMaybe<Scalars['Boolean']['input']>;
  producer?: InputMaybe<ProducerInput>;
  providers?: InputMaybe<Array<ProviderInput>>;
  quoteProperties?: InputMaybe<QuotePropertiesInput>;
  srfAdjustment?: InputMaybe<Scalars['Float']['input']>;
  status?: InputMaybe<StatusType>;
  underwriterNotes?: InputMaybe<Array<NoteInput>>;
  useCalculatedSrfAdjustment?: InputMaybe<Scalars['Boolean']['input']>;
};

export type SubmissionUpdateInput = {
  calculatedSrfAdjustment?: InputMaybe<Scalars['Float']['input']>;
  declination?: InputMaybe<DeclinationInput>;
  entities?: InputMaybe<Array<EntityUpdateInput>>;
  id: Scalars['ID']['input'];
  midlevels?: InputMaybe<Array<MidlevelUpdateInput>>;
  notes?: InputMaybe<Scalars['String']['input']>;
  policyType?: InputMaybe<PolicyType>;
  previewPremium?: InputMaybe<Scalars['Float']['input']>;
  previewPremiumsAreStale?: InputMaybe<Scalars['Boolean']['input']>;
  producer?: InputMaybe<ProducerUpdateInput>;
  providers?: InputMaybe<Array<ProviderUpdateInput>>;
  quoteProperties?: InputMaybe<QuotePropertiesInput>;
  srfAdjustment?: InputMaybe<Scalars['Float']['input']>;
  status?: InputMaybe<StatusType>;
  underwriterNotes?: InputMaybe<Array<NoteUpdateInput>>;
  useCalculatedSrfAdjustment?: InputMaybe<Scalars['Boolean']['input']>;
};

export type SunlightCreationAddressPayload = {
  __typename?: 'SunlightCreationAddressPayload';
  city: Scalars['String']['output'];
  countyFips?: Maybe<Scalars['String']['output']>;
  countyName?: Maybe<Scalars['String']['output']>;
  secondary?: Maybe<Scalars['String']['output']>;
  state: Scalars['String']['output'];
  streetName: Scalars['String']['output'];
  zip: Scalars['String']['output'];
};

export enum SunlightCustomerType {
  Entity = 'ENTITY',
  Midlevel = 'MIDLEVEL',
  Provider = 'PROVIDER'
}

export type TableImportEventPayload = {
  __typename?: 'TableImportEventPayload';
  glueJobName: Scalars['String']['output'];
  newTableSuffix?: Maybe<Scalars['String']['output']>;
  snowflakeConfig?: Maybe<SnowflakeConfig>;
  snowflakeImportType: SnowflakeImportType;
  table: ImportedDdbTable;
};

export type UpdatedSrfAdjustment = {
  __typename?: 'UpdatedSrfAdjustment';
  calculatedSrfAdjustment?: Maybe<Scalars['Float']['output']>;
  srfAdjustment?: Maybe<Scalars['Float']['output']>;
  useCalculatedSrfAdjustment?: Maybe<Scalars['Boolean']['output']>;
};
