export var CREATE_HEALTHCARE_ENTITY = "Create Healthcare Entity";
export var COPY_HEALTHCARE_ENTITY = "Copy Healthcare Entity";
export var UPDATE_HEALTHCARE_ENTITY = "Update Healthcare Entity";
export var DELETE_HEALTHCARE_ENTITY = "Delete Healthcare Entity";
export var CREATE_INDIVIDUAL_PROVIDER = "Create Individual Provider";
export var COPY_INDIVIDUAL_PROVIDER = "Copy Individual Provider";
export var UPDATE_INDIVIDUAL_PROVIDER = "Update Individual Provider";
export var DELETE_INDIVIDUAL_PROVIDER = "Delete Individual Provider";
export var CREATE_MIDLEVEL = "Create Midlevel";
export var COPY_MIDLEVEL = "Copy Midlevel";
export var UPDATE_MIDLEVEL = "Update Midlevel";
export var DELETE_MIDLEVEL = "Delete Midlevel";
export var NEXT_BUTTON_CLICKED = "Next Button Clicked";
export var BACK_BUTTON_CLICKED = "Back Button Clicked";
export var PAGES = {
    ROSTER: "roster",
    MODEL_INSIGHT: "model_insight",
    RESOLUTION: "resolution",
};
export var ERROR_OVERLAY_ACTIVATED = "Error Overlay Shown";
export var OPEN_RESOLUTION_MODAL = "Open Resolution Modal";
export var CLOSE_RESOLUTION_MODAL = "Close Resolution Modal";
export var SELECT_NPI_FROM_RESOLUTION_MODAL = "Select NPI From Resolution Modal";
export var UPDATE_SCHEDULE_RATING_FACTOR = "Update Schedule Rating Factor";
export var SUBMIT_QUOTE = "Submit Quote";
export var SUBMIT_QUOTE_ACTIVITY_ERROR = "Submit Quote Activity Error";
export var QUOTE_RESPONSE = "Quote Response";
export var QUOTE_ERROR = "Quote Submission Error";
export var AMOUNT_OF_NPI_MATCHES = "Amount of NPI Matches";
export var RAT_EVENTS = {
    SELECT_MULTIPLE_PROVIDERS_FLOW: "Select Multiple Providers Flow",
    SELECT_SINGLE_PROVIDER_FLOW: "Select Individual Provider Flow",
    MATCH_PROFILES: "Match Profiles Clicked",
    ASSESS_RISK: "Assess Risk Clicked",
    ADD_PROVIDER: "Add Another Provider Clicked",
    SUBMIT_INQUIRY: "Submit Application Clicked",
};
