export var RiskLevel;
(function (RiskLevel) {
    RiskLevel["LOW"] = "Low";
    RiskLevel["MODERATE"] = "Moderate";
    RiskLevel["HIGH"] = "High";
    RiskLevel["UNKNOWN"] = "Unknown";
})(RiskLevel || (RiskLevel = {}));
export var RiskColor;
(function (RiskColor) {
    RiskColor["RED"] = "RED";
    RiskColor["GREEN"] = "GREEN";
    RiskColor["GRAY"] = "GRAY";
    RiskColor["YELLOW"] = "YELLOW";
})(RiskColor || (RiskColor = {}));
export function getRiskColorForLevel(level) {
    switch (level) {
        case RiskLevel.HIGH:
            return RiskColor.RED;
        case RiskLevel.MODERATE:
            return RiskColor.YELLOW;
        case RiskLevel.LOW:
            return RiskColor.GREEN;
        case RiskLevel.UNKNOWN:
            return RiskColor.GRAY;
        default:
            return RiskColor.GRAY;
    }
}
