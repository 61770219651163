export var typography = {
    fonts: {
        body: "Darker Grotesque, system-ui, sans-serif",
        heading: "Darker Grotesque, Georgia, serif",
        mono: "Menlo, monospace",
    },
    fontSizes: {
        xxs: "0.75rem",
        xs: "0.875rem",
        xm: "1rem",
        sm: "1.125rem",
        md: "1.2rem",
        lg: "1.5rem",
        xl: "1.75rem",
        "2xl": "1.5rem",
        "3xl": "2rem",
        "4xl": "2.25rem",
        "5xl": "3rem",
        "6xl": "3.75rem",
        "7xl": "4.5rem",
        "8xl": "6rem",
        "9xl": "8rem",
    },
    lineHeights: {
        normal: "normal",
        none: 1,
        shorter: 1.25,
        short: 1.375,
        base: 1.5,
        tall: 1.625,
        taller: "2",
        "3": ".75rem",
        "4": "1rem",
        "5": "1.25rem",
        "6": "1.5rem",
        "7": "1.75rem",
        "8": "2rem",
        "9": "2.25rem",
        "10": "2.5rem",
    },
    fontWeights: {
        hairline: 600,
        thin: 600,
        light: 600,
        normal: 500,
        medium: 600,
        semibold: 600,
        bold: 700,
        extrabold: 800,
        black: 900,
    },
};
