var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { useState, useCallback } from "react";
export var useSmartyStreets = function () {
    var _a = useState(false), loading = _a[0], setLoading = _a[1];
    var _b = useState(null), error = _b[0], setError = _b[1];
    var _c = useState(), autocompleteData = _c[0], setAutocompleteData = _c[1];
    var _d = useState(null), detailsData = _d[0], setDetailsData = _d[1];
    var autocomplete = useCallback(function (search) { return __awaiter(void 0, void 0, Promise, function () {
        var searchEnc, key, url, response, data, error_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    setLoading(true);
                    setError(null);
                    searchEnc = encodeURIComponent(search);
                    key = "170117547993626608";
                    url = "https://us-autocomplete-pro.api.smartystreets.com/lookup?key=".concat(key, "&search=").concat(searchEnc);
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 4, 5, 6]);
                    return [4 /*yield*/, fetch(url, {
                            method: "GET",
                            headers: {
                                Referer: "localhost",
                            },
                        })];
                case 2:
                    response = _a.sent();
                    if (!response.ok) {
                        throw new Error("Network response was not OK");
                    }
                    return [4 /*yield*/, response.json()];
                case 3:
                    data = _a.sent();
                    setAutocompleteData(data);
                    return [2 /*return*/, data];
                case 4:
                    error_1 = _a.sent();
                    setError(error_1);
                    throw error_1;
                case 5:
                    setLoading(false);
                    return [7 /*endfinally*/];
                case 6: return [2 /*return*/];
            }
        });
    }); }, []);
    var details = useCallback(function (street, city, state) { return __awaiter(void 0, void 0, void 0, function () {
        var host, key, _street, _city, url, response, data, addressData, error_2;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    setLoading(true);
                    setError(null);
                    host = "us-street.api.smartystreets.com";
                    key = "170117547993626608";
                    _street = encodeURIComponent(street);
                    _city = encodeURIComponent(city);
                    url = "https://".concat(host, "/street-address?key=").concat(key, "&license=us-core-cloud&street=").concat(_street, "&city=").concat(_city, "&state=").concat(state, "&candidates=10");
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 4, 5, 6]);
                    return [4 /*yield*/, fetch(url, {
                            method: "GET",
                            headers: {
                                Referer: "localhost",
                            },
                        })];
                case 2:
                    response = _a.sent();
                    if (!response.ok) {
                        throw new Error("Network response was not OK");
                    }
                    return [4 /*yield*/, response.json()];
                case 3:
                    data = _a.sent();
                    addressData = data.map(function (d) {
                        var _a, _b, _c, _d;
                        return {
                            street: (_a = d === null || d === void 0 ? void 0 : d.components) === null || _a === void 0 ? void 0 : _a.street_name,
                            countyName: (_b = d === null || d === void 0 ? void 0 : d.metadata) === null || _b === void 0 ? void 0 : _b.county_name,
                            countyFips: (_c = d === null || d === void 0 ? void 0 : d.metadata) === null || _c === void 0 ? void 0 : _c.county_fips,
                            postalCode: (_d = d === null || d === void 0 ? void 0 : d.components) === null || _d === void 0 ? void 0 : _d.zipcode,
                        };
                    })[0];
                    setDetailsData(addressData);
                    return [2 /*return*/, addressData];
                case 4:
                    error_2 = _a.sent();
                    setError(error_2);
                    return [3 /*break*/, 6];
                case 5:
                    setLoading(false);
                    return [7 /*endfinally*/];
                case 6: return [2 /*return*/];
            }
        });
    }); }, []);
    return {
        loading: loading,
        error: error,
        autocompleteData: autocompleteData,
        detailsData: detailsData,
        autocomplete: autocomplete,
        details: details,
    };
};
