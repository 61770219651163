import { useState, useEffect } from "react";
export function useLocalAuthSession() {
    var _a = useState(function () {
        try {
            var storedUser = JSON.parse(localStorage.getItem("signedInUser") || "null");
            return storedUser;
        }
        catch (e) {
            console.error("Failed parsing local user: ", e);
            return null;
        }
    }), user = _a[0], setUser = _a[1];
    useEffect(function () {
        var updateUserFromStorage = function () {
            try {
                var storedUser = JSON.parse(localStorage.getItem("signedInUser") || "null");
                setUser(storedUser);
            }
            catch (e) {
                console.error("Failed parsing local user: ", e);
            }
        };
        var handleStorageChange = function (event) {
            if (event.key === "signedInUser") {
                updateUserFromStorage();
            }
        };
        window.addEventListener("storage", handleStorageChange);
        return function () {
            window.removeEventListener("storage", handleStorageChange);
        };
    }, []);
    return user;
}
