import { useEffect, useState, createContext, useContext, ReactNode } from "react";
import { Maybe, Submission } from "__generated__/graphql";
import { UPDATE_SRF_ADJUSTMENT } from "mutations";
import { useMutation } from "@apollo/client";

export type SrfAdjustmentContextType = {
  srfAdjustment: Maybe<number | undefined>;
  calculatedSrfAdjustment: Maybe<number | undefined>;
  useCalculatedSrfAdjustment: Maybe<boolean | undefined>;
  setSrfAdjustment: (value: number) => void;
  setUseCalculatedSrfAdjustment: (value: boolean) => void;
  loading: boolean;
};

const SrfAdjustmentContext = createContext<SrfAdjustmentContextType | undefined>(undefined);

export function useSrfAdjustment() {
  const context = useContext(SrfAdjustmentContext);
  if (!context) {
    throw new Error("useSrfAdjustment must be used within a SrfAdjustmentProvider");
  }
  return context;
}

export function SrfAdjustmentProvider({
  initialData,
  children,
}: {
  initialData?: Submission;
  children: ReactNode;
}) {
  const [srfAdjustment, setSrfAdjustment] = useState<Maybe<number | undefined>>(
    initialData?.srfAdjustment,
  );
  const [useCalculatedSrfAdjustment, setUseCalculatedSrfAdjustment] = useState<
    Maybe<boolean | undefined>
  >(initialData?.useCalculatedSrfAdjustment);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    if (initialData) {
      setSrfAdjustment(initialData?.srfAdjustment);
      setUseCalculatedSrfAdjustment(initialData?.useCalculatedSrfAdjustment);
      setLoading(false);
    }
  }, [initialData]);

  const [updateSrfAdjustmentMutation] = useMutation(UPDATE_SRF_ADJUSTMENT, {
    onCompleted: (data) => {
      setLoading(false);
      setSrfAdjustment(data.updateSrfAdjustment.srfAdjustment);
      setUseCalculatedSrfAdjustment(data.updateSrfAdjustment.useCalculatedSrfAdjustment);
    },
    onError: () => {
      setLoading(false);
    },
  });

  const setSrfAdjustmentAndUpdate = (value: number) => {
    setLoading(true);
    updateSrfAdjustmentMutation({
      variables: {
        submissionId: initialData?.id,
        srfAdjustment: value,
        useCalculatedSrfAdjustment,
      },
    });
  };

  const setUseCalculatedSrfAdjustmentAndUpdate = (value: boolean) => {
    setLoading(true);
    updateSrfAdjustmentMutation({
      variables: {
        submissionId: initialData?.id,
        useCalculatedSrfAdjustment: value,
      },
    });
  };

  return (
    <SrfAdjustmentContext.Provider
      value={{
        srfAdjustment,
        calculatedSrfAdjustment: initialData?.calculatedSrfAdjustment,
        useCalculatedSrfAdjustment,
        setSrfAdjustment: setSrfAdjustmentAndUpdate,
        setUseCalculatedSrfAdjustment: setUseCalculatedSrfAdjustmentAndUpdate,
        loading,
      }}
    >
      {children}
    </SrfAdjustmentContext.Provider>
  );
}
