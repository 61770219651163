import { Container, Flex } from "@chakra-ui/react";
import { ReactNode } from "react";

interface SubnavProps {
  leftContent?: ReactNode;
  rightContent?: ReactNode;
}

export function Subnav({ leftContent, rightContent }: SubnavProps) {
  return (
    <Flex
      borderColor="gray.200"
      bgColor="white"
      boxShadow="0px 1px 2px 0px rgba(0, 0, 0, 0.06), 0px 1px 3px 0px rgba(0, 0, 0, 0.10)"
      borderBottom="0px"
      zIndex="10"
      position="fixed"
      w="100%"
    >
      <Container>
        <Flex h="64px" pl="16px" pr="64px" alignItems="center" justify="space-between">
          {leftContent}
          {rightContent}
        </Flex>
      </Container>
    </Flex>
  );
}
