var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import "reflect-metadata";
import { Observable } from "@apollo/client/core";
import { ApolloLink } from "@apollo/client/link/core";
import { Sha256 } from "@aws-crypto/sha256-js";
import { SignatureV4 } from "@smithy/signature-v4";
import { print } from "graphql/language/printer";
import { BatchLink } from "@apollo/client/link/batch";
var AwsHttpLink = /** @class */ (function (_super) {
    __extends(AwsHttpLink, _super);
    function AwsHttpLink(axiosClient, credentialProvider, region, url, batchMax, batchInterval) {
        if (batchMax === void 0) { batchMax = 10; }
        if (batchInterval === void 0) { batchInterval = 20; }
        var _this = _super.call(this) || this;
        _this.httpClient = axiosClient;
        _this.requestSigner = new SignatureV4({
            credentials: credentialProvider,
            region: region,
            service: "execute-api",
            sha256: Sha256,
        });
        _this.region = region;
        _this.url = url;
        _this.batchMax = batchMax;
        _this.batchInterval = batchInterval;
        _this.batchLink = new BatchLink({
            batchMax: _this.batchMax,
            batchInterval: _this.batchInterval,
            batchHandler: _this.batchRequest.bind(_this),
        });
        return _this;
    }
    AwsHttpLink.prototype.request = function (operation) {
        var _this = this;
        var origContext = operation.getContext();
        console.log("Starting query with operationName: " + operation.operationName);
        return new Observable(function (observer) {
            var _a = new URL(_this.url), hostname = _a.hostname, pathname = _a.pathname;
            var unsigned = {
                method: "POST",
                protocol: "https",
                headers: __assign({ accept: "*/*", "Content-Type": "application/json; charset=UTF-8", host: hostname }, origContext.headers),
                hostname: hostname,
                path: pathname,
                body: JSON.stringify({
                    operationName: operation.operationName,
                    query: print(operation.query),
                    variables: operation.variables,
                }),
            };
            _this.requestSigner
                .sign(unsigned)
                .then(function (signed) {
                return _this.httpClient.request({
                    method: signed.method,
                    url: _this.url,
                    headers: signed.headers,
                    data: signed.body,
                });
            })
                .then(function (response) {
                var _a;
                operation.setContext({ response: response });
                console.log("Finished query with status code: " + response.status);
                observer.next({
                    data: response.data.data,
                    errors: (_a = response.data.errors) !== null && _a !== void 0 ? _a : [],
                });
                observer.complete();
            })
                .catch(function (err) { return observer.error(err); });
        });
    };
    AwsHttpLink.prototype.batchRequest = function (operations) {
        var _this = this;
        return new Observable(function (observer) {
            var _a = new URL(_this.url), hostname = _a.hostname, pathname = _a.pathname;
            var unsignedRequests = operations.map(function (operation) {
                var origContext = operation.getContext();
                return {
                    method: "POST",
                    protocol: "https",
                    headers: __assign({ accept: "*/*", "Content-Type": "application/json; charset=UTF-8", host: hostname }, origContext.headers),
                    hostname: hostname,
                    path: pathname,
                    body: JSON.stringify({
                        operationName: operation.operationName,
                        query: print(operation.query),
                        variables: operation.variables,
                    }),
                };
            });
            Promise.all(unsignedRequests.map(function (unsigned) {
                return _this.requestSigner.sign(unsigned).then(function (signed) {
                    return _this.httpClient.request({
                        method: signed.method,
                        url: _this.url,
                        headers: signed.headers,
                        data: signed.body,
                    });
                });
            }))
                .then(function (responses) {
                var results = responses.map(function (response) {
                    var _a;
                    return ({
                        data: response.data.data,
                        errors: (_a = response.data.errors) !== null && _a !== void 0 ? _a : [],
                    });
                });
                observer.next(results);
                observer.complete();
            })
                .catch(function (err) { return observer.error(err); });
        });
    };
    AwsHttpLink.prototype.createBatchLink = function () {
        return new BatchLink({
            batchMax: this.batchMax,
            batchInterval: this.batchInterval,
            batchHandler: this.batchRequest.bind(this),
        });
    };
    return AwsHttpLink;
}(ApolloLink));
export { AwsHttpLink };
