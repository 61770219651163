import validator from "validator";
import { z } from "zod";
export var AmountValueSchema = z.object({
    Currency: z.literal("USD"),
    Amount: z.number(),
});
export var AmountValueCyberAggregateSchema = z.object({
    Currency: z.literal("USD"),
    Amount: z
        .number()
        .refine(function (val) { return validator.isIn(val.toString(), ["50000", "100000", "150000", "250000"]); }),
});
export var AmountValueCyberOccurrenceSchema = z.object({
    Currency: z.literal("USD"),
    Amount: z.literal(50000),
});
export var SharedOrSeparateLimitsSchema = z
    .string()
    .refine(function (val) { return validator.isIn(val, ["3101", "3102"]); });
export var DeductibleStructureFactorSchema = z
    .string()
    .optional()
    .refine(function (val) { return val === undefined || validator.isIn(val, ["3101"]); });
export var IndividualExternalIdentifierSchema = z
    .string()
    .includes("AnythingHereToUseLaterOnINDIVIDUAL:");
export var EntityExternalIdentifierSchema = z.literal("AnythingHereToUseLaterOnHOSPITAL");
