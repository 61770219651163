import { inputAnatomy } from "@chakra-ui/anatomy";
import { createMultiStyleConfigHelpers } from "@chakra-ui/react";
var _a = createMultiStyleConfigHelpers(inputAnatomy.keys), definePartsStyle = _a.definePartsStyle, defineMultiStyleConfig = _a.defineMultiStyleConfig;
var outline = definePartsStyle({
    field: {
        borderColor: "indigo.300",
        borderRadius: "2px",
        paddingLeft: "8px",
        paddingRight: "12px",
        _hover: {
            borderColor: "indigo.500",
        },
        _focus: {
            borderColor: "indigo.500",
            boxShadow: "0 0 0 1px #2C0B9B",
        },
    },
});
export var inputTheme = defineMultiStyleConfig({ variants: { outline: outline } });
