import { Container, Heading } from "@chakra-ui/react";
import { Link } from "react-router-dom";

export function NotFound() {
  return (
    <Container pt="100px">
      <Heading as="h1">Page not found: 404</Heading>
      <Link to="/">Return to home</Link>
    </Container>
  );
}
