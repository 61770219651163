import { Text, Flex, Icon } from "@chakra-ui/react";
import { ChevronRightIcon } from "@chakra-ui/icons";
import { FaCircle } from "react-icons/fa";

export interface DetailSummaryProps {
  label: string;
  value: string;
  handleClick?: () => void;
  intent?: "positive" | "negative" | "neutral" | "warning";
}
export function DetailSummary({ intent, label, value, handleClick }: DetailSummaryProps) {
  let dotColor = "gray.500";
  switch (intent) {
    case "positive":
      dotColor = "green.500";
      break;
    case "negative":
      dotColor = "red.500";
      break;
    case "warning":
      dotColor = "yellow.500";
      break;
    case "neutral":
      dotColor = "gray.400";
      break;
    default:
      dotColor = "gray.500";
      break;
  }

  return (
    <Flex
      as="button"
      onClick={handleClick}
      w="100%"
      fontSize="sm"
      direction={{ lg: "row", base: "column" }}
      justifyContent="space-between"
      textAlign={{ lg: "center", base: "left" }}
      p="12px 16px"
      _hover={{ bg: "indigo.100" }}
    >
      <Flex alignItems="center">
        <Icon data-testid="icon" mt="4px" as={FaCircle} color={dotColor} fontSize="xxs" />
        <Text ml={{ lg: "16px", base: "6px" }} fontWeight="bold" lineHeight="shorter">
          {label}
        </Text>
      </Flex>
      <Flex alignItems="center">
        <Text
          style={{ textTransform: "capitalize" }}
          color="gray.450"
          fontWeight="normal"
          lineHeight="shorter"
        >
          {value || "Unknown"}
        </Text>
        <ChevronRightIcon mt="5px" flex={1} color="gray.450" />
      </Flex>
    </Flex>
  );
}
