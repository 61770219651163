import { cardAnatomy } from "@chakra-ui/anatomy";
import { createMultiStyleConfigHelpers } from "@chakra-ui/react";
var _a = createMultiStyleConfigHelpers(cardAnatomy.keys), definePartsStyle = _a.definePartsStyle, defineMultiStyleConfig = _a.defineMultiStyleConfig;
var baseStyle = definePartsStyle({
    container: {
        borderRadius: "3px",
        maxW: "1128px",
        boxShadow: "0px 1px 2px 0px rgba(0, 0, 0, 0.06), 0px 1px 3px 0px rgba(0, 0, 0, 0.10) !important",
    },
});
export var cardTheme = defineMultiStyleConfig({ baseStyle: baseStyle });
