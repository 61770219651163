import { useNavigate } from "react-router-dom";
import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Flex,
  Button,
  Container,
  Heading,
} from "@chakra-ui/react";

export function Confirm() {
  const navigate = useNavigate();
  return (
    <Container pt="100px" maxW="833px">
      <Card
        maxWidth="833px"
        boxShadow="0px 0px 5px 0px rgba(0, 0, 0, 0.10)"
        border="1px"
        borderColor="#DEDEDE"
      >
        <CardHeader p="0" bg="indigo.50" fontSize="sm">
          <Flex px="25px" py="15px" alignItems="center" justifyContent="space-between">
            Thank you!
          </Flex>
        </CardHeader>
        <CardBody p="40px">
          <Heading size="md">
            Great news, your information has been submitted and is now being reviewed by our team.
          </Heading>
        </CardBody>
        <CardFooter>
          <Flex>
            <Button onClick={() => navigate("/broker")}>Start Another Search</Button>
          </Flex>
        </CardFooter>
      </Card>
    </Container>
  );
}
