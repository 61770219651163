import { Card, CardBody, Flex, Text, Link } from "@chakra-ui/react";
import { CheckCircleIcon, ExternalLinkIcon } from "@chakra-ui/icons";
import { Datum } from "components";
import { formatAsDollar } from "utils";
import { SUNLIGHT_API_URL } from "app-constants";
import { QuotePropertiesInput } from "__generated__/graphql";

export type QuoteResults = Pick<
  QuotePropertiesInput,
  "simplifyQuoteId" | "initialPremium" | "primaryCustomerId"
> & { isDeclined?: boolean };

export interface QuoteResultsCardProps {
  quoteResults: QuoteResults;
}

export function QuoteResultsCard({ quoteResults }: QuoteResultsCardProps) {
  const { simplifyQuoteId, initialPremium, primaryCustomerId, isDeclined } = quoteResults;
  if (!simplifyQuoteId && !initialPremium && !primaryCustomerId) return null;
  return (
    <Card mb="12px">
      <CardBody>
        <Flex justifyContent="space-between" alignItems="center">
          <Flex alignItems="center" gap="12px">
            <CheckCircleIcon color={isDeclined ? "indigo.500" : "green.500"} />
            <Text mt="-4px">{isDeclined ? "Declination Sent" : "Submitted to Sunlight"}</Text>
          </Flex>
          <Flex gap="18px" textAlign="right">
            {initialPremium ? (
              <Datum label="Initial Premium" value={formatAsDollar.format(initialPremium || 0)} />
            ) : undefined}
            {primaryCustomerId && (
              <Datum
                label="Customer ID"
                value={
                  <Link
                    style={{ whiteSpace: "nowrap" }}
                    href={`${SUNLIGHT_API_URL}/customers/${primaryCustomerId}`}
                    target="_blank"
                    color="indigo.500"
                    rel="noopener noreferrer"
                  >
                    <Flex gap="6px">
                      <Text>{primaryCustomerId}</Text>
                      <ExternalLinkIcon />
                    </Flex>
                  </Link>
                }
              />
            )}

            {simplifyQuoteId && (
              <Datum
                label="Policy Number"
                value={
                  <Link
                    style={{ whiteSpace: "nowrap" }}
                    href={`${SUNLIGHT_API_URL}/policies/${simplifyQuoteId}-00`}
                    target="_blank"
                    color="indigo.500"
                    rel="noopener noreferrer"
                  >
                    <Flex gap="6px">
                      <Text>{simplifyQuoteId}</Text>
                      <ExternalLinkIcon />
                    </Flex>
                  </Link>
                }
              />
            )}
          </Flex>
        </Flex>
      </CardBody>
    </Card>
  );
}
