import { Flex, Text } from "@chakra-ui/react";
import { WarningTwoIcon, CheckCircleIcon } from "@chakra-ui/icons";

interface CardActionProps {
  message: string;
  variant?: "success" | "warning" | "error";
}

export function CardAction({ message, variant }: CardActionProps) {
  return (
    <Flex w="100%" alignItems="center" gap="8px" whiteSpace="nowrap">
      {variant && variant !== "success" ? (
        <WarningTwoIcon fontSize="xm" color={variant === "error" ? "red.500" : "orange.500"} />
      ) : (
        <CheckCircleIcon fontSize="xm" color="green.500" />
      )}
      <Text fontSize="sm" fontWeight="normal">
        {message}
      </Text>
    </Flex>
  );
}
