import { Icon } from "@chakra-ui/react";

export function ExpiredIcon() {
  return (
    <Icon ml="5px" overflow="visible" viewBox="0 0 24 24" color="red.500" boxSize="24px" p="1px">
      <path
        d="M9.18128 1.9332L15.4126 13.8332C15.5191 14.0364 15.5713 14.2637 15.5642 14.493C15.5571 14.7223 15.491 14.9459 15.3722 15.1422C15.2534 15.3385 15.086 15.5008 14.8861 15.6134C14.6863 15.7261 14.4607 15.7852 14.2313 15.7852H1.76861C1.53919 15.7852 1.31364 15.7261 1.11377 15.6134C0.913898 15.5008 0.746475 15.3385 0.627689 15.1422C0.508903 14.9459 0.442773 14.7223 0.435694 14.493C0.428614 14.2637 0.480826 14.0364 0.587278 13.8332L6.81928 1.9332C6.93221 1.71769 7.10196 1.5372 7.31013 1.41126C7.51831 1.28532 7.75697 1.21875 8.00028 1.21875C8.24358 1.21875 8.48225 1.28532 8.69042 1.41126C8.8986 1.5372 9.06835 1.71769 9.18128 1.9332ZM7.99997 6C7.58576 6 7.24997 6.33579 7.24997 6.75V10.375C7.24997 10.45 7.25554 10.521 7.26529 10.5881C7.27449 10.7374 7.33031 10.9164 7.44647 11.125C7.51786 11.2532 7.61204 11.3926 7.7322 11.543L9.49997 13.3108C9.79286 13.6037 10.2677 13.6037 10.5606 13.3108C10.7265 13.145 10.7984 12.9208 10.7764 12.7043C10.7471 12.4146 10.5204 12.2046 10.3018 12.0021C10.2638 11.967 10.2261 11.932 10.1896 11.8969C9.83329 11.5534 9.27889 11.0079 8.79286 10.4824C8.77845 10.4668 8.7638 10.4516 8.74895 10.4368C8.74963 10.4166 8.74997 10.396 8.74997 10.375V6.75C8.74997 6.33579 8.41418 6 7.99997 6Z"
        fill="currentColor"
      />
    </Icon>
  );
}
