import { Menu, MenuButton, Button, MenuItem, MenuList, MenuDivider } from "@chakra-ui/react";
import { ChevronDownIcon } from "@chakra-ui/icons";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { useMutation } from "@apollo/client";
import { UPDATE_SUBMISSION } from "mutations";
import { GET_SUBMISSION } from "queries";
import { StatusType } from "__generated__/graphql";

export function StatusMenu() {
  const [isLoading, setIsLoading] = useState(false);
  const { id } = useParams();
  const [saveStatus] = useMutation(UPDATE_SUBMISSION, {
    refetchQueries: [GET_SUBMISSION],
    awaitRefetchQueries: true,
  });
  async function updateStatus(status: StatusType) {
    try {
      setIsLoading(true);
      await saveStatus({
        variables: {
          input: {
            id,
            status,
          },
        },
      });
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
      console.error(e);
    }
  }
  return (
    <Menu matchWidth={true}>
      <MenuButton
        isLoading={isLoading}
        aria-busy={isLoading}
        variant="outline"
        mx="4px"
        w="110px"
        as={Button}
        rightIcon={<ChevronDownIcon mt="5px" />}
      >
        Actions
      </MenuButton>
      <MenuList zIndex={2} w="40px">
        <MenuItem onClick={() => updateStatus(StatusType.UwAnalyzing)}>UW Analyzing</MenuItem>
        <MenuItem onClick={() => updateStatus(StatusType.InQueue)}>In Queue</MenuItem>
        <MenuDivider />
        <MenuItem onClick={() => updateStatus(StatusType.NeedBrokerInfo)}>
          Need Broker Info
        </MenuItem>
        <MenuItem onClick={() => updateStatus(StatusType.InternalDiscussion)}>
          Internal Discussion
        </MenuItem>
        <MenuItem onClick={() => updateStatus(StatusType.DsInput)}>DS Input</MenuItem>
        <MenuItem onClick={() => updateStatus(StatusType.ActuaryReview)}>Actuary Review</MenuItem>
        <MenuItem onClick={() => updateStatus(StatusType.SystemBug)}>System Bug</MenuItem>
        <MenuDivider />
        <MenuItem onClick={() => updateStatus(StatusType.AgencyPending)}>Agency Pending</MenuItem>
        <MenuItem onClick={() => updateStatus(StatusType.StatePending)}>State Pending</MenuItem>
        <MenuItem onClick={() => updateStatus(StatusType.Declined)}>Declined</MenuItem>
      </MenuList>
    </Menu>
  );
}
