import { useLayoutEffect } from "react";
import { useAuthenticator } from "@aws-amplify/ui-react";
import { Routes, Route, useLocation } from "react-router-dom";
import { Inquiry } from "./inquiry";
import { Navbar } from "components";
import { useDocumentTitle, useLocalAuthSession } from "hooks";

export function Broker() {
  useDocumentTitle("Indigo Risk Appetite Tool");
  const location = useLocation();
  const currentUser = useLocalAuthSession();
  const { signOut } = useAuthenticator((context) => [context.user]);

  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  return (
    <div>
      <Navbar userEmail={currentUser?.email} signOut={signOut} />
      <Routes>
        <Route path="*" element={<Inquiry />} />
      </Routes>
      <div style={{ height: "30px" }}></div>
    </div>
  );
}
