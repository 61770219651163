import { useEffect, useRef, useState } from "react";
export function useAnimatedNumber(targetNumber, delay) {
    if (delay === void 0) { delay = 10; }
    var localNum = targetNumber !== null && targetNumber !== void 0 ? targetNumber : 0;
    var _a = useState(localNum), displayNumber = _a[0], setDisplayNumber = _a[1];
    var _b = useState(0), step = _b[0], setStep = _b[1];
    var displayNumberRef = useRef(displayNumber);
    useEffect(function () {
        displayNumberRef.current = displayNumber;
    }, [displayNumber]);
    useEffect(function () {
        var difference = localNum - displayNumber;
        var newStep = difference / 10;
        if (Math.abs(newStep) > 0.0001) {
            setStep(newStep);
        }
    }, [localNum, displayNumber]);
    useEffect(function () {
        var timeoutId;
        var epsilon = Math.abs(step) / 2;
        var animate = function () {
            if (Math.abs(displayNumber - localNum) < epsilon) {
                setDisplayNumber(localNum);
                return;
            }
            setDisplayNumber(function (prev) { return prev + step; });
            timeoutId = setTimeout(animate, delay);
        };
        if (step !== 0) {
            timeoutId = setTimeout(animate, delay);
        }
        return function () { return clearTimeout(timeoutId); };
    }, [localNum, step, delay, displayNumber]);
    return displayNumber;
}
