import { Menu, MenuButton, Button, MenuItem, MenuList } from "@chakra-ui/react";
import { ChevronDownIcon } from "@chakra-ui/icons";

interface CompleteReviewButtonProps {
  isLoading?: boolean;
  onClickQuote?: () => void;
  onClickQIP?: () => void;
}
export function SendDocumentMenu({
  isLoading,
  onClickQuote,
  onClickQIP,
}: CompleteReviewButtonProps) {
  return (
    <Menu matchWidth={true}>
      <MenuButton
        data-cy="complete-review-button"
        variant="solid"
        as={Button}
        mx="4px"
        w="182px"
        colorScheme="indigo"
        isLoading={isLoading}
        loadingText="Submitting"
        rightIcon={<ChevronDownIcon mt="5px" />}
      >
        Send Document
      </MenuButton>
      <MenuList zIndex={2} w="40px">
        <MenuItem onClick={onClickQIP}>Send QIP</MenuItem>
        <MenuItem onClick={onClickQuote} data-cy="submit-to-sunlight">
          Send Quote
        </MenuItem>
      </MenuList>
    </Menu>
  );
}
