import validator from "validator";
import { z } from "zod";
import { AmountValueCyberAggregateSchema, AmountValueCyberOccurrenceSchema, AmountValueSchema, SharedOrSeparateLimitsSchema, } from "../general";
/**
 * Schema for midlevel coverage on an individual provider quote.
 */
export var MidlevelCoverageSchema = z.object({
    OfferedServiceId: z.literal("Admitted - Medical Professional Liability Fleet^TPLPI"),
    CoverageClaimType: z.literal("2"),
    InsuredItemId: z.string().includes("GroupGoesHereINDIVIDUAL:"),
    ServiceExternalIdentifier: z.string().includes("CoverageExternalIdentifierINDIVIDUAL:"),
    ListofLimitsDeductibles: z.tuple([
        z.object({
            OfferedDeductibleLimitId: z.literal("Admitted - Medical Professional Liability Fleet^TPLPI^Aggregate Limit By Policy Term"),
            LimitDeductibleType: z.literal("4"),
            LimitDeductibleCategory: z.literal("8"),
            "PolicyCoverage-Filed": z.literal("1903"),
            AmountValue: AmountValueSchema,
        }),
        z.object({
            OfferedDeductibleLimitId: z.literal("Admitted - Medical Professional Liability Fleet^TPLPI^Occurrence Limit"),
            AmountValue: AmountValueSchema,
            LimitDeductibleType: z.literal("2"),
            LimitDeductibleCategory: z.literal("2"),
            "PolicyCoverage-Filed": z.literal("1903"),
        }),
    ]),
    MPLIndividualProviderSharedOrSeparateLimits: z
        .string()
        .refine(function (val) { return validator.isIn(val, ["3101", "3102"]); }),
});
/**
 * Schema for midlevel cyber coverage on an individual provider quote.
 */
export var MidlevelCyberCoverageSchema = z.object({
    OfferedServiceId: z.literal("Admitted - Medical Professional Liability Fleet^CMD"),
    CoverageClaimType: z.literal("2"),
    ListofLimitsDeductibles: z.tuple([
        z.object({
            OfferedDeductibleLimitId: z.literal("Admitted - Medical Professional Liability Fleet^CMD^Aggregate Limit By Policy Term"),
            LimitDeductibleType: z.literal("4"),
            "PolicyCoverage-Filed": z.literal("1903"),
            LimitDeductibleCategory: z.literal("8"),
            AmountValue: AmountValueCyberAggregateSchema,
        }),
        z.object({
            OfferedDeductibleLimitId: z.literal("Admitted - Medical Professional Liability Fleet^CMD^Occurrence Limit"),
            AmountValue: AmountValueCyberOccurrenceSchema,
            LimitDeductibleCategory: z.literal("2"),
            LimitDeductibleType: z.literal("2"),
            "PolicyCoverage-Filed": z.literal("1903"),
        }),
    ]),
    InsuredItemId: z.string().includes("GroupGoesHereINDIVIDUAL:"),
});
/**
 * Schema for midlevel south carolina medical malpractice coverage on an individual provider quote.
 */
export var MidlevelSCMMACoverageSchema = z.object({
    OfferedServiceId: z.literal("Admitted - Medical Professional Liability Fleet^SCMMAI"),
    CoverageClaimType: z.literal("3"),
    ListofLimitsDeductibles: z.tuple([
        z.object({
            OfferedDeductibleLimitId: z.literal("Admitted - Medical Professional Liability Fleet^SCMMAI^Aggregate Limit By Policy Term"),
            LimitDeductibleType: z.literal("4"),
            "PolicyCoverage-Filed": z.literal("1903"),
            LimitDeductibleCategory: z.literal("8"),
            AmountValue: AmountValueSchema,
        }),
        z.object({
            OfferedDeductibleLimitId: z.literal("Admitted - Medical Professional Liability Fleet^SCMMAI^Occurrence Limit"),
            AmountValue: AmountValueSchema,
            LimitDeductibleCategory: z.literal("2"),
            LimitDeductibleType: z.literal("2"),
            "PolicyCoverage-Filed": z.literal("1903"),
        }),
    ]),
    InsuredItemId: z.string().includes("GroupGoesHereINDIVIDUAL:"),
});
/**
 * Schema for midlevel pennseylvania medical malpractice coverage on an individual provider quote.
 */
export var MidlevelPCFPAICoverageSchema = z.object({
    OfferedServiceId: z.literal("Admitted - Medical Professional Liability Fleet^PCFPAI"),
    CoverageClaimType: z.literal("2"),
    ExcludeFromCommission: z.literal(true),
    ListofLimitsDeductibles: z.tuple([
        z.object({
            OfferedDeductibleLimitId: z.literal("Admitted - Medical Professional Liability Fleet^PCFPAI^Aggregate Limit By Policy Term"),
            LimitDeductibleType: z.literal("4"),
            "PolicyCoverage-Filed": z.literal("1903"),
            LimitDeductibleCategory: z.literal("8"),
            AmountValue: AmountValueSchema,
        }),
        z.object({
            OfferedDeductibleLimitId: z.literal("Admitted - Medical Professional Liability Fleet^PCFPAI^Occurrence Limit"),
            AmountValue: AmountValueSchema,
            LimitDeductibleCategory: z.literal("2"),
            LimitDeductibleType: z.literal("2"),
            "PolicyCoverage-Filed": z.literal("1903"),
        }),
    ]),
    InsuredItemId: z.string().includes("GroupGoesHereINDIVIDUAL:"),
});
/**
 * Schema for midlevel coverage on an entity quote.
 */
export var EntityMidlevelCoverageSchema = z.object({
    OfferedServiceId: z.literal("Admitted - Medical Professional Liability Fleet^TPLPI"),
    CoverageClaimType: z.literal("2"),
    InsuredItemId: z.string().includes("GroupGoesHereINDIVIDUAL:"),
    ServiceExternalIdentifier: z.string().includes("CoverageExternalIdentifierINDIVIDUAL:"),
    ListofLimitsDeductibles: z.tuple([
        z.object({
            OfferedDeductibleLimitId: z.literal("Admitted - Medical Professional Liability Fleet^TPLPI^Aggregate Limit By Policy Term"),
            LimitDeductibleType: z.literal("4"),
            LimitDeductibleCategory: z.literal("8"),
            "PolicyCoverage-Filed": z.literal("1903"),
            AmountValue: AmountValueSchema,
        }),
        z.object({
            OfferedDeductibleLimitId: z.literal("Admitted - Medical Professional Liability Fleet^TPLPI^Occurrence Limit"),
            AmountValue: AmountValueSchema,
            LimitDeductibleType: z.literal("2"),
            LimitDeductibleCategory: z.literal("2"),
            "PolicyCoverage-Filed": z.literal("1903"),
        }),
    ]),
    MPLIndividualProviderSharedOrSeparateLimits: SharedOrSeparateLimitsSchema,
});
/**
 * Schema for midlevel cyber coverage on an entity quote.
 */
export var EntityMidlevelCyberCoverageSchema = z.object({
    OfferedServiceId: z.literal("Admitted - Medical Professional Liability Fleet^CMD"),
    CoverageClaimType: z.literal("2"),
    InsuredItemId: z.string().includes("GroupGoesHereINDIVIDUAL:"),
    ListofLimitsDeductibles: z.tuple([
        z.object({
            OfferedDeductibleLimitId: z.literal("Admitted - Medical Professional Liability Fleet^CMD^Aggregate Limit By Policy Term"),
            LimitDeductibleType: z.literal("4"),
            LimitDeductibleCategory: z.literal("8"),
            "PolicyCoverage-Filed": z.literal("1903"),
            AmountValue: AmountValueCyberAggregateSchema,
        }),
        z.object({
            OfferedDeductibleLimitId: z.literal("Admitted - Medical Professional Liability Fleet^CMD^Occurrence Limit"),
            AmountValue: AmountValueCyberOccurrenceSchema,
            LimitDeductibleType: z.literal("2"),
            LimitDeductibleCategory: z.literal("2"),
            "PolicyCoverage-Filed": z.literal("1903"),
        }),
    ]),
    MPLIndividualProviderSharedOrSeparateLimits: SharedOrSeparateLimitsSchema,
});
/**
 * Schema for midlevel south carolina medical malpractice coverage on an entity quote.
 */
export var EntityMidlevelSCMMACoverageSchema = z.object({
    OfferedServiceId: z.literal("Admitted - Medical Professional Liability Fleet^SCMMAI"),
    CoverageClaimType: z.literal("3"),
    InsuredItemId: z.string().includes("GroupGoesHereINDIVIDUAL:"),
    ListofLimitsDeductibles: z.tuple([
        z.object({
            OfferedDeductibleLimitId: z.literal("Admitted - Medical Professional Liability Fleet^SCMMAI^Aggregate Limit By Policy Term"),
            LimitDeductibleType: z.literal("4"),
            LimitDeductibleCategory: z.literal("8"),
            "PolicyCoverage-Filed": z.literal("1903"),
            AmountValue: AmountValueSchema,
        }),
        z.object({
            OfferedDeductibleLimitId: z.literal("Admitted - Medical Professional Liability Fleet^SCMMAI^Occurrence Limit"),
            AmountValue: AmountValueSchema,
            LimitDeductibleType: z.literal("2"),
            LimitDeductibleCategory: z.literal("2"),
            "PolicyCoverage-Filed": z.literal("1903"),
        }),
    ]),
    MPLIndividualProviderSharedOrSeparateLimits: SharedOrSeparateLimitsSchema,
});
export var EntityMidlevelPCFPAICoverageSchema = z.object({
    OfferedServiceId: z.literal("Admitted - Medical Professional Liability Fleet^PCFPAI"),
    CoverageClaimType: z.literal("2"),
    ExcludeFromCommission: z.literal(true),
    InsuredItemId: z.string().includes("GroupGoesHereINDIVIDUAL:"),
    ListofLimitsDeductibles: z.tuple([
        z.object({
            OfferedDeductibleLimitId: z.literal("Admitted - Medical Professional Liability Fleet^PCFPAI^Aggregate Limit By Policy Term"),
            LimitDeductibleType: z.literal("4"),
            LimitDeductibleCategory: z.literal("8"),
            "PolicyCoverage-Filed": z.literal("1903"),
            AmountValue: AmountValueSchema,
        }),
        z.object({
            OfferedDeductibleLimitId: z.literal("Admitted - Medical Professional Liability Fleet^PCFPAI^Occurrence Limit"),
            AmountValue: AmountValueSchema,
            LimitDeductibleType: z.literal("2"),
            LimitDeductibleCategory: z.literal("2"),
            "PolicyCoverage-Filed": z.literal("1903"),
        }),
    ]),
    MPLIndividualProviderSharedOrSeparateLimits: SharedOrSeparateLimitsSchema,
});
