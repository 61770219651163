import { Flex, Text, Box } from "@chakra-ui/react";
import { DownloadIcon } from "@chakra-ui/icons";
import {
  BiSolidFilePdf,
  BiSolidFileHtml,
  BiSolidFileCss,
  BiSolidFileJs,
  BiSolidFileJpg,
  BiSolidFilePng,
  BiSolidFileArchive,
  BiSolidFileJson,
  BiSolidFileTxt,
  BiSolidFile,
} from "react-icons/bi";

import "./Documents.css";
import { downloadDocument, formatFileSize } from "utils";
import { GetSubmissionDocumentUrlsResult } from "@app-stack/types/submission_documents";

function renderMimeIcon(type: string) {
  return mimeTypes?.[type]?.({ size: "32px" }) || <BiSolidFile size="32px" />;
}

export const mimeTypes: {
  [key: string]: Function;
} = {
  "text/plain": BiSolidFileTxt,
  "text/html": BiSolidFileHtml,
  "text/css": BiSolidFileCss,
  "text/javascript": BiSolidFileJs,
  "text/csv": BiSolidFile,
  "image/jpeg": BiSolidFileJpg,
  "image/png": BiSolidFilePng,
  "image/gif": BiSolidFile,
  "image/webp": BiSolidFile,
  "image/svg+xml": BiSolidFile,
  "application/json": BiSolidFileJson,
  "application/xml": BiSolidFile,
  "application/pdf": BiSolidFilePdf,
  "application/zip": BiSolidFileArchive,
};

interface DocumentsProps {
  docs: GetSubmissionDocumentUrlsResult[];
}

interface DocumentProps {
  doc: GetSubmissionDocumentUrlsResult;
}

export function Document({ doc }: DocumentProps) {
  return (
    <Flex
      gap="6px"
      alignItems="flex-start"
      textAlign="left"
      as="button"
      onClick={() => downloadDocument(doc)}
      borderRadius="3px"
      p="6px"
      className="doc"
      w="100%"
    >
      <Box color="gray.400">{renderMimeIcon(doc?.contentType || "")}</Box>
      <Box flex={1}>
        <Text fontSize="sm" lineHeight="none" fontWeight="medium">
          {doc?.key}
        </Text>
        <Text fontSize="sm" lineHeight="tall" fontWeight="normal">
          {formatFileSize(doc?.contentLengthBytes || 0)}
        </Text>
      </Box>
      <DownloadIcon />
    </Flex>
  );
}

export function Documents({ docs }: DocumentsProps) {
  return (
    <Box px="16px" overflow="scroll" py="24px" mb="50px">
      {docs.map((doc, i) => {
        return <Document key={i} doc={doc} />;
      })}
    </Box>
  );
}
