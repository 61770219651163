import { StrictMode } from "react";
import { createRoot } from "react-dom/client";
import { ChakraProvider } from "@chakra-ui/react";
import { fetchAuthSession } from "aws-amplify/auth";
import axios from "axios";
import { ApolloClient, InMemoryCache, ApolloProvider, from } from "@apollo/client";
import { removeTypenameFromVariables } from "@apollo/client/link/remove-typename";
import { AwsHttpLink } from "@app-stack/common/graphql/links";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import { Root } from "./routes";
import { theme } from "./theme";
import { ApiClientProvider, AnalyticsProvider, Analytics } from "./providers";

async function defaultProvider() {
  try {
    return (await fetchAuthSession()).credentials!;
  } catch (e) {
    console.error("Error fetching auth session", e);
    throw e;
  }
}

function getEnv() {
  return process.env.REACT_APP_ENV ?? "dev";
}

const env = getEnv();
const devUrl = "http://localhost:4000";

const awsHttpLink = new AwsHttpLink(
  new axios.Axios({
    transformRequest: axios.defaults.transformRequest,
    transformResponse: axios.defaults.transformResponse,
  }),
  () => defaultProvider(),
  "us-east-1",
  process.env.NODE_ENV === "development" ? devUrl : `https://${env}.graphql.api.getindigo.com`,
  10,
  20,
);

const removeTypenameLink = removeTypenameFromVariables();
const link = from([removeTypenameLink, awsHttpLink.batchLink]);

const client = new ApolloClient({
  link,
  cache: new InMemoryCache(),
});

const root = createRoot(document.getElementById("root") as HTMLElement);
declare global {
  interface Window {
    analytics: Analytics;
  }
}

root.render(
  <StrictMode>
    <AnalyticsProvider analytics={window.analytics}>
      <ChakraProvider theme={theme}>
        <ApolloProvider client={client}>
          <ApiClientProvider>
            <Root />
          </ApiClientProvider>
        </ApolloProvider>
      </ChakraProvider>
    </AnalyticsProvider>
  </StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
