import { ProviderUpdateInput, SubmissionUpdateInput } from "__generated__/graphql";
import { useNavigate, useParams } from "react-router-dom";
import { Container, Button, Flex } from "@chakra-ui/react";
import { ArrowForwardIcon } from "@chakra-ui/icons";
import { RAT_EVENTS } from "analytics";
import { ResolveNpi } from "./ResolveNpi";
import { useAnalytics } from "providers";
interface ResolveNpiWrapperProps {
  submission: SubmissionUpdateInput;
}
export function ResolveNpiWrapper({ submission }: ResolveNpiWrapperProps) {
  const { track } = useAnalytics();
  const navigate = useNavigate();
  const { id } = useParams();
  return (
    <Container pt="100px" maxW="833px">
      {submission.providers?.map((provider: ProviderUpdateInput, index: number) => (
        <ResolveNpi key={index} provider={provider} submission={submission} />
      ))}
      <Flex justifyContent="flex-end" mt="30px">
        <Button
          isDisabled={
            submission?.providers?.filter((x) => !!x.npi).length !==
            (submission?.providers?.length || 0)
          }
          isLoading={false}
          onClick={() => {
            track(RAT_EVENTS.ASSESS_RISK);
            navigate(`/broker/inquiry/${id}/submit`);
          }}
          rightIcon={<ArrowForwardIcon />}
          colorScheme="indigo"
        >
          Next, Assess Risk
        </Button>
      </Flex>
    </Container>
  );
}
