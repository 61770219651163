import { defineStyle, defineStyleConfig } from "@chakra-ui/react";
var boxy = defineStyle({
    border: "1px solid",
    borderRadius: "3px",
    background: "red.500",
    color: "white",
    padding: "0 12px",
    fontWeight: 600,
    lineHeight: "2.1875rem",
});
export var badgeTheme = defineStyleConfig({
    variants: { boxy: boxy },
});
