import { IoBugSharp } from "react-icons/io5";
import { Tooltip, Box, IconButton } from "@chakra-ui/react";
import { downloadBugReport } from "utils";
import { SignedInUser, useLocalAuthSession } from "hooks";
import { SubmissionInput } from "__generated__/graphql";

interface BugButtonProps {
  submissionSnapshot: SubmissionInput;
}

export function BugButton({ submissionSnapshot }: BugButtonProps) {
  const currentUser = useLocalAuthSession();
  const bugData = {
    submissionSnapshot: submissionSnapshot as SubmissionInput,
    reportingUser: currentUser as SignedInUser,
  };
  return (
    <Box position="fixed" bottom="20px" right="0" zIndex="10">
      <Tooltip
        placement="left"
        hasArrow
        label="Download bug report file"
        aria-label="bug report tooltip"
      >
        <IconButton
          background="white"
          m="5px"
          aria-label="Report a bug"
          icon={<IoBugSharp />}
          color="indigo.500"
          onClick={() => {
            downloadBugReport({ ...bugData, timestamp: new Date().toISOString() });
          }}
        />
      </Tooltip>
    </Box>
  );
}
