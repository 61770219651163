import {
  Card,
  CardBody,
  Flex,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Text,
  CardHeader,
} from "@chakra-ui/react";

export type Factor = {
  name: string;
  category: string;
  score: number | string;
  value: string;
};

interface NegativeFactorsProps {
  factors: Factor[];
}
export function NegativeFactors({ factors }: NegativeFactorsProps) {
  return (
    <Flex w="100%">
      <Card w="100%">
        <CardBody>
          <CardHeader lineHeight="115%" fontWeight="bold" p="16px 0px 16px 8px">
            Negative Factors
          </CardHeader>
          <Flex w="612px" border="1px solid" borderRadius="3px" borderColor="gray.300">
            <Table fontSize="xs" variant="striped">
              <Thead w="100%">
                <Tr>
                  <Th p="16px 12px" w="25%">
                    Number
                  </Th>
                  <Th p="16px 12px" w="25%">
                    Name
                  </Th>
                  <Th p="16px 12px" w="25%">
                    Category
                  </Th>
                  <Th p="16px 12px" w="25%">
                    Value
                  </Th>
                  <Th p="16px 12px" w="25%">
                    Score
                  </Th>
                </Tr>
              </Thead>
              <Tbody verticalAlign="top">
                {factors.map(({ name, category, score, value }, index) => (
                  <Tr key={index} bgColor={index % 2 === 0 ? "gray.100" : "white"}>
                    <Td p="16px 12px" w="25%">
                      {index + 1}
                    </Td>
                    <Td p="16px 12px" w="25%">
                      {name}
                    </Td>
                    <Td p="16px 12px" w="25%">
                      {category}
                    </Td>
                    <Td p="16px 12px" w="25%">
                      {value}
                    </Td>
                    <Td p="16px 12px" w="25%">
                      {score}
                    </Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </Flex>
          {factors.length === 0 && (
            <Flex w="100%" p="20px" justifyContent="center">
              <Text fontSize="md">No Negative Factors</Text>
            </Flex>
          )}
        </CardBody>
      </Card>
    </Flex>
  );
}
