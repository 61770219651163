import { useCallback, useEffect, useState } from "react";
import { Badge, Card, CardHeader, CardBody, Container, Flex, Box, Text } from "@chakra-ui/react";
import { OptionCard } from "./OptionCard";
import { Loader } from "components";
import { useNpiSearch } from "hooks";
import { useMutation } from "@apollo/client";
import { UPDATE_SUBMISSION } from "mutations";
import { GET_SUBMISSION } from "queries";
import { SubmissionUpdateInput, ProviderUpdateInput } from "__generated__/graphql";

interface ResolveNpiProps {
  provider: ProviderUpdateInput;
  submission: SubmissionUpdateInput;
}

export function ResolveNpi({ provider, submission }: ResolveNpiProps) {
  const [selectedProvider, setSelectedProvider] = useState<{
    npi: string;
    nppesCity: string;
    nppesState: string;
    nppesSpecialty: string;
  } | null>(null);

  const { isLoading, matches, fetchMatches } = useNpiSearch();
  const [mutateFunction] = useMutation(UPDATE_SUBMISSION, {
    refetchQueries: [GET_SUBMISSION],
    awaitRefetchQueries: true,
  });
  const handleFormSubmission = useCallback(async () => {
    if (submission?.providers && selectedProvider?.npi) {
      await mutateFunction({
        variables: {
          input: { id: submission.id, providers: [{ ...provider, ...selectedProvider }] },
        },
      });
    }
  }, [provider, mutateFunction, selectedProvider, submission.id, submission?.providers]);

  useEffect(() => {
    if (
      selectedProvider?.npi !== provider.npi ||
      selectedProvider?.nppesCity !== provider.nppesCity ||
      selectedProvider?.nppesState !== provider.nppesState ||
      selectedProvider?.nppesSpecialty !== provider.nppesSpecialty
    ) {
      handleFormSubmission();
    }
  }, [provider, selectedProvider, handleFormSubmission]);
  useEffect(() => {
    if (matches === undefined) {
      fetchMatches(provider);
    }
    if (matches?.length === 1) {
      const match = matches[0];
      setSelectedProvider({
        npi: match.npi,
        nppesCity: match.city,
        nppesState: match.state,
        nppesSpecialty: match.specialty,
      });
    }
  }, [matches, provider, fetchMatches]);

  return isLoading ? (
    <Loader />
  ) : (
    <Container mb="15px" maxW="833px">
      <Card maxWidth="833px" border="1px" borderColor="gray.150">
        <CardHeader p="0" bg="indigo.50" fontSize="sm">
          <Flex px="25px" py="15px" alignItems="center" justifyContent="space-between">
            We found the following providers, please pick one
          </Flex>
        </CardHeader>
        <CardBody pt="0px">
          <Box key={provider.id}>
            <Flex gap="13px" my="20px" alignItems="center">
              <Badge colorScheme="yellow" px="12px" borderRadius="3px" pb="2px">
                <Text
                  fontSize="xm"
                  pb="8px"
                  color="black"
                  fontStyle="lowercase"
                  textTransform="none"
                >
                  {provider.firstName} {provider.lastName}
                </Text>
              </Badge>
              <Text fontSize="sm" color="black" fontStyle="lowercase" textTransform="none">
                We found {matches?.length} matching result{matches?.length === 1 ? "" : "s"}
              </Text>
            </Flex>
            <OptionCard
              selectedNpi={provider.npi}
              onChangeSelectedNpi={(npi) => {
                const match = matches?.find((m) => m.npi === npi);
                setSelectedProvider({
                  npi: npi,
                  nppesCity: match?.city || "",
                  nppesState: match?.state || "",
                  nppesSpecialty: match?.specialty || "",
                });
              }}
              unresolvedNpis={matches || []}
            />
          </Box>
        </CardBody>
      </Card>
    </Container>
  );
}
