import { useEffect, useCallback, useState } from "react";
import { useParams } from "react-router-dom";
import { ProviderCard } from "components";
import { Provider } from "__generated__/graphql";
import { ModelRatingApiResult, ModelRatingApiResponseData } from "@app-stack/types";
import {
  DEFAULT_MRS_VALUE,
  AVERAGE_MRS_QUOTE_THRESHOLD,
  AVERAGE_MRS_QUOTE_THRESHOLD_LARGE,
} from "app-constants";
import { useMutation } from "@apollo/client";
import { UPDATE_SUBMISSION, UPDATE_PROVIDERS } from "mutations";
import { GET_SUBMISSION } from "queries";
import { InsightDetails } from "./InsightDetails";
import { Factor } from "./InsightDetails/PositiveFactors";
import { useModelRatingApi } from "hooks";
import { Consistency, InsightDetailType, SuggestedAction } from "localTypes";
import {
  formatSpecialtyInconsistencies,
  formatLocationInconsistencies,
  formatStateLicenses,
} from "utils";

interface ProviderCardWrapperProps {
  provider: Provider;
  averageAdjustedMrs: number;
  averageMRS: number;
  srfAdjustment?: number;
  bookedSRF?: number;
  index?: number;
  lengthOfSet?: number;
  scheduledRatingFactor?: number;
  modelId?: string;
  setSuggestedAction: (action: SuggestedAction) => void;
  suggestedAction?: SuggestedAction;
  customerId?: string;
  indigoPremium?: string;
  localAdjustedPercent: number;
  refreshInsightsData: () => Promise<void>;
}

export function ProviderCardWrapper({
  refreshInsightsData,
  localAdjustedPercent,
  provider,
  averageAdjustedMrs,
  bookedSRF,
  index,
  lengthOfSet,
  scheduledRatingFactor,
  modelId,
  setSuggestedAction,
  suggestedAction,
  indigoPremium,
}: ProviderCardWrapperProps) {
  const { modelData, fetchModelRating, loading } = useModelRatingApi();
  const threshold =
    (lengthOfSet || 0) > 1 ? AVERAGE_MRS_QUOTE_THRESHOLD_LARGE : AVERAGE_MRS_QUOTE_THRESHOLD;
  const [mutateFunction, { loading: loadingMutation }] = useMutation(UPDATE_SUBMISSION, {});
  const [updateProviders, { loading: loadingUpdateProviders }] = useMutation(UPDATE_PROVIDERS, {
    refetchQueries: [GET_SUBMISSION],
    awaitRefetchQueries: true,
  });
  const { id } = useParams();
  const [showInsightDetails, setShowInsightDetails] = useState(false);
  const [selectedInsightType, setSelectedInsightType] = useState<InsightDetailType>(
    InsightDetailType.Claims,
  );
  const [localProviderSpecialty, setLocalProviderSpecialty] = useState(provider.indigoSpecialty);
  const [shouldRefetchMRS, setShouldRefetchMRS] = useState(!provider.mrs || provider.mrs !== 0);
  const inconsistencies = {
    location: formatLocationInconsistencies(provider, modelData),
    specialty: formatSpecialtyInconsistencies(provider, modelData),
  };

  const updateSubmissionModelData = useCallback(async () => {
    if (modelData?.metadata?.modelId && modelData?.metadata?.modelId !== modelId) {
      const newModelId = modelData?.metadata?.modelId || "Unknown";
      const newModelVersion = modelData?.metadata?.modelVersion || "Unknown";
      try {
        await mutateFunction({
          variables: {
            input: {
              id,
              quoteProperties: {
                modelInfo: {
                  modelId: newModelId,
                  modelVersion: newModelVersion,
                },
              },
            },
          },
        });
      } catch (e) {
        console.error(e);
      }
    }
  }, [
    id,
    modelData?.metadata?.modelId,
    modelData?.metadata?.modelVersion,
    mutateFunction,
    modelId,
  ]);

  useEffect(() => {
    if (modelData) {
      updateSubmissionModelData();
    }
  }, [modelData, updateSubmissionModelData]);

  const refetchMrs = useCallback(async () => {
    await fetchModelRating({ ...provider, indigoSpecialty: localProviderSpecialty });
    await refreshInsightsData();
  }, [refreshInsightsData, localProviderSpecialty, fetchModelRating, provider]);

  useEffect(() => {
    if (shouldRefetchMRS) {
      refetchMrs();
      setShouldRefetchMRS(false);
    }
  }, [refetchMrs, shouldRefetchMRS]);

  const claims: string[] = JSON.parse(
    modelData?.results?.[0]?.PRIOR_LEGAL_CLAIM_REPORTED_DATES_REPORT || "[]",
  );

  useEffect(() => {
    if (claims.length > 1 && suggestedAction === SuggestedAction.Quote) {
      setSuggestedAction(SuggestedAction.Review);
    }
  }, [suggestedAction, claims, setSuggestedAction]);

  const payerData = [
    {
      year: "2023",
      amount: modelData?.results?.[0]?.NON_CMS_PAYOR_TOTAL_PAYMENT_1Y_2023 || "",
    },
    {
      year: "2022",
      amount: modelData?.results?.[0]?.NON_CMS_PAYOR_TOTAL_PAYMENT_1Y_2022 || "",
    },
    {
      year: "2021",
      amount: modelData?.results?.[0]?.NON_CMS_PAYOR_TOTAL_PAYMENT_1Y_2021 || "",
    },
  ];

  const hvData = [
    {
      year: "2024",
      amount: modelData?.results?.[0]?.HV_TOTAL_CLAIM_COUNT_1Y_REPORT || "",
    },
    {
      year: "2023",
      amount: modelData?.results?.[0]?.HV_TOTAL_CLAIM_COUNT_1Y_2023 || "",
    },
  ];

  const mrsTrend = [
    {
      version: "MRS Trend - 2024",
      value: parseFloat(modelData?.results?.[0]?.ADJUSTED_RELATIVE_SCORE_REPORT || "-1"),
    },
    {
      version: "MRS Trend - 2023",
      value: parseFloat(modelData?.results?.[0]?.ADJUSTED_RELATIVE_SCORE_2023 || "-1"),
    },
    {
      version: "MRS Trend - 2022",
      value: parseFloat(modelData?.results?.[0]?.ADJUSTED_RELATIVE_SCORE_2022 || "-1"),
    },
  ];

  function generateFactors(data?: ModelRatingApiResponseData) {
    const factors: Factor[] = [];
    if (data) {
      const d: ModelRatingApiResult | null = data?.results?.[0];
      if (d) {
        for (let i: number = 1; i <= 10; i++) {
          if (
            i === 1 ||
            i === 2 ||
            i === 3 ||
            i === 4 ||
            i === 5 ||
            i === 6 ||
            i === 7 ||
            i === 8 ||
            i === 9 ||
            i === 10
          ) {
            factors.push({
              name: d[`POSITIVE_FACTOR_${i}`] || "",
              category: d[`POSITIVE_FACTOR_${i}_CATEGORY`] || "",
              score: d[`POSITIVE_FACTOR_${i}_SCORE`] || 0,
              value: d[`POSITIVE_FACTOR_${i}_VALUE`] || "",
            });
          }
        }
      }
    }
    return factors;
  }
  const factors = generateFactors(modelData);

  const hasLowBillingConfidence = payerData.some((data) => !data.amount);
  return (
    <div>
      <ProviderCard
        isLoading={loading || loadingMutation || loadingUpdateProviders}
        scoreReasons={[
          ...(!provider.excludedNpi
            ? [{ message: "In Adjusted Means Table", variant: "success" as "success" }]
            : [{ message: "Not In Adjusted Means Table", variant: "error" as "error" }]),
          ...(!hasLowBillingConfidence
            ? [{ message: "High Billing Confidence", variant: "success" as "success" }]
            : [{ message: "Low Billing Confidence", variant: "error" as "error" }]),
        ]}
        indigoPremium={indigoPremium}
        index={index || 0}
        lengthOfSet={lengthOfSet || 1}
        bookedSRF={bookedSRF || 0}
        adjustedPercent={parseFloat(localAdjustedPercent?.toFixed(2))}
        adjustedMRS={averageAdjustedMrs || 0}
        handleChangeSpecialty={async (e) => {
          try {
            setLocalProviderSpecialty(e.target.value);
            const hasPreviewPremium = !!provider.previewPremium;
            await updateProviders({
              variables: {
                submissionId: id,
                providers: [
                  {
                    id: provider.id,
                    indigoSpecialty: e.target.value,
                    scheduledRatingFactor,
                  },
                ],
              },
            });
            const promises = [
              mutateFunction({
                variables: {
                  input: {
                    id,
                    useCalculatedSrfAdjustment: true,
                    previewPremiumsAreStale: hasPreviewPremium,
                  },
                },
              }),
            ];
            await Promise.all(promises);
            setShouldRefetchMRS(true);
          } catch (e) {
            console.error(e);
          }
        }}
        name={`${provider.firstName} ${provider.lastName}`}
        mrs={provider.mrs}
        mrsThreshold={threshold}
        boardActionsCount={provider.boardActions?.length || 0}
        claimsCount={claims.length || 0}
        consistency={
          inconsistencies.location.filter((c) => c.isConsistent === false).length > 0 ||
          inconsistencies.specialty.filter((c) => c.isConsistent === false).length > 0
            ? Consistency.Low
            : Consistency.High
        }
        county={provider.address?.countyName || ""}
        npi={provider.npi || ""}
        specialty={localProviderSpecialty || ""}
        suffix={provider.suffix || ""}
        customerId={provider.simplifyCustomerId || ""}
        showInsightModal={(type: InsightDetailType) => {
          setSelectedInsightType(type);
          setShowInsightDetails(true);
        }}
      />
      {showInsightDetails && (
        <InsightDetails
          hasLargeGroup={(lengthOfSet && lengthOfSet > 1) || false}
          stateLicenses={provider.stateLicenses ? formatStateLicenses(provider.stateLicenses) : []}
          isIgnoringLocationInconsistency={provider?.ignoreLocationInconsistency}
          setIgnoreLocationInconsistency={async () => {
            try {
              await updateProviders({
                variables: {
                  submissionId: id,
                  providers: [
                    {
                      id: provider.id,
                      ignoreLocationInconsistency:
                        provider?.ignoreLocationInconsistency === false ? true : false,
                    },
                  ],
                },
              });
              await mutateFunction({
                variables: {
                  input: {
                    id,
                    previewPremiumsAreStale: true,
                  },
                },
              });
              setShouldRefetchMRS(true);
            } catch (e) {
              console.error(e);
            }
          }}
          boardActions={provider.boardActions || []}
          factors={factors}
          isInAdjustedMeansTable={!provider.excludedNpi}
          mrs={provider.mrs || DEFAULT_MRS_VALUE}
          mrsTrend={mrsTrend}
          payerData={payerData}
          hvData={hvData}
          locationInconsistencies={inconsistencies.location}
          specialtyInconsistencies={inconsistencies.specialty}
          type={selectedInsightType}
          claims={claims || []}
          name={`${provider.firstName} ${provider.lastName}`}
          onClose={() => setShowInsightDetails(false)}
        />
      )}
    </div>
  );
}
