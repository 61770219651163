import { Box, Text, Progress } from "@chakra-ui/react";
import { Maybe } from "__generated__/graphql";
import { useAnimatedNumber } from "hooks";

interface RangeDisplayProps {
  value?: number | Maybe<number>;
  min: number;
  max: number;
  threshold?: number;
  colorScheme?: string;
}

export function RangeDisplay({ colorScheme, threshold, value, min, max }: RangeDisplayProps) {
  const thresholdPercent = (threshold ? (threshold - min) / (max - min) : 0) * 100;
  const valuePercent = ((value || max - min) / (max - min)) * 100;
  const animatedValue = useAnimatedNumber(value || 0, 10);
  let color = valuePercent >= thresholdPercent ? "red" : "green";
  if (value === undefined || Number.isNaN(value)) {
    color = "gray.200";
  }
  const colorSchemeCalc =
    threshold === undefined ? colorScheme : color === "green" ? colorScheme || "green" : "red";
  return (
    <Box pb="10px">
      {threshold && (
        <div>
          <Box
            position="relative"
            zIndex={1}
            mb="-10px"
            h="12px"
            w={`calc(${thresholdPercent}% - 2px)`}
            borderRight="1px dashed"
            backgroundColor="transparent"
            data-testid="threshold-indicator"
          />
          <Text
            fontSize="xs"
            mt="6px"
            mb="-27px"
            ml={`calc(${thresholdPercent}% - ${threshold === 1.5 ? "10" : "15"}px)`}
          >
            {threshold || 1.25}
          </Text>
        </div>
      )}
      <Progress
        aria-label="range-display"
        h="8px"
        value={animatedValue}
        colorScheme={colorSchemeCalc}
        min={min}
        max={max}
        backgroundColor="blackAlpha.100"
        style={{ borderRadius: "5px" }}
      />
    </Box>
  );
}
