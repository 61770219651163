import { ChangeEvent, useState } from "react";
import {
  Flex,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Select,
  Text,
} from "@chakra-ui/react";
import { ClaimsDetail } from "./ClaimsDetail";
import { ConsistencyDetails } from "./ConsistencyDetails";
import { Confidence } from "./Confidence";
import { NegativeFactors, Factor } from "./PositiveFactors";
import { BoardActions } from "./BoardActions";
import {
  SpecialtyConsistency,
  LocationConsistency,
  InsightDetailType,
  StateLicense,
} from "localTypes";
import { BoardAction, Maybe, Scalars } from "__generated__/graphql";

interface InsightDetailsProps {
  onClose: () => void;
  name: string;
  claims: string[];
  type: InsightDetailType;
  locationInconsistencies: LocationConsistency[];
  specialtyInconsistencies: SpecialtyConsistency[];
  mrs: number;
  mrsTrend: { version: string; value: number }[];
  payerData: { year: string; amount: string }[];
  hvData: { year: string; amount: string }[];
  isInAdjustedMeansTable: boolean;
  boardActions: BoardAction[];
  stateLicenses: StateLicense[];
  factors: Factor[];
  setIgnoreLocationInconsistency?: () => Promise<void>;
  isIgnoringLocationInconsistency?: Maybe<Scalars["Boolean"]["output"]>;
  hasLargeGroup: boolean;
}

export function InsightDetails({
  isIgnoringLocationInconsistency,
  setIgnoreLocationInconsistency,
  locationInconsistencies,
  specialtyInconsistencies,
  type,
  claims,
  onClose,
  name,
  payerData,
  mrsTrend,
  mrs,
  hvData,
  isInAdjustedMeansTable,
  boardActions,
  stateLicenses,
  factors,
  hasLargeGroup,
}: InsightDetailsProps) {
  const [selectedDetailType, setSelectedDetailType] = useState<InsightDetailType>(type);
  let selectedDetail = null;

  switch (selectedDetailType) {
    case InsightDetailType.NegativeFactors:
      selectedDetail = <NegativeFactors factors={factors} />;
      break;
    case InsightDetailType.BoardActions:
      selectedDetail = <BoardActions stateLicenses={stateLicenses} boardActions={boardActions} />;
      break;
    case InsightDetailType.Confidence:
      selectedDetail = (
        <Confidence
          hasLargeGroup={hasLargeGroup}
          isInAdjustedMeansTable={isInAdjustedMeansTable}
          hvData={hvData}
          mrs={mrs}
          payerData={payerData}
          mrsTrend={mrsTrend}
        />
      );
      break;
    case InsightDetailType.Claims:
      selectedDetail = <ClaimsDetail claims={claims} />;
      break;
    case InsightDetailType.Consistency:
      selectedDetail = (
        <ConsistencyDetails
          isIgnoringLocationInconsistency={isIgnoringLocationInconsistency}
          setIgnoreLocationInconsistency={setIgnoreLocationInconsistency}
          specialty={specialtyInconsistencies}
          location={locationInconsistencies}
        />
      );
      break;
    default:
      break;
  }
  return (
    <Modal size="xl" isOpen={true} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <Flex direction="column" mb="8px">
            <Text color="gray.450" fontSize="sm" fontWeight="normal" lineHeight="shorter">
              Breakdown
            </Text>
            <Text lineHeight="none" fontSize="3xl">
              {name}
            </Text>
          </Flex>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Select
            value={selectedDetailType}
            onChange={(e: ChangeEvent<HTMLSelectElement>) => {
              switch (e.target.value) {
                case InsightDetailType.NegativeFactors:
                  setSelectedDetailType(InsightDetailType.NegativeFactors);
                  break;
                case InsightDetailType.BoardActions:
                  setSelectedDetailType(InsightDetailType.BoardActions);
                  break;
                case InsightDetailType.Claims:
                  setSelectedDetailType(InsightDetailType.Claims);
                  break;
                case InsightDetailType.Consistency:
                  setSelectedDetailType(InsightDetailType.Consistency);
                  break;
                case InsightDetailType.Confidence:
                  setSelectedDetailType(InsightDetailType.Confidence);
                  break;
                default:
                  break;
              }
            }}
          >
            {Object.values(InsightDetailType).map((type) => {
              return (
                <option key={type} value={type}>
                  {type}
                </option>
              );
            })}
          </Select>
        </ModalBody>
        <ModalFooter p="16px 24px" bgColor="gray.100">
          {selectedDetail}
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
