var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { customAlphabet, urlAlphabet } from "nanoid";
import { StatusType, Limit, Deductible, DeclinationReason, NoteType, } from "__generated__/graphql";
import { UserGroups } from "localTypes";
import { STATE_LICENSE_URLS } from "app-constants";
export function formatUserGroups(groups) {
    if (!groups)
        return [];
    return groups.map(function (group) {
        switch (group) {
            case "Underwriters":
                return UserGroups.Underwriters;
            case "Brokers":
                return UserGroups.Brokers;
            default:
                return UserGroups.Unknown;
        }
    });
}
export function generateId() {
    var gen = customAlphabet(urlAlphabet, 7);
    return gen();
}
export var formatAsDollar = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    // These options are needed to round to whole numbers if that's what you want.
    //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
    //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
});
export function formatDeductible(deductible) {
    switch (deductible) {
        case Deductible.None:
            return "None";
        case Deductible["10K"]:
            return "10K";
        case Deductible["25K"]:
            return "25K";
        case Deductible["50K"]:
            return "50K";
        default:
            return "Unknown";
    }
}
export function formatLimit(limit) {
    switch (limit) {
        case Limit["1M_3M"]:
            return "1M/3M";
        case Limit["100K_300K"]:
            return "0.1M/0.3M";
        case Limit["200K_600K"]:
            return "0.2M/0.6M";
        case Limit["250K_750K"]:
            return "0.25M/0.75M";
        case Limit["500K_1500K"]:
            return "0.5M/1.5M";
        default:
            return "Unknown";
    }
}
export function formatDeclinationReason(reason) {
    switch (reason) {
        case DeclinationReason.OtherMultiple:
            return "Other/Multiple - Please Describe";
        case DeclinationReason.TooManyRisks:
            return "Too Many Risks";
        case DeclinationReason.Claims:
            return "Claims";
        case DeclinationReason.MrsScoreTooHigh:
            return "MRS Score Too High";
        case DeclinationReason.SubmittedByAnotherBroker:
            return "Submitted by Another Broker";
        case DeclinationReason.BoardAction:
            return "Board Action";
        case DeclinationReason.PracticeProfile:
            return "Practice Profile";
        case DeclinationReason.VlExposure:
            return "VL Exposure";
        case DeclinationReason.NotEnoughInformation:
            return "Not Enough Information";
        default:
            return "Unknown";
    }
}
export function formatStatusType(status) {
    switch (status) {
        case StatusType.ActuaryReview:
            return "Actuary Review";
        case StatusType.AgencyPending:
            return "Agency Pending";
        case StatusType.DsInput:
            return "DS Input";
        case StatusType.InternalDiscussion:
            return "Internal Discussion";
        case StatusType.NeedBrokerInfo:
            return "Need Broker Info";
        case StatusType.StatePending:
            return "State Pending";
        case StatusType.SystemBug:
            return "System Bug";
        case StatusType.UwAnalyzing:
            return "UW Analyzing";
        case StatusType.Ratwip:
            return "RAT WIP";
        case StatusType.RatOfficial:
            return "RAT Official";
        case StatusType.InQueue:
            return "In Queue";
        case StatusType.Declined:
            return "Declined";
        default:
            return "";
    }
}
export function formatLocationInconsistencies(provider, modelData) {
    var _a, _b, _c, _d, _e, _f;
    var nppesState = provider.nppesState, address = provider.address;
    var countyName = ((_b = (_a = modelData === null || modelData === void 0 ? void 0 : modelData.results) === null || _a === void 0 ? void 0 : _a[0]) === null || _b === void 0 ? void 0 : _b.FIPS_COUNTY_NAME_REPORT) || "";
    var stateName = ((_d = (_c = modelData === null || modelData === void 0 ? void 0 : modelData.results) === null || _c === void 0 ? void 0 : _c[0]) === null || _d === void 0 ? void 0 : _d.STATE_REPORT) || "";
    return [
        {
            ds: "".concat(countyName, ", ").concat(stateName),
            iqvia: "",
            inferred: "",
            declared: "".concat(address === null || address === void 0 ? void 0 : address.countyName, ", ").concat(address === null || address === void 0 ? void 0 : address.state),
            isConsistent: countyName.toLowerCase() === ((_e = address === null || address === void 0 ? void 0 : address.countyName) === null || _e === void 0 ? void 0 : _e.toLowerCase()) &&
                (nppesState === null || nppesState === void 0 ? void 0 : nppesState.toLowerCase()) === ((_f = address === null || address === void 0 ? void 0 : address.state) === null || _f === void 0 ? void 0 : _f.toLowerCase()),
        },
    ];
}
export function extractInferredSpecialties(modelData) {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k;
    if (!modelData)
        return [];
    return [
        ((_b = (_a = modelData.results) === null || _a === void 0 ? void 0 : _a[0]) === null || _b === void 0 ? void 0 : _b.INFERRED_SPECIALTY_RANK_1_2023) || "",
        ((_d = (_c = modelData.results) === null || _c === void 0 ? void 0 : _c[0]) === null || _d === void 0 ? void 0 : _d.INFERRED_SPECIALTY_RANK_2_2023) || "",
        ((_f = (_e = modelData.results) === null || _e === void 0 ? void 0 : _e[0]) === null || _f === void 0 ? void 0 : _f.INFERRED_SPECIALTY_RANK_3_2023) || "",
        ((_h = (_g = modelData.results) === null || _g === void 0 ? void 0 : _g[0]) === null || _h === void 0 ? void 0 : _h.INFERRED_SPECIALTY_RANK_4_2023) || "",
        ((_k = (_j = modelData.results) === null || _j === void 0 ? void 0 : _j[0]) === null || _k === void 0 ? void 0 : _k.INFERRED_SPECIALTY_RANK_5_2023) || "",
    ];
}
var specialtyMap = {
    "Otorhinolaryngology-Surgery": [
        "Otorhinolaryngology-Surgery",
        "Plastic Otorhinolaryngology-Surgery",
    ],
    "Plastic Otorhinolaryngology-Surgery": [
        "Otorhinolaryngology-Surgery",
        "Plastic Otorhinolaryngology-Surgery",
    ],
    Otolaryngology: ["Otolaryngology", "Otorhinolaryngology"],
    Otorhinolaryngology: ["Otolaryngology", "Otorhinolaryngology"],
};
export function formatSpecialtyInconsistencies(provider, modelData) {
    var _a, _b, _c;
    var nppesSpecialty = provider.nppesSpecialty, indigoSpecialty = provider.indigoSpecialty;
    var modelRatingSpecialty = (_b = (_a = modelData === null || modelData === void 0 ? void 0 : modelData.results) === null || _a === void 0 ? void 0 : _a[0]) === null || _b === void 0 ? void 0 : _b.INDIGO_SPECIALTY_REPORT;
    var inferredSpecialties = extractInferredSpecialties(modelData);
    var inferredFlag = false;
    var inferred = inferredSpecialties[0];
    if (modelRatingSpecialty) {
        inferredFlag = inferredSpecialties.includes(modelRatingSpecialty);
        if (inferredFlag) {
            var index = inferredSpecialties.indexOf(modelRatingSpecialty);
            inferred = inferredSpecialties[index];
        }
    }
    return [
        {
            nppes: nppesSpecialty || "",
            dhc: "",
            inferred: inferred || "",
            declared: indigoSpecialty || "",
            ds: modelRatingSpecialty || "",
            isConsistent: modelRatingSpecialty === indigoSpecialty ||
                ((_c = specialtyMap[modelRatingSpecialty || ""]) === null || _c === void 0 ? void 0 : _c.includes(indigoSpecialty || "")),
        },
    ];
}
export function downloadDocument(doc) {
    var a = document.createElement("a");
    a.href = doc.downloadUrl;
    a.download = doc.key;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    URL.revokeObjectURL(doc.downloadUrl);
}
export function downloadBugReport(bugData) {
    if (bugData.submissionSnapshot.id === undefined) {
        throw new Error("Submission ID is required to download bug report");
    }
    var element = document.createElement("a");
    var file = new Blob([JSON.stringify(bugData, null, 2)], { type: "text/plain" });
    element.href = URL.createObjectURL(file);
    element.download = "".concat(bugData.submissionSnapshot.id, "-bug-report.txt");
    document.body.appendChild(element);
    element.click();
}
export function formatFileSize(bytes, decimals) {
    var _a;
    if (decimals === void 0) { decimals = 2; }
    if (bytes === 0)
        return "0 Bytes";
    var k = 1024;
    var dm = decimals < 0 ? 0 : decimals;
    var sizes = ["Bytes", "KB", "MB", "GB"];
    var i = Math.floor(Math.log(bytes) / Math.log(k));
    var unit = (_a = sizes[i]) !== null && _a !== void 0 ? _a : "GB";
    return "".concat(parseFloat((bytes / Math.pow(k, i)).toFixed(dm)), " ").concat(unit);
}
export function addressToString(address) {
    if (!address)
        return "";
    var addressString = "";
    addressString += address.streetName ? "".concat(address.streetName) : "";
    addressString += address.secondary ? " ".concat(address.secondary) : "";
    addressString += address.city ? " ".concat(address.city, ",") : ",";
    addressString += address.state ? " ".concat(address.state) : "";
    addressString += address.zip ? " ".concat(address.zip) : "";
    return addressString.split("  ").join(" ").trim();
}
export function parseCustomerResults(customerResults) {
    return customerResults.map(function (customer) { return ({
        customerId: customer.Result.Customer.CustomerNumber,
        npi: customer.Result.Customer.MyPerson.MyPersonalCustomer.NationalProviderIdentifier,
        firstName: customer.Result.Customer.MyPerson.MyPersonalCustomer.FirstName,
        lastName: customer.Result.Customer.MyPerson.MyPersonalCustomer.LastName,
    }); });
}
export function parsePremiumResults(quoteResults) {
    return quoteResults[0].PolicyJSON.ListofCoverages.map(function (coverage) { return ({
        amount: coverage.Premium.Amount,
        customer: coverage.ServiceExternalIdentifier.split(":")[1],
    }); });
}
export function addPremiumToCustomer(customers, premiums) {
    return customers.map(function (customer) {
        var customerPremiums = premiums.filter(function (premium) { return premium.customer === customer.customerId; });
        return __assign(__assign({}, customer), { premium: customerPremiums.reduce(function (sum, premium) { return sum + premium.amount; }, 0) });
    });
}
export function formatCustomerPremiums(sunlightResponse) {
    var customers = parseCustomerResults(sunlightResponse.customerResults);
    var premiums = parsePremiumResults(sunlightResponse.quoteResults);
    var customersWithPremiums = addPremiumToCustomer(customers, premiums);
    return customersWithPremiums;
}
export function sumPremiums(quoteResults) {
    var _a, _b, _c;
    return (((_c = (_b = (_a = quoteResults === null || quoteResults === void 0 ? void 0 : quoteResults[0]) === null || _a === void 0 ? void 0 : _a.PolicyJSON) === null || _b === void 0 ? void 0 : _b.ListofCoverages) === null || _c === void 0 ? void 0 : _c.reduce(function (sum, coverage) { var _a; return sum + ((_a = coverage.Premium) === null || _a === void 0 ? void 0 : _a.Amount); }, 0)) || 0);
}
export function calcMrsAdjustment(mrs, target) {
    return parseInt(Math.abs(((mrs - target) / mrs) * 100).toFixed(2));
}
export function defaultMrsAdjustment(mrs) {
    if (mrs >= 0.35 && mrs <= 0.5) {
        return 0;
    }
    if (mrs >= 0.372 && mrs <= 1.25) {
        return -8;
    }
    if (mrs > 1.26) {
        return 0;
    }
    if (mrs < 0.372) {
        return calcMrsAdjustment(mrs, 0.35);
    }
}
export function formatStateLicenses(licenses) {
    return licenses.map(function (_a) {
        var state = _a.state, number = _a.number;
        return {
            state: state,
            number: number,
            link: STATE_LICENSE_URLS[state || "unknown"] || { name: "", url: "" },
        };
    });
}
export function countErrors(errors, prevCount, depth) {
    if (prevCount === void 0) { prevCount = 0; }
    if (depth === void 0) { depth = 0; }
    var amountOfErrors = prevCount;
    if (depth > 3)
        return amountOfErrors;
    if (errors) {
        return Object.keys(errors)
            .map(function (k) {
            var _a;
            if ((_a = errors[k]) === null || _a === void 0 ? void 0 : _a.type) {
                return 1;
            }
            else {
                return countErrors(errors[k], amountOfErrors, depth + 1);
            }
        })
            .reduce(function (a, b) { return a + b; }, 0);
    }
    return amountOfErrors;
}
export function formatErrors(errors) {
    var errorCount = countErrors(errors);
    if (errorCount === 1) {
        return "1 Issue";
    }
    else {
        return "".concat(errorCount, " Issues");
    }
}
export var formatDecimal = function (num) {
    if (num === undefined)
        return "00.0";
    var isNegative = num < 0;
    var absNum = Math.abs(num);
    var _a = absNum.toFixed(1).split("."), integerPart = _a[0], decimalPart = _a[1];
    var formattedIntegerPart = integerPart.length < 2 ? integerPart.padStart(2, "0") : integerPart;
    var result = "".concat(formattedIntegerPart, ".").concat(decimalPart);
    return isNegative ? "-".concat(result) : result;
};
export function formatBrokerNote(data) {
    var _a;
    if (data === undefined)
        return undefined;
    var note = {
        id: data.id,
        content: data.notes,
        author: (_a = data.producer) === null || _a === void 0 ? void 0 : _a.email,
        type: NoteType.Broker,
    };
    return note;
}
