import { Modal, ModalOverlay, ModalContent, ModalProps } from "@chakra-ui/react";
import { motion } from "framer-motion";

const MotionModalOverlay = motion(ModalOverlay);
const MotionModalContent = motion(ModalContent);

const slideIn = {
  hidden: { y: "-100%", opacity: 0 },
  visible: { y: "0", opacity: 1 },
};

const slideOut = {
  hidden: { y: "0", opacity: 1 },
  visible: { y: "-100%", opacity: 0 },
};

export function CustomModal({ isOpen, onClose, children, ...props }: ModalProps) {
  return (
    <Modal isOpen={isOpen} onClose={onClose} {...props}>
      <MotionModalOverlay initial="hidden" animate="visible" exit="hidden" variants={slideOut} />
      <MotionModalContent
        initial="hidden"
        animate="visible"
        exit="hidden"
        variants={slideIn}
        transition={{ duration: 0.3 }}
      >
        {children}
      </MotionModalContent>
    </Modal>
  );
}
