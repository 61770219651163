import { Box, Card, CardBody, Flex, Text, Tooltip } from "@chakra-ui/react";
import { RangeDisplay, Datum, Rolodex } from "components";
import { ExpiredIcon } from "assets";
import { useStalePricing } from "providers";

interface MidlevelCardProps {
  name: string;
  county?: string;

  mrs?: number;
  mrsThreshold: number;
  lengthOfSet: number;
  index?: number;
  customerId?: string;
  indigoPremium?: string;
}
export function MidlevelCard({
  mrsThreshold,
  name,
  county,
  mrs,
  lengthOfSet,
  index,
  customerId,
  indigoPremium,
}: MidlevelCardProps) {
  const hasStalePricing = useStalePricing();

  function customerIdent() {
    if (!customerId) {
      return (
        <Flex w="100%">
          <Datum label="" value="" />
        </Flex>
      );
    } else {
      return (
        <Flex w="100%">
          <Datum label="Simplify Customer ID" value={customerId} />
        </Flex>
      );
    }
  }
  return (
    <Card mb="12px">
      <CardBody p="0">
        <Flex>
          <Flex
            basis="45%"
            gap="12px"
            direction="column"
            p="24px"
            borderRight="1px"
            borderRightColor="gray.300"
            w="100%"
          >
            <Flex direction="column" mb="8px">
              <Text color="gray.450" fontWeight="normal" lineHeight="shorter">
                Non-Physician Provider{" "}
                {index !== undefined && lengthOfSet > 1 ? `${index + 1}/${lengthOfSet}` : ""}
              </Text>
              <Text lineHeight="none" fontSize="3xl">
                {name}
              </Text>
            </Flex>
            <Flex w="100%">
              <Datum label="County" value={county} />
            </Flex>
            <Flex w="100%">
              {indigoPremium && (
                <Datum
                  label="Indigo Premium"
                  value={
                    <Flex>
                      {indigoPremium}
                      {hasStalePricing && (
                        <Tooltip hasArrow={true} label="Pricing data may be incorrect">
                          <Flex>
                            <ExpiredIcon />
                          </Flex>
                        </Tooltip>
                      )}
                    </Flex>
                  }
                />
              )}
            </Flex>
            {customerIdent()}
          </Flex>
          <Flex basis="55%" w="100%" justifyContent="space-between">
            <Flex flex="1" p="24px" direction="column" justifyContent="flex-start">
              <Flex
                data-testid="provider-summary"
                padding="16px"
                backgroundColor={
                  mrs === undefined || Number.isNaN(mrs)
                    ? "gray.50"
                    : mrs < mrsThreshold
                      ? "green.50"
                      : "red.50"
                }
                overflow="hidden"
                justifyContent="space-between"
                gap="60px"
                alignItems="center"
                borderRadius="3px"
              >
                <Box textAlign="left" w="100%" flex={1}>
                  <Datum
                    label={
                      <Text color="black" fontWeight="bold">
                        MRS
                      </Text>
                    }
                    value={<Rolodex num={mrs} />}
                  />
                  <RangeDisplay threshold={mrsThreshold} value={mrs} min={0} max={2} />
                </Box>
                <Box w="100%" flex={1} />
              </Flex>
            </Flex>
          </Flex>
        </Flex>
      </CardBody>
    </Card>
  );
}
