var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { z } from "zod";
export var nppesApiParamsCommon = {
    version: 2.1,
    limit: 200, // Maximum amount allowed by the NPI Registry
};
export function nppesApiParamsTypeGuard(params) {
    return __assign(__assign({}, nppesApiParamsCommon), params);
}
export var getNppesApiResultItemFirstName = function (item) {
    return "first_name" in item.basic
        ? item.basic.first_name
        : item.basic.authorized_official_first_name;
};
export var getNppesDbResultItemFirstName = function (item) {
    return item.resolvedFirstName === "UNKNOWN"
        ? item.resolvedOtherFirstName
        : item.resolvedFirstName;
};
export var getNppesApiResultItemLastName = function (item) {
    return "first_name" in item.basic
        ? item.basic.last_name
        : item.basic.authorized_official_last_name;
};
export var getNppesDbResultItemLastName = function (item) {
    return item.resolvedLastName === "UNKNOWN" ? item.resolvedOtherLastName : item.resolvedLastName;
};
export var NppesRequestBaseSchema = z.object({
    limit: z.number().optional(),
    skip: z.number().optional(),
});
export var NppesNumberSearchRequestSchema = NppesRequestBaseSchema.extend({
    number: z.string().min(1),
});
export var NppesNameSearchRequestSchema = NppesRequestBaseSchema.extend({
    first_name: z.string().min(1),
    last_name: z.string().min(1),
});
export var NppesNameAndStateSearchRequestSchema = NppesRequestBaseSchema.extend({
    first_name: z.string().min(1),
    last_name: z.string().min(1),
    state: z.string().min(1),
});
export var NppesNameAndStateAndTaxonomySearchRequestSchema = NppesRequestBaseSchema.extend({
    first_name: z.string().min(1),
    last_name: z.string().min(1),
    state: z.string().min(1),
    taxonomy_description: z.array(z.string().min(1)),
});
export var NppesNameAndTaxonomySearchRequestSchema = NppesRequestBaseSchema.extend({
    first_name: z.string().min(1),
    last_name: z.string().min(1),
    taxonomy_description: z.array(z.string().min(1)),
});
export var NppesSearchRequestSchema = z.union([
    NppesNumberSearchRequestSchema,
    NppesNameSearchRequestSchema,
    NppesNameAndStateSearchRequestSchema,
    NppesNameAndStateAndTaxonomySearchRequestSchema,
    NppesNameAndTaxonomySearchRequestSchema,
]);
