import { ChangeEvent } from "react";
import { GoTriangleDown } from "react-icons/go";
import {
  Spinner,
  Tooltip,
  FormLabel,
  Select,
  Box,
  Card,
  CardBody,
  Flex,
  Text,
} from "@chakra-ui/react";
import { ExpiredIcon } from "assets";

import { ChevronRightIcon } from "@chakra-ui/icons";
import { DetailSummary, RangeDisplay, Datum, CardAction, Rolodex } from "components";
import { INDIGO_SPECIALTIES, PROVIDER_SUFFIXES } from "app-constants";
import { InsightDetailType, Consistency, Reason } from "localTypes";
import { Maybe } from "__generated__/graphql";
import { useStalePricing } from "providers";

export interface ProviderCardProps {
  name: string;
  mrs?: Maybe<number>;
  scoreReasons: Reason[];
  mrsThreshold: number;
  boardActionsCount?: number;
  claimsCount?: number;
  consistency?: Consistency;
  county: string;
  suffix: string;
  specialty?: string;
  npi?: string;
  index?: number;
  lengthOfSet: number;
  adjustedMRS: number;
  adjustedPercent: number;
  handleChangeSpecialty: (e: ChangeEvent<HTMLSelectElement>) => void;
  bookedSRF: number;
  showInsightModal: (type: InsightDetailType) => void;
  customerId?: string;
  indigoPremium?: string;
  isLoading?: boolean;
}

export function ProviderCard({
  isLoading,
  county,
  adjustedPercent,
  handleChangeSpecialty,
  name,
  npi,
  mrs,
  scoreReasons,
  mrsThreshold,
  specialty,
  suffix,
  index,
  lengthOfSet,
  claimsCount,
  boardActionsCount,
  consistency,
  adjustedMRS,
  bookedSRF,
  showInsightModal,
  customerId,
  indigoPremium,
}: ProviderCardProps) {
  const hasStalePricing = useStalePricing();
  function customerIdent() {
    if (!customerId) {
      return (
        <Flex w="100%">
          <Datum label="" value="" />
        </Flex>
      );
    } else {
      return (
        <Flex w="100%">
          <Datum label="Simplify Customer ID" value={customerId} />
        </Flex>
      );
    }
  }

  return (
    <Card mb="12px">
      <CardBody p="0">
        <Flex>
          <Flex
            basis="45%"
            gap="12px"
            direction="column"
            p="24px"
            borderRight="1px"
            borderRightColor="gray.300"
            w="100%"
          >
            <Flex direction="column" mb="8px">
              <Text color="gray.450" fontWeight="normal" lineHeight="shorter">
                Individual Provider{" "}
                {index !== undefined && lengthOfSet > 1 ? `${index + 1}/${lengthOfSet}` : ""}
              </Text>
              <Text lineHeight="none" fontSize="3xl">
                {name}
                {suffix === "" ? "" : `, ${PROVIDER_SUFFIXES[suffix || ""]}`}
              </Text>
            </Flex>
            <Flex w="100%" direction={{ lg: "row", base: "column" }}>
              <Datum label="County" value={county} />
              {npi && (
                <Box textAlign={{ lg: "right", base: "left" }}>
                  <Datum label="NPI Number" value={npi} />
                </Box>
              )}
            </Flex>
            {customerIdent()}
            <Datum
              label={
                <FormLabel fontWeight="normal" htmlFor="providerSpecialty">
                  Indigo Specialty
                </FormLabel>
              }
              value={
                <Flex>
                  <Select
                    icon={
                      isLoading ? (
                        <Spinner fontSize="1rem" color="indigo.500" />
                      ) : (
                        <GoTriangleDown />
                      )
                    }
                    id="providerSpecialty"
                    size="md"
                    mt="6px"
                    p="0px"
                    onChange={handleChangeSpecialty}
                    value={specialty}
                  >
                    {INDIGO_SPECIALTIES.map((spec) => (
                      <option key={spec.value} value={spec.name}>
                        {spec.name}
                      </option>
                    ))}
                  </Select>
                </Flex>
              }
            />
            <Flex
              w="100%"
              justifyContent="space-between"
              alignItems={{ lg: "center", base: "flex-start" }}
              direction={{ lg: "row", base: "column" }}
            >
              <Datum
                label="Credit/Debit to MRS"
                value={
                  <Flex>
                    <Rolodex num={adjustedPercent} />%
                  </Flex>
                }
              />
              <Box w="100%" textAlign={{ lg: "right", base: "left" }}>
                <Datum label="MRS" value={<Rolodex num={mrs ?? 0} />} />
              </Box>
            </Flex>
            <Flex
              w="100%"
              justifyContent="space-between"
              alignItems={{ lg: "center", base: "flex-start" }}
              direction={{ lg: "row", base: "column" }}
            >
              <Datum
                label="Booked SRF"
                value={
                  <Flex>
                    <Rolodex num={bookedSRF} />%
                  </Flex>
                }
              />
              <Box w="100%" textAlign={{ lg: "right", base: "start" }}>
                <Datum label="Average Adjusted MRS" value={<Rolodex num={adjustedMRS} />} />
              </Box>
            </Flex>
            <Flex w="100%" justifyContent="space-between" alignItems="center">
              {indigoPremium && (
                <Datum
                  label="Indigo Premium"
                  value={
                    <Flex>
                      {indigoPremium}
                      {hasStalePricing && (
                        <Tooltip hasArrow={true} label="Pricing data may be incorrect">
                          <Flex>
                            <ExpiredIcon />
                          </Flex>
                        </Tooltip>
                      )}
                    </Flex>
                  }
                />
              )}
            </Flex>
          </Flex>
          <Flex basis="55%" w="100%" justifyContent="space-between">
            <Flex flex="1" p="24px" direction="column" justifyContent="flex-start">
              <Flex
                as="button"
                onClick={() => showInsightModal(InsightDetailType.Confidence)}
                data-testid="provider-summary"
                padding="16px"
                backgroundColor={
                  !mrs && mrs !== 0 ? "gray.50" : mrs < mrsThreshold ? "green.50" : "red.50"
                }
                overflow="hidden"
                justifyContent="space-between"
                gap={{ lg: "18px", base: "0px" }}
                direction={{ lg: "row", base: "column" }}
                alignItems="center"
                borderRadius="3px"
              >
                <Box textAlign="left" w="100%" flex={1}>
                  <Datum
                    label={
                      <Text color="black" fontWeight="bold">
                        MRS
                      </Text>
                    }
                    value={<Rolodex num={mrs ?? 0} />}
                  />
                  <RangeDisplay threshold={mrsThreshold} value={mrs} min={0} max={2} />
                </Box>
                <Flex
                  flex={1}
                  w="100%"
                  h="100%"
                  pl="20px"
                  alignItems="center"
                  justifyContent="center"
                  borderLeft={{ lg: "1px", base: "0px" }}
                  borderLeftColor={{ lg: "gray.300", base: "none" }}
                >
                  <Flex alignItems="center">
                    <Flex direction="column">
                      {scoreReasons.map(({ message, variant }, index) => {
                        return <CardAction key={index} message={message} variant={variant} />;
                      })}
                    </Flex>
                    <ChevronRightIcon fontSize="1.5rem" color="gray.450" />
                  </Flex>
                </Flex>
              </Flex>
              <Flex direction="column" pt="12px">
                <DetailSummary
                  handleClick={() => showInsightModal(InsightDetailType.BoardActions)}
                  label="Board Actions"
                  value={
                    boardActionsCount !== undefined
                      ? `${boardActionsCount} Action${boardActionsCount === 1 ? "" : "s"}`
                      : "Coming soon"
                  }
                  intent={
                    boardActionsCount === undefined
                      ? "neutral"
                      : boardActionsCount > 0
                        ? "negative"
                        : "positive"
                  }
                />
                <DetailSummary
                  handleClick={() => showInsightModal(InsightDetailType.Consistency)}
                  label="Provider Consistency"
                  value={consistency !== undefined ? `${consistency}` : "Unknown"}
                  intent={
                    consistency === undefined
                      ? "neutral"
                      : consistency === Consistency.High
                        ? "positive"
                        : "negative"
                  }
                />
                <DetailSummary
                  handleClick={() => showInsightModal(InsightDetailType.Claims)}
                  label="Claims"
                  value={claimsCount !== undefined ? `${claimsCount} Claims` : "Unknown"}
                  intent={
                    claimsCount === undefined
                      ? "neutral"
                      : claimsCount > 0
                        ? "negative"
                        : "positive"
                  }
                />
                <DetailSummary
                  handleClick={() => showInsightModal(InsightDetailType.NegativeFactors)}
                  label="Negative Factors"
                  value="Click to see more"
                  intent="warning"
                />
              </Flex>
            </Flex>
          </Flex>
        </Flex>
      </CardBody>
    </Card>
  );
}
