import validator from "validator";
import { z } from "zod";
import { IndividualExternalIdentifierSchema } from "../general";
export var MidlevelPremiumFactorSchema = z.object({
    Class: z.literal("clsPriceFactorWithMultipleFactorsForPolicyMPL"),
    OfferedPriceFactorVersionId: z.literal("Schedule Rating Indigo"),
    ListofApplicableInsuredItems: z.tuple([
        z.object({
            InsuredItemInterfaceId: IndividualExternalIdentifierSchema,
        }),
    ]),
    ListofSubscribedServices: z.tuple([
        z.object({
            SubscribedServiceId: z.string().includes("CoverageExternalIdentifierINDIVIDUAL:"),
        }),
    ]),
    PriceFactorDiscountOrSurcharge: z.string().refine(function (val) { return validator.isIn(val, ["1", "2"]); }),
    TargetFactor: z.number(),
});
