import { z } from "zod";
import { IndividualExternalIdentifierSchema } from "../general";
/**
 * Midlevel risk for individual provider quotes
 */
export var MidlevelRiskForWebAPISchema = z.object({
    Class: z.literal("clsInsuredItemMedicalProfessional"),
    InsuredItemTypeId: z.literal("Individual Provider"),
    InsuredItemExternalIdentifier: IndividualExternalIdentifierSchema,
    "MPL-IP-IndividualProviderSpecialty": z.string().default(""),
    "MPL-IP-IndividualProviderType": z.literal("1902"),
    RiskEffectiveDate: z.string(),
    OriginalEffectiveDate: z.string(),
    FirstRatingState: z.string(),
    FirstRatingCounty: z.string(),
    InsuredItemStatus: z.literal("2"),
    RiskCreatedByThisContractVersionNumber: z.literal(1),
    RiskPrimaryPriorActsDates: z.string(),
    CustomerId: z.string().includes("|clsIndividualProviderCustomer"),
    FIPS: z.string(),
    ListofSubRisksForWebAPI: z.tuple([]),
    "MPL-IPPCF-SpecialtyPAa": z.string().optional().default(""),
});
/**
 * Midlevel sub risk for entity quotes
 */
export var MidlevelSubRiskSchema = z.object({
    Class: z.literal("clsInsuredItemMedicalProfessional"),
    InsuredItemTypeId: z.literal("Individual Provider"),
    SubRiskExternalIdentifier: IndividualExternalIdentifierSchema,
    "SubRiskMPL-IP-IndividualProviderSpecialty": z.string().default(""),
    "SubRiskMPL-IP-IndividualProviderType": z.literal("1902"),
    SubRiskRiskEffectiveDate: z.string(),
    SubRiskOriginalEffectiveDate: z.string(),
    SubRiskFirstRatingState: z.string(),
    SubRiskFirstRatingCounty: z.string(),
    SubRiskInsuredItemStatus: z.literal("2"),
    SubRiskCreatedByThisContractVersionNumber: z.literal(1),
    PrimaryPriorActsDates: z.string(),
    CustomerId: z.string().includes("|clsIndividualProviderCustomer"),
    SubFIPS: z.string(),
    "MPL-IPPCF-SpecialtyPAa": z.string().optional().default(""),
});
