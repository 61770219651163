import { Menu, MenuButton, MenuList, MenuItem, Button } from "@chakra-ui/react";
import { AddIcon } from "@chakra-ui/icons";

interface AddProviderCardProps {
  handleAddProvider: () => void;
  handleAddMidlevel: () => void;
  handleAddEntity: () => void;
}

export function AddProviderCard({
  handleAddEntity,
  handleAddMidlevel,
  handleAddProvider,
}: AddProviderCardProps) {
  return (
    <Menu matchWidth={true}>
      {({ isOpen }) => (
        <>
          <MenuButton
            variant="outline"
            colorScheme="indigo"
            borderRadius="3px"
            mx="4px"
            isActive={isOpen}
            as={Button}
            rightIcon={<AddIcon mt="5px" boxSize={3} />}
            data-cy="add-new-button"
          >
            Add New
          </MenuButton>
          <MenuList fontWeight="normal">
            <MenuItem
              data-cy="create-new-provider-button"
              _hover={{ background: "indigo.200" }}
              onClick={handleAddProvider}
            >
              Individual Provider
            </MenuItem>
            <MenuItem
              data-cy="create-new-midlevel-button"
              _hover={{ background: "indigo.200" }}
              onClick={handleAddMidlevel}
            >
              Non-Physician Provider
            </MenuItem>
            <MenuItem
              data-cy="create-new-entity-button"
              _hover={{ background: "indigo.200" }}
              onClick={handleAddEntity}
            >
              Medical Practice
            </MenuItem>
          </MenuList>
        </>
      )}
    </Menu>
  );
}
