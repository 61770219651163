import { Flex, useRadioGroup, Text, Box } from "@chakra-ui/react";
import { CustomRadio } from "./CustomRadio";
import { CircleIcon } from "components";
import { Match } from "hooks";

interface OptionCardProps {
  unresolvedNpis: Match[];
  onChangeSelectedNpi: (npi: string) => void;
  selectedNpi?: string | null;
}
export function OptionCard({ selectedNpi, unresolvedNpis, onChangeSelectedNpi }: OptionCardProps) {
  const { getRootProps, getRadioProps } = useRadioGroup({
    defaultValue: selectedNpi || "",
    name: "npi",
    onChange: onChangeSelectedNpi,
  });
  const group = getRootProps();

  return (
    <Flex direction="column" gap="10px" {...group} tabIndex={0}>
      {unresolvedNpis?.map((unresolved, index) => {
        const radio = getRadioProps({ value: `${unresolved.npi}` });
        return (
          <CustomRadio key={index} radioProps={radio}>
            <Flex justifyContent="space-between" direction={["column", "row"]}>
              <Flex>
                <CircleIcon pr="12px" boxSize={9} color={radio.isChecked ? "indigo" : "white"} />
                <Flex direction="column">
                  <Box textTransform="capitalize">{unresolved.providerName.toLowerCase()}</Box>
                  <Box textTransform="capitalize">{unresolved.specialty}</Box>
                  <Flex w="100%" gap="5px" textTransform="capitalize" fontWeight={500}>
                    <Text fontWeight="700">NPPES Address:</Text>
                    {unresolved.address.toLowerCase()}
                  </Flex>
                </Flex>
              </Flex>
              <Flex direction="row">NPI: {unresolved.npi}</Flex>
            </Flex>
          </CustomRadio>
        );
      })}
    </Flex>
  );
}
