import { Defaults } from "../../defaults";
import validator from "validator";
import { z } from "zod";
import { IndividualExternalIdentifierSchema } from "../general";
/**
 * Provider risk for individual provider quotes
 */
export var ProviderRiskForWebAPISchema = z.object({
    Class: z.literal("clsInsuredItemMedicalProfessional"),
    InsuredItemTypeId: z.literal("Individual Provider"),
    InsuredItemExternalIdentifier: IndividualExternalIdentifierSchema,
    RiskEffectiveDate: z.string(),
    "MPL-IP-IndividualProviderSpecialty": z.string(),
    "MPL-IP-IndividualProviderType": z.literal("1901"),
    FirstRatingState: z.string(),
    FirstRatingCounty: z.string(),
    RiskCreatedByThisContractVersionNumber: z.literal(1),
    IsPrimary: z.boolean(),
    OriginalEffectiveDate: z.string(),
    InsuredItemStatus: z.literal("2"),
    RiskPrimaryPriorActsDates: z.string(),
    "MPL-IP-PartTimePractice": z
        .string()
        .optional()
        .transform(function (val) { return val !== null && val !== void 0 ? val : undefined; }),
    MPLIPIndividualProviderTeachingPhysicianPercentage: z.string().optional().default("0"),
    MPLIPIndividualProviderTeachingPhysicianPercentageYesNo: z
        .string()
        .refine(function (val) { return validator.isIn(val, ["1", "2"]); }),
    "MPL-InsuredItem-ApplicationDate": z.string(),
    MPLIPIndividualProviderResidencyAndFellowshipProgramsPercentageYesNo: z
        .string()
        .refine(function (val) { return validator.isIn(val, ["1", "2"]); }),
    MPLIPIndividualProviderResidencyAndFellowshipProgramsPercentage: z
        .string()
        .optional()
        .default("0"),
    "MPL-IP-RetiredVolunteer": z.boolean().optional().default(false),
    CustomerId: z.string().includes("|clsIndividualProviderCustomer"),
    FIPS: z.string(),
    BrokerSpecialty: z.string().optional().default(""),
    ExpiringPremium: z.number().default(0),
    "MPL-InsuredItem-PrimaryRecommendedPremium": z.number().default(0),
    ModelRiskScore: z.string().optional().default(Defaults.MRS),
    ListofSubRisksForWebAPI: z.tuple([]),
    "MPL-IPPCF-SpecialtyPAa": z.string().optional().default(""),
});
/**
 * Provider sub risk for entity quotes
 */
export var ProviderSubRiskSchema = z.object({
    Class: z.literal("clsInsuredItemMedicalProfessional"),
    InsuredItemTypeId: z.literal("Individual Provider"),
    SubRiskExternalIdentifier: IndividualExternalIdentifierSchema,
    "SubRiskMPL-IP-IndividualProviderSpecialty": z.string().default(""),
    "SubRiskMPL-IP-IndividualProviderType": z.literal("1901"),
    SubRiskRiskEffectiveDate: z.string().min(1),
    SubRiskOriginalEffectiveDate: z.string().min(1),
    SubRiskFirstRatingState: z.string(),
    SubRiskFirstRatingCounty: z.string(),
    SubRiskInsuredItemStatus: z.literal("2"),
    SubRiskCreatedByThisContractVersionNumber: z.literal(1),
    PrimaryPriorActsDates: z.string(),
    CustomerId: z.string().includes("|clsIndividualProviderCustomer"),
    "SubMPL-IP-PartTimePractice": z
        .string()
        .optional()
        .transform(function (val) { return val !== null && val !== void 0 ? val : undefined; }),
    SubMPLIPIndividualProviderTeachingPhysicianPercentage: z.string().optional().default("0"),
    SubMPLIPIndividualProviderTeachingPhysicianPercentageYesNo: z
        .string()
        .refine(function (val) { return validator.isIn(val, ["1", "2"]); }),
    "MPL-InsuredItem-ApplicationDate": z.string(),
    SubMPLIPIndividualProviderResidencyAndFellowshipProgramsPercentageYesNo: z
        .string()
        .refine(function (val) { return validator.isIn(val, ["1", "2"]); }),
    SubMPLIPIndividualProviderResidencyAndFellowshipProgramsPercentage: z
        .string()
        .optional()
        .default("0"),
    "SubRiskMPL-IP-RetiredVolunteer": z.boolean().optional().default(false),
    SubFIPS: z.string(),
    subBrokerSpecialty: z.string().optional().default(""),
    subExpiringPremium: z.number().default(0),
    "subMPL-InsuredItem-PrimaryRecommendedPremium": z.number().default(0),
    subModelRiskScore: z.string().optional().default(Defaults.MRS),
    "MPL-IPPCF-SpecialtyPAa": z.string().optional().default(""),
});
