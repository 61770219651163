var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { extendTheme } from "@chakra-ui/react";
import { colors } from "./colors";
import { typography } from "./typography";
import { components } from "./components";
import { radii } from "./radii";
import { shadows } from "./shadows";
export var theme = extendTheme(__assign(__assign({}, typography), { colors: colors, components: components, radii: radii, shadows: shadows, styles: {
        global: {
            "@keyframes slideIn": {
                from: {
                    transform: "translateY(-100%)",
                    opacity: 0,
                },
                to: {
                    transform: "translateY(0)",
                    opacity: 1,
                },
            },
            "@keyframes slideUp": {
                from: {
                    transform: "translateY(0)",
                    opacity: 1,
                },
                to: {
                    transform: "translateY(-100%)",
                    opacity: 0,
                },
            },
        },
    } }));
