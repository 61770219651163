import { Card, CardHeader, CardBody, Flex, Table, Tr, Td, Text, Tbody } from "@chakra-ui/react";
import { WarningTwoIcon } from "@chakra-ui/icons";

interface ClaimsDetailProps {
  claims: string[];
}
export function ClaimsDetail({ claims }: ClaimsDetailProps) {
  return (
    <Card w="100%" p="16px" overflow="scroll">
      <CardHeader lineHeight="115%" fontWeight="bold" p="16px 8px 16px 0px">
        Claims Data
      </CardHeader>
      <CardBody p="0">
        <Flex w="100%" justifyContent="space-between" gap="12px">
          <Card
            border="1px solid"
            borderColor="gray.200"
            boxShadow="none"
            w="100%"
            height="max-content"
          >
            <CardHeader p="16px 16px 8px 16px">
              <Text
                fontSize="sm"
                color="gray.450"
                fontWeight="700"
                style={{ textTransform: "uppercase" }}
              >
                Total Found
              </Text>
            </CardHeader>
            <CardBody p="16px">
              <Flex gap="6px" w="100%" alignItems="center" justifyContent="flex-start">
                <WarningTwoIcon color="red.500" fontSize="md" />
                <Text fontSize="2xl">
                  {claims.length} Claim{claims.length !== 1 ? "s" : ""}
                </Text>
              </Flex>
            </CardBody>
          </Card>
          <Card w="100%">
            <CardHeader p="16px 16px 8px 16px">
              <Text
                fontSize="sm"
                color="gray.450"
                fontWeight="700"
                style={{ textTransform: "uppercase" }}
              >
                Dates
              </Text>
            </CardHeader>

            <Table variant="striped">
              <Tbody>
                {claims?.map((claim, index) => {
                  return (
                    <Tr key={index}>
                      <Td fontSize="xs" p="8px 16px">
                        {claim}
                      </Td>
                    </Tr>
                  );
                })}
              </Tbody>
            </Table>
          </Card>
        </Flex>
      </CardBody>
    </Card>
  );
}
