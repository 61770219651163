import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { ButtonGroup, Text, Container, Image, Flex, Button } from "@chakra-ui/react";
import { useMutation } from "@apollo/client";
import { SAVE_SUBMISSION } from "mutations";
import { GET_SUBMISSION } from "queries";
import { generateId } from "utils";

export function NewInsight() {
  const navigate = useNavigate();
  const [mutateFunction] = useMutation(SAVE_SUBMISSION, {
    refetchQueries: [GET_SUBMISSION],
    awaitRefetchQueries: true,
  });
  const [loading, setLoading] = useState(false);
  const env = process.env.REACT_APP_ENV || "dev";
  return (
    <Container>
      <Flex pt="100px" px="100px" justifyContent="center">
        <Image
          h="100px"
          src="https://uploads-ssl.webflow.com/645029572ba4d37613789f79/645029572ba4d36e42789f80_230426_Indigo_Logo_Primary-p-1080.png"
          alt="Indigo Logo"
        />
      </Flex>
      <Flex pt="0px" px="100px" justifyContent="center">
        <Text fontSize="6xl">Insight Tool</Text>
      </Flex>
      <Flex p="100px" justifyContent="center">
        {env !== "main" && (
          <ButtonGroup>
            <Button
              onClick={async () => {
                setLoading(true);
                const id = generateId();
                await mutateFunction({
                  variables: {
                    input: {
                      id,
                    },
                  },
                });
                navigate(`/insights/${id}`);
              }}
              colorScheme="indigo"
              isLoading={loading}
              data-cy="create-blank-submission-button"
            >
              Create blank submission
            </Button>
            <Button
              onClick={async () => {
                setLoading(true);
                const id = generateId();
                await mutateFunction({
                  variables: {
                    input: {
                      id,
                      quoteProperties: {
                        effectiveDate: "05/03/2024",
                        modelInfo: {
                          modelId: "65e57a483c00002700af2c50",
                          modelVersion: "v1.1.6.2",
                        },
                      },
                      producer: {
                        id: "qaqG2Lz",
                        name: "Johnny Utah",
                        manager: "",
                        managerId: "",
                        producerId: "19003",
                        email: "partime@example.com",
                        agency: {
                          name: "Keanu Insurance Group",
                          agencyId: "00015154",
                        },
                      },
                      midlevels: [],
                      providers: [
                        {
                          id: generateId(),
                          firstName: "David",
                          lastName: "Jones",
                          suffix: "",
                          specialty: "",
                          npi: "1942292412",
                          indigoSpecialty: "Family Medicine-Minor Surgery",
                          nppesCity: "DECATUR",
                          nppesState: "IL",
                          nppesSpecialty: "Family Medicine",
                          deductible: "None",
                          limitType: "Separate",
                          limit: "_1M_3M",
                          retroDate: "04/24/2001",
                          scheduledRatingFactor: 0.19999999999999996,
                          excludedNpi: false,
                          address: {
                            streetName: "1770 E Lake Shore Dr",
                            city: "Decatur",
                            state: "IL",
                            zip: "62521",
                            countyFips: "17115",
                            countyName: "Macon",
                          },
                          boardActions: [
                            {
                              details:
                                "Default on Health Education Loan or Scholarship Obligations",
                              orderDate: "7/20/2005 12:00:00 AM",
                              reportingEntity:
                                "Illinois Department of Financial and Professional Regulation",
                              severity: 2,
                              summary:
                                "RESTRICTIONS/LIMITATIONS PLACED ON PHYSICIAN ASSISTANT LICENSE/REGISTRATION/PERMIT",
                            },
                            {
                              details: "Not Applicable",
                              orderDate: "12/21/2006 12:00:00 AM",
                              reportingEntity:
                                "Illinois Department of Financial and Professional Regulation",
                              severity: 1,
                              summary: "PROBATION TERMINATED",
                            },
                          ],
                        },
                      ],
                    },
                  },
                });
                navigate(`/insights/${id}/model-insights`);
              }}
              colorScheme="indigo"
              isLoading={loading}
              data-cy="create-dummy-submission-button"
            >
              Create Dummy Submission
            </Button>
          </ButtonGroup>
        )}
      </Flex>
    </Container>
  );
}
