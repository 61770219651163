import { Amplify } from "aws-amplify";
export function setupAmplify() {
    Amplify.configure({
        Auth: {
            Cognito: getCognitoParams(),
        },
    });
}
function getCognitoParams() {
    if (!process.env.REACT_APP_USER_POOL_ID ||
        !process.env.REACT_APP_USER_POOL_CLIENT_ID ||
        !process.env.REACT_APP_IDENTITY_POOL_ID) {
        throw new Error("Missing required auth environment variables. Must define REACT_APP_USER_POOL_ID, " +
            "REACT_APP_USER_POOL_CLIENT_ID, and REACT_APP_IDENTITY_POOL_ID.");
    }
    console.log("Successfully found auth environment variables");
    return {
        userPoolId: process.env.REACT_APP_USER_POOL_ID,
        userPoolClientId: process.env.REACT_APP_USER_POOL_CLIENT_ID,
        identityPoolId: process.env.REACT_APP_IDENTITY_POOL_ID,
    };
}
