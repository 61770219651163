import { Tag, Text } from "@chakra-ui/react";
import { SuggestedAction } from "localTypes";

interface SuggestionsActionTagProps {
  suggestedAction?: SuggestedAction;
}

export function SuggestedActionTag({ suggestedAction }: SuggestionsActionTagProps) {
  switch (suggestedAction) {
    case SuggestedAction.Review:
      return (
        <Tag
          p="5px 10px 5px 10px"
          gap="8px"
          borderRadius="20px"
          colorScheme="yellow"
          lineHeight="none"
          fontSize="sm"
          fontWeight="800"
          variant="solid"
        >
          <Text mt="-2px" casing="uppercase">
            Review
          </Text>
        </Tag>
      );

    case SuggestedAction.Quote:
      return (
        <Tag
          p="5px 10px 5px 10px"
          gap="8px"
          borderRadius="20px"
          colorScheme="green"
          lineHeight="none"
          fontSize="sm"
          fontWeight="800"
          variant="solid"
        >
          <Text mt="-2px" casing="uppercase">
            Quote
          </Text>
        </Tag>
      );

    case SuggestedAction.Decline:
      return (
        <Tag
          p="5px 10px 5px 10px"
          gap="8px"
          borderRadius="20px"
          colorScheme="red"
          lineHeight="none"
          fontSize="sm"
          fontWeight="800"
          variant="solid"
        >
          <Text mt="-2px" casing="uppercase">
            Decline
          </Text>
        </Tag>
      );

    default:
      return null;
  }
}
