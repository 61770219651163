import { ChangeEvent, useEffect, useState, useContext, useCallback } from "react";
import { useParams } from "react-router-dom";
import {
  Button,
  IconButton,
  Heading,
  useDisclosure,
  Box,
  Flex,
  Textarea,
  Slide,
  useToast,
} from "@chakra-ui/react";
import { ApiClientContext } from "providers";
import { RiFolder3Fill, RiFileList2Line, RiCloseFill } from "react-icons/ri";
import { Documents, Notes } from "components";
import { GetSubmissionDocumentUrlsResult } from "@app-stack/types/submission_documents";
import { Note, NoteInput, NoteType } from "__generated__/graphql";
import { generateId } from "utils";

import JSZip from "jszip";

const zip = new JSZip();

function formatDocumentName(name: string) {
  return name.split("/").pop() || "";
}

interface SidePanelProps {
  notes: Note[];
  brokerNotes?: Note;
  handleAddNote: (note: NoteInput) => Promise<void>;
  author?: string;
  defaultOpen?: boolean;
}
export function SidePanel({
  author,
  brokerNotes,
  notes,
  handleAddNote,
  defaultOpen,
}: SidePanelProps) {
  const { id } = useParams();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [selectedTab, setSelectedTab] = useState<"downloads" | "notes" | undefined>(undefined);
  const [documents, setDocuments] = useState<GetSubmissionDocumentUrlsResult[]>([]);
  const [isDownloading, setIsDownloading] = useState<boolean>(false);
  const [noteType, setNoteType] = useState<NoteType>(NoteType.WriteUp);
  const [noteContent, setNoteContent] = useState<string>("");
  const apiClient = useContext(ApiClientContext);
  const [isLoading, setIsLoading] = useState(false);
  const toast = useToast();

  const fetchDocuments = useCallback(async () => {
    try {
      if (id) {
        const docs = ((await apiClient?.getSubmissionDocumentUrls({ id })) || []).map((doc) => ({
          ...doc,
          key: formatDocumentName(doc.key),
        }));

        setDocuments(docs);
      }
    } catch (err) {
      console.error(err);
    }
  }, [apiClient, id]);

  useEffect(() => {
    fetchDocuments();
  }, [fetchDocuments]);

  useEffect(() => {
    if (defaultOpen === true) {
      onOpen();
      setSelectedTab("downloads");
    }
  }, [defaultOpen, onOpen]);

  async function handleSaveNote() {
    try {
      setIsLoading(true);
      const note: NoteInput = {
        id: generateId(),
        content: noteContent,
        type: noteType,
        author: author || "publicuniversalfriend@getindigo.com",
        createdAt: new Date().toISOString(),
      };
      setNoteContent("");
      await handleAddNote(note);
    } catch (e) {
      toast({
        title: "Error",
        description: "Unable to save note!",
        duration: 5000,
        status: "error",
        isClosable: true,
      });
      console.error(e);
    } finally {
      setIsLoading(false);
    }
  }

  async function downloadAll() {
    try {
      setIsDownloading(true);
      const docs = zip.folder(`${id}`);

      for (let i = 0; i < documents.length; i++) {
        const doc = documents[i];

        const f = await fetch(doc?.downloadUrl);
        const blob = await f.blob();
        docs?.file(doc?.key, blob);
      }

      const content = await zip.generateAsync({ type: "blob" });
      const url = URL.createObjectURL(content);
      const a = document.createElement("a");
      a.href = url;
      a.download = `${id}.zip`;
      a.click();
      URL.revokeObjectURL(url);
    } catch (e) {
      console.error(e);
    } finally {
      setIsDownloading(false);
    }
  }

  return (
    <Box w="332px">
      <Box
        textAlign="left"
        position="fixed"
        borderLeft="1px"
        borderLeftColor="gray.200"
        bgColor="white"
        right="0"
        top="50vh"
        minH="100vh"
        w="48px"
        transform="translateY(-50%)"
        pt="130px"
        style={{ zIndex: 10 }}
      >
        <Flex direction="column">
          <IconButton
            aria-label="Open documents"
            icon={<RiFolder3Fill />}
            color="indigo.500"
            bgColor="white"
            onClick={() => {
              if (isOpen && selectedTab === "downloads") {
                onClose();
                setSelectedTab(undefined);
              } else {
                onOpen();
                setSelectedTab("downloads");
              }
            }}
          />
          <IconButton
            aria-label="Open notes"
            color="indigo.500"
            bgColor="white"
            icon={<RiFileList2Line />}
            onClick={() => {
              if (isOpen && selectedTab === "notes") {
                onClose();
                setSelectedTab(undefined);
              } else {
                onOpen();
                setSelectedTab("notes");
              }
            }}
          />
        </Flex>
      </Box>
      {/* Drawer */}
      <Slide
        direction="right"
        in={isOpen}
        style={{ zIndex: 9, width: "332px", paddingTop: "120px" }}
      >
        {selectedTab !== "notes" ? (
          <Flex
            w="284px"
            rounded="md"
            shadow="md"
            h="100%"
            bg="white"
            right="48px"
            direction="column"
          >
            <Flex
              justifyContent="space-between"
              alignItems="center"
              p="16px"
              borderBottom="1px"
              borderColor="gray.200"
            >
              <Heading size="sm" fontWeight="600">
                Downloads
              </Heading>
              <IconButton
                onClick={() => {
                  onClose();
                  setSelectedTab(undefined);
                }}
                borderRadius="3px"
                aria-label="Close Drawer"
                icon={<RiCloseFill />}
                color="black"
                fontSize="24px"
                bgColor="white"
              />
            </Flex>
            <Documents docs={documents} />
            <Flex>
              <Box
                p="16px"
                display="flex"
                position="absolute"
                bottom="0px"
                borderTop="1px"
                borderTopColor="gray.300"
                flexDirection="column"
                alignItems="flex-end"
                background="white"
                justifyContent="flex-end"
              >
                <Button
                  onClick={downloadAll}
                  w="252px"
                  m="0 auto"
                  colorScheme="indigo"
                  variant="outline"
                  loadingText="Downloading..."
                  isLoading={isDownloading}
                >
                  Download All
                </Button>
              </Box>
            </Flex>
          </Flex>
        ) : (
          <Flex
            direction="column"
            w="284px"
            rounded="md"
            shadow="md"
            h="100%"
            bg="white"
            right="48px"
          >
            <Flex
              flex="none"
              justifyContent="space-between"
              alignItems="center"
              p="16px"
              borderBottom="1px"
              borderColor="gray.200"
            >
              <Heading size="sm" fontWeight="600">
                Notes
              </Heading>
              <IconButton
                onClick={() => {
                  onClose();
                  setSelectedTab(undefined);
                }}
                aria-label="Close Drawer"
                icon={<RiCloseFill />}
                borderRadius="3px"
                color="black"
                fontSize="24px"
                bgColor="white"
              />
            </Flex>
            <Notes
              brokerNotes={brokerNotes}
              noteType={noteType}
              notes={notes}
              setNoteType={(noteType: NoteType) => setNoteType(noteType)}
            />
            <Box flex="none" mt="auto" pb="16px" textAlign="center">
              <Textarea
                onChange={(e: ChangeEvent<HTMLTextAreaElement>) => {
                  setNoteContent(e.target.value);
                }}
                value={noteContent}
                w="252px"
                mb="16px"
                resize="none"
                lineHeight="115%"
              />
              <Button
                onClick={handleSaveNote}
                isLoading={isLoading}
                isDisabled={noteContent.length === 0}
                loadingText="Saving Note"
                w="252px"
                m="0 auto"
                colorScheme="indigo"
              >
                Add
              </Button>
            </Box>
          </Flex>
        )}
      </Slide>
    </Box>
  );
}
