import { useContext, useCallback, useState } from "react";
import { ApiClientContext } from "providers";
import { useParams } from "react-router-dom";
import {
  Divider,
  InputGroup,
  InputLeftAddon,
  ModalFooter,
  Button,
  Text,
  ModalContent,
  Modal,
  ModalOverlay,
  ModalHeader,
  ModalBody,
  Flex,
  ModalCloseButton,
  FormControl,
  FormLabel,
  Input,
  FormErrorMessage,
  Textarea,
} from "@chakra-ui/react";
import { WarningIcon, AttachmentIcon } from "@chakra-ui/icons";
import { useMutation } from "@apollo/client";
import { UPDATE_QUOTE_PROPERTIES } from "mutations";
import { DEFAULT_CC_EMAIL_ADDRESSES, DEFAULT_BCC_EMAIL_ADDRESSES } from "app-constants";
import { GET_SUBMISSION } from "queries";
import { useForm } from "react-hook-form";
import { QuotePropertiesInput, Note } from "__generated__/graphql";
import { downloadDocument } from "utils";

interface SendDocumentProps {
  onClose: () => void;
  quoteProperties?: QuotePropertiesInput;
  isQIP: boolean;
  subjectivities?: Note[];
  brokerEmail?: string;
  previewQuoteDocument: (
    id: string,
    policyExclusions: string,
  ) => Promise<{ documentPreviewUrl: string } | undefined>;
}
export function SendDocument({
  subjectivities,
  onClose,
  quoteProperties,
  isQIP,
  brokerEmail,
  previewQuoteDocument,
}: SendDocumentProps) {
  const apiClient = useContext(ApiClientContext);
  const [isLoadingPreview, setIsLoadingPreview] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { id } = useParams();
  const [updateQuoteProperties] = useMutation(UPDATE_QUOTE_PROPERTIES, {
    refetchQueries: [GET_SUBMISSION],
    awaitRefetchQueries: true,
  });
  const subjectivitiesContent = subjectivities?.map((note: Note) => `${note.content}\n`).join("");
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm<QuotePropertiesInput>({
    defaultValues: {
      emailAddresses: {
        to: brokerEmail || "",
        cc: quoteProperties?.emailAddresses?.cc || DEFAULT_CC_EMAIL_ADDRESSES,
        bcc: quoteProperties?.emailAddresses?.bcc || DEFAULT_BCC_EMAIL_ADDRESSES,
      },
      policyExclusions: (quoteProperties?.policyExclusions || "").concat(
        subjectivitiesContent || "",
      ),
      isQIP,
    },
  });
  const { policyExclusions: policyExclusionsData } = watch();
  const onSubmit = useCallback(
    async (quoteProperties: QuotePropertiesInput) => {
      try {
        setIsSubmitting(true);
        await updateQuoteProperties({
          variables: {
            submissionId: id,
            quoteProperties,
          },
        });
        await apiClient?.sendQuoteDocument({
          submissionId: id || "",
          policyExclusions: policyExclusionsData || "",
        });
        onClose();
      } catch (e) {
        console.error(e);
      } finally {
        setIsSubmitting(false);
      }
    },
    [id, updateQuoteProperties, onClose, apiClient, policyExclusionsData],
  );

  return (
    <Modal size="xl" isOpen={true} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader pb="4px">
          <Flex direction="column" mb="8px">
            <Text lineHeight="none" fontSize="3xl">
              {isQIP ? "QIP" : "Quote"}
            </Text>
          </Flex>
        </ModalHeader>
        <ModalCloseButton />
        <form data-testid="declination-form" onSubmit={handleSubmit(onSubmit)}>
          <ModalBody pb="20px" pt="16px">
            <Flex direction="column" gap="8px">
              <FormControl isInvalid={!!errors?.emailAddresses?.to?.message}>
                <InputGroup>
                  <InputLeftAddon
                    w="60px"
                    fontWeight="bold"
                    backgroundColor="white"
                    borderColor="indigo.300"
                    borderRight="none"
                  >
                    <FormLabel mb="3px" fontWeight="bold" htmlFor={`emailAddresses.to`}>
                      To
                    </FormLabel>
                  </InputLeftAddon>

                  <Input
                    data-testid="emailAddresses.to"
                    borderLeft="none"
                    id="emailAddresses.to"
                    type="text"
                    {...register("emailAddresses.to", {
                      required: "'To' is required",
                    })}
                  />
                </InputGroup>
                <FormErrorMessage>
                  <WarningIcon fontSize="xxs" mr="3.5px" mt="3.5px" color="red.500" />
                  {errors?.emailAddresses?.to?.message}
                </FormErrorMessage>
              </FormControl>
              <FormControl>
                <InputGroup>
                  <InputLeftAddon
                    w="60px"
                    fontWeight="bold"
                    backgroundColor="white"
                    borderColor="indigo.300"
                    borderRight="none"
                  >
                    <FormLabel mb="3px" fontWeight="bold" htmlFor={`emailAddresses.cc`}>
                      Cc
                    </FormLabel>
                  </InputLeftAddon>
                  <Input
                    borderLeft="none"
                    id="emailAddresses.cc"
                    type="text"
                    {...register("emailAddresses.cc")}
                  />
                </InputGroup>
              </FormControl>
              <FormControl>
                <InputGroup>
                  <InputLeftAddon
                    w="60px"
                    fontWeight="bold"
                    backgroundColor="white"
                    borderColor="indigo.300"
                    borderRight="none"
                  >
                    <FormLabel mb="3px" fontWeight="bold" htmlFor={`emailAddresses.bcc`}>
                      Bcc
                    </FormLabel>
                  </InputLeftAddon>
                  <Input
                    borderLeft="none"
                    id="emailAddresses.bcc"
                    type="text"
                    {...register("emailAddresses.bcc")}
                  />
                </InputGroup>
              </FormControl>
              <Divider borderColor="gray.200" py="8px" />
              {isQIP && (
                <FormControl>
                  <FormLabel fontWeight="bold" pt="4px" htmlFor={`policyExclusions`}>
                    Subjectivities
                  </FormLabel>
                  <Textarea
                    _hover={{ borderColor: "indigo.300" }}
                    _focus={{
                      borderColor: "indigo.500",
                      boxShadow: "0 0 0 1px #2C0B9B",
                    }}
                    rows={2}
                    borderRadius="2px"
                    borderColor="indigo.300"
                    resize="none"
                    id="policyExclusions"
                    {...register("policyExclusions")}
                  ></Textarea>
                </FormControl>
              )}
            </Flex>
            <Flex mt="10px">
              <Button
                leftIcon={<AttachmentIcon color="indigo.400" />}
                variant="link"
                isLoading={isLoadingPreview}
                loadingText="Generating document..."
                onClick={async () => {
                  try {
                    setIsLoadingPreview(true);
                    await updateQuoteProperties({
                      variables: {
                        submissionId: id,
                        quoteProperties: { isQIP },
                      },
                    });
                    const response = await previewQuoteDocument(
                      id || "",
                      policyExclusionsData || "",
                    );
                    if (response?.documentPreviewUrl) {
                      downloadDocument({ key: "hello", downloadUrl: response.documentPreviewUrl });
                    }
                  } catch (e) {
                    console.error(e);
                  } finally {
                    setIsLoadingPreview(false);
                  }
                }}
                color="indigo.400"
              >
                Preview {isQIP ? "QIP" : "Quote"} Document
              </Button>
            </Flex>
          </ModalBody>
          <ModalFooter bgColor="gray.100" pb="24px">
            <Button
              isLoading={isSubmitting}
              loadingText="Sending Document"
              mx="0"
              type="submit"
              data-testid="submit"
              colorScheme="indigo"
              w="100%"
            >
              Send {isQIP ? "QIP" : "Quote"}
            </Button>
          </ModalFooter>
        </form>
      </ModalContent>
    </Modal>
  );
}
