import { Image } from "@chakra-ui/react";
import yellow from "assets/background/yellow.svg";
import blue from "assets/background/blue.svg";
import gray from "assets/background/gray.svg";

export function Slime() {
  return (
    <div style={{ width: "100%", marginTop: "-250px", zIndex: -1, overflow: "hidden" }}>
      <Image src={yellow} w="100%" />
      <Image src={blue} mt="-57vw" w="100%" />
      <Image src={gray} mt="-45vw" w="100%" />
    </div>
  );
}
