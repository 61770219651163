import ReactDatePicker, { ReactDatePickerProps } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "theme/datepicker.css";
import { parse } from "date-fns";

function formatDate(date: Date) {
  if (!date) return "";
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");
  return `${month}/${day}/${year}`;
}

function parseDate(dateString: string): Date | null {
  const formats = [
    "MM/dd/yyyy", // e.g., 01/31/2004
    "MM/dd/yy", // e.g., 01/31/04
    "M/d/yyyy", // e.g., 1/31/2004 (less strict)
    "M/d/yy", // e.g., 1/31/04 (less strict)
    "dd/MM/yyyy", // e.g., 31/01/2004
    "d/M/yyyy", // e.g., 31/1/2004 (less strict)
    "yyyy-MM-dd", // ISO format, e.g., 2004-01-31
    "MM-dd-yyyy", // e.g., 01-31-2004
    "MM-dd-yy", // e.g., 01-31-04
    "dd-MM-yyyy", // e.g., 31-01-2004
    "yyyy/MM/dd", // e.g., 2004/01/31
    "yyyy/dd/MM", // less common, e.g., 2004/31/01
  ];
  for (const format of formats) {
    try {
      const parsedDate = parse(dateString, format, new Date());
      if (!isNaN(parsedDate.getTime())) {
        return parsedDate;
      }
    } catch (e) {
      if (dateString) {
        console.warn("unable to match date format", e, format, dateString);
      }
    }
  }

  return null;
}

export function DatePicker({
  id,
  selected,
  onChange,
  includeDateIntervals,
  invalid,
}: {
  selected: string;
  onChange: (dateString: string) => void;
  includeDateIntervals?: ReactDatePickerProps["includeDateIntervals"];
  invalid?: boolean;
  id?: string;
}) {
  const _handleChange = (date: Date) => onChange(formatDate(date));
  return (
    <div className={`custom-container ${invalid ? "error" : ""}`}>
      <ReactDatePicker
        id={id || "date-picker"}
        popperPlacement="bottom-end"
        portalId="root-portal"
        selected={parseDate(selected)}
        onChange={_handleChange}
        includeDateIntervals={includeDateIntervals}
      />
    </div>
  );
}
