import { z } from "zod";
import { MidlevelCoverageSchema, MidlevelCyberCoverageSchema, MidlevelSCMMACoverageSchema, MidlevelPCFPAICoverageSchema, } from "./midlevel/coverage";
import { MidlevelPremiumFactorSchema } from "./midlevel/premium_factor";
import { MidlevelRiskForWebAPISchema } from "./midlevel/risk";
import { ExternalServicingActorsSchema, InternalServicingActorsSchema } from "./servicing_actors";
import { ProviderCoverageSchema, ProviderCyberCoverageSchema, ProviderSCMMACoverageSchema, ProviderPCFPAICoverageSchema, } from "./provider/coverage";
import { ProviderInsuredItemSchema } from "./provider/insured_item";
import { ProviderPremiumFactorSchema, AmaProviderPremiumFactorSchema, } from "./provider/premium_factor";
import { ProviderRiskForWebAPISchema } from "./provider/risk";
export var CoverageSchema = z.union([
    ProviderCoverageSchema,
    ProviderCyberCoverageSchema,
    ProviderSCMMACoverageSchema,
    ProviderPCFPAICoverageSchema,
    MidlevelCoverageSchema,
    MidlevelCyberCoverageSchema,
    MidlevelSCMMACoverageSchema,
    MidlevelPCFPAICoverageSchema,
]);
export var RiskSchema = z.union([ProviderRiskForWebAPISchema, MidlevelRiskForWebAPISchema]);
export var PremiumFactorSchema = z.union([
    ProviderPremiumFactorSchema,
    AmaProviderPremiumFactorSchema,
    MidlevelPremiumFactorSchema,
]);
export var CreateQuoteInputSchema = z.object({
    PolicyJSON: z.object({
        ListofProductVersion: z.tuple([
            z.object({
                ProductVersionId: z.literal("Admitted - Medical Professional Liability Fleet"),
            }),
        ]),
        PolicyHolderNumber: z.string(),
        PolicyTerm: z.literal("1"),
        EffectiveDate: z.string(),
        BillingFrequency: z.literal("3"),
        MPLPolicyTransaction: z.literal("1901"),
        PolicyType: z.literal("1901"),
        MPLPolicyForm: z.literal("1901"),
        MPLPolicyRenewalType: z.literal("1902"),
        "MPL-ExcessMinimumPremium": z.literal("1901"),
        ListOfRisksForWebAPI: z.array(RiskSchema),
        ListofInsuredItems: z.array(ProviderInsuredItemSchema),
        ListofCoverages: z.array(CoverageSchema),
        ListofExternalServicingActors: ExternalServicingActorsSchema,
        ListofInternalServicingActors: InternalServicingActorsSchema,
        ListofPremiumFactors: z.array(ProviderPremiumFactorSchema.or(AmaProviderPremiumFactorSchema).or(MidlevelPremiumFactorSchema)),
        AgentBillType: z.literal("3"), // 1 = Agency Bill, 2 = Statement Billing, 3 = Direct Bill
        ModelID: z.string(),
        InquiryIDQuote: z.string(),
        PreviousCarrier: z.string(),
        QuoteTargetPremium: z.number(),
        QuoteExpiringPremium: z.number(),
        QuoteRenewalPremium: z.number(),
        InitialSubmissionFull: z.boolean(),
        "Status–UserAssigned": z.string().optional(),
        DeclineQuoteReason: z.string().optional(),
        OtherDeclineReason: z.string().optional(),
    }),
});
