var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { getNppesApiResultItemFirstName, getNppesApiResultItemLastName, } from "@app-stack/types/nppes";
import { getSpecialtyDetails } from "app-constants";
import { ApiClientContext } from "providers";
import { useCallback, useContext, useState } from "react";
export function useNpiSearch() {
    var _this = this;
    var _a = useState(false), isLoading = _a[0], setIsLoading = _a[1];
    var _b = useState(undefined), matches = _b[0], setMatches = _b[1];
    var apiClient = useContext(ApiClientContext);
    var fetchMatches = useCallback(function (provider) { return __awaiter(_this, void 0, void 0, function () {
        var responseData, newMatches, uniqueMatches, seenNpis, _i, newMatches_1, match, e_1;
        var _a, _b;
        return __generator(this, function (_c) {
            switch (_c.label) {
                case 0:
                    setIsLoading(true);
                    _c.label = 1;
                case 1:
                    _c.trys.push([1, 3, 4, 5]);
                    return [4 /*yield*/, (apiClient === null || apiClient === void 0 ? void 0 : apiClient.npiSearch({
                            params: (provider === null || provider === void 0 ? void 0 : provider.npi)
                                ? [
                                    {
                                        first_name: (provider === null || provider === void 0 ? void 0 : provider.firstName) || "",
                                        last_name: (provider === null || provider === void 0 ? void 0 : provider.lastName) || "",
                                        taxonomySearches: [],
                                        number: provider.npi,
                                    },
                                ]
                                : [
                                    {
                                        first_name: (provider === null || provider === void 0 ? void 0 : provider.firstName) || "",
                                        last_name: (provider === null || provider === void 0 ? void 0 : provider.lastName) || "",
                                        taxonomySearches: getSpecialtyDetails(provider === null || provider === void 0 ? void 0 : provider.indigoSpecialty).search || [],
                                        state: ((_a = provider === null || provider === void 0 ? void 0 : provider.address) === null || _a === void 0 ? void 0 : _a.state) || "",
                                        number: (provider === null || provider === void 0 ? void 0 : provider.npi) || "",
                                    },
                                ],
                            useSpecialtyInLookup: true,
                        }))];
                case 2:
                    responseData = _c.sent();
                    newMatches = ((_b = responseData === null || responseData === void 0 ? void 0 : responseData[0]) === null || _b === void 0 ? void 0 : _b.map(function (response) {
                        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p;
                        return ({
                            providerName: "".concat(getNppesApiResultItemFirstName(response), " ").concat(getNppesApiResultItemLastName(response)),
                            npi: (response === null || response === void 0 ? void 0 : response.number) || "",
                            address: "".concat((_b = (_a = response === null || response === void 0 ? void 0 : response.addresses) === null || _a === void 0 ? void 0 : _a[0]) === null || _b === void 0 ? void 0 : _b.address_1, ", ").concat((_d = (_c = response === null || response === void 0 ? void 0 : response.addresses) === null || _c === void 0 ? void 0 : _c[0]) === null || _d === void 0 ? void 0 : _d.city, ", ").concat((_f = (_e = response === null || response === void 0 ? void 0 : response.addresses) === null || _e === void 0 ? void 0 : _e[0]) === null || _f === void 0 ? void 0 : _f.state, " ").concat((_h = (_g = response === null || response === void 0 ? void 0 : response.addresses) === null || _g === void 0 ? void 0 : _g[0]) === null || _h === void 0 ? void 0 : _h.postal_code),
                            city: (_k = (_j = response === null || response === void 0 ? void 0 : response.addresses) === null || _j === void 0 ? void 0 : _j[0]) === null || _k === void 0 ? void 0 : _k.city,
                            state: (_m = (_l = response === null || response === void 0 ? void 0 : response.addresses) === null || _l === void 0 ? void 0 : _l[0]) === null || _m === void 0 ? void 0 : _m.state,
                            specialty: ((_p = (_o = response === null || response === void 0 ? void 0 : response.taxonomies.filter(function (t) { return t === null || t === void 0 ? void 0 : t.primary; })) === null || _o === void 0 ? void 0 : _o[0]) === null || _p === void 0 ? void 0 : _p.desc) ||
                                "",
                        });
                    })) || [];
                    uniqueMatches = [];
                    seenNpis = new Set();
                    for (_i = 0, newMatches_1 = newMatches; _i < newMatches_1.length; _i++) {
                        match = newMatches_1[_i];
                        if (!seenNpis.has(match.npi)) {
                            seenNpis.add(match.npi);
                            uniqueMatches.push(match);
                        }
                    }
                    setMatches(uniqueMatches || []);
                    return [2 /*return*/, uniqueMatches || []];
                case 3:
                    e_1 = _c.sent();
                    console.error("Error fetching npi data", e_1);
                    return [3 /*break*/, 5];
                case 4:
                    setIsLoading(false);
                    return [7 /*endfinally*/];
                case 5: return [2 /*return*/];
            }
        });
    }); }, [apiClient]);
    return { isLoading: isLoading, matches: matches, fetchMatches: fetchMatches };
}
