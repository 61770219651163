import { useEffect, useState } from "react";
import { useParams, Link as RRLink } from "react-router-dom";
import {
  useDisclosure,
  Center,
  Divider,
  Link,
  Flex,
  Box,
  Button,
  ButtonGroup,
  Text,
} from "@chakra-ui/react";
import { SuggestedAction } from "localTypes";
import {
  CompleteReviewButton,
  GroupCard,
  Subnav,
  MidlevelCard,
  QuoteResultsCard,
  QuoteResults,
  StatusMenu,
  SubmissionEventDisplay,
  QuoteErrorCard,
  NotificationWrapper,
  Loader,
  DeclinationModal,
  SendDocument,
  SendDocumentMenu,
} from "components";
import { ProviderCardWrapper } from "./ProviderCardWrapper";
import { radii } from "theme/radii";
import { ChevronRightIcon } from "@chakra-ui/icons";
import { PriceCheckIcon, DevilBoy } from "assets";
import {
  DeclinationInput,
  StatusType,
  Entity,
  Midlevel,
  Provider,
  Event,
  EventState,
  QuotePropertiesInput,
  Note,
  InsightsData,
} from "__generated__/graphql";
import { useMutation } from "@apollo/client";
import { UPDATE_SUBMISSION, UPDATE_PROVIDERS } from "mutations";
import { GET_SUBMISSION } from "queries";
import { AVERAGE_MRS_QUOTE_THRESHOLD, AVERAGE_MRS_QUOTE_THRESHOLD_LARGE } from "app-constants";
import { formatAsDollar, defaultMrsAdjustment } from "utils";
import { useStalePricing } from "providers";

interface ModelInsightsProps {
  quoteResults?: QuoteResults;
  declination?: DeclinationInput;
  providers: Provider[];
  midlevels: Midlevel[];
  entity: Entity;
  srfAdjustment?: number;
  calculatedSrfAdjustment?: number;
  useCalculatedSrfAdjustment?: boolean;
  status?: StatusType;
  submitToSunlight: () => void;
  isSubmitting: boolean;
  isDisabled?: boolean;
  sunlightErrors: string[];
  modelId?: string;
  events?: Event[];
  isLoadingEvents?: boolean;
  fetchPricingData?: () => void;
  isFetchingPricingData?: boolean;
  indigoPremium?: string;
  targetPremium?: string;
  loading?: boolean;
  quoteProperties?: QuotePropertiesInput;
  brokerEmail?: string;
  insightsData: InsightsData;
  subjectivities?: Note[];
  fetchAndSaveMrs: () => Promise<void>;
  previewQuoteDocument: (
    id: string,
    policyExclusions: string,
  ) => Promise<{ documentPreviewUrl: string } | undefined>;
}

export function ModelInsights({
  insightsData,
  loading,
  quoteResults,
  status,
  srfAdjustment,
  useCalculatedSrfAdjustment,
  entity,
  midlevels,
  providers,
  submitToSunlight,
  isSubmitting,
  isDisabled,
  sunlightErrors,
  modelId,
  events,
  fetchPricingData,
  isFetchingPricingData,
  indigoPremium,
  targetPremium,
  declination,
  quoteProperties,
  brokerEmail,
  subjectivities,
  fetchAndSaveMrs,
  previewQuoteDocument,
}: ModelInsightsProps) {
  const documentsAreSent = quoteProperties?.hasSentDocuments;
  const [isQIP, setIsQIP] = useState(false);
  const {
    onOpen: onOpenDeclination,
    isOpen: isOpenDeclination,
    onClose: onCloseDeclination,
  } = useDisclosure();
  const {
    onOpen: onOpenSendDocument,
    isOpen: isOpenSendDocument,
    onClose: onCloseSendDocument,
  } = useDisclosure();

  const {
    averageMrs,
    averageAdjustedMrs,
    bookedSrf,
    providers: cProviders,
    calculatedSrfAdjustment,
    scheduledRatingFactor,
  } = insightsData;
  const threshold =
    providers.length > 1 ? AVERAGE_MRS_QUOTE_THRESHOLD_LARGE : AVERAGE_MRS_QUOTE_THRESHOLD;
  const showDevil =
    events &&
    events?.length > 0 &&
    events?.some((e) => e.state === EventState.Queued || e.state === EventState.Processing);

  const adjustment = useCalculatedSrfAdjustment ? calculatedSrfAdjustment : srfAdjustment;

  const hasStalePricing = useStalePricing();
  const { id } = useParams();
  const [suggestedAction, setSuggestedAction] = useState<SuggestedAction>(SuggestedAction.Quote);
  const [updateProviders] = useMutation(UPDATE_PROVIDERS, {
    refetchQueries: [GET_SUBMISSION],
    awaitRefetchQueries: true,
  });
  const [mutateFunction] = useMutation(UPDATE_SUBMISSION, {
    refetchQueries: [GET_SUBMISSION],
    awaitRefetchQueries: true,
  });

  useEffect(() => {
    if (averageMrs || 0 < threshold) {
      setSuggestedAction(SuggestedAction.Quote);
    } else {
      setSuggestedAction(SuggestedAction.Decline);
    }
  }, [threshold, averageMrs]);

  useEffect(() => {
    if ((averageMrs && calculatedSrfAdjustment !== null) || useCalculatedSrfAdjustment === null) {
      const calculated = defaultMrsAdjustment(averageMrs);
      if (calculated !== calculatedSrfAdjustment || useCalculatedSrfAdjustment === null) {
        mutateFunction({
          variables: {
            input: {
              id,
              calculatedSrfAdjustment: calculated,
              useCalculatedSrfAdjustment:
                useCalculatedSrfAdjustment === null ? true : useCalculatedSrfAdjustment,
            },
          },
        });
      }
    }
    if (averageMrs && adjustment === null) {
      mutateFunction({
        variables: {
          input: {
            id,
            srfAdjustment: 0,
          },
        },
      });
    }
  }, [
    scheduledRatingFactor,
    useCalculatedSrfAdjustment,
    calculatedSrfAdjustment,
    providers,
    id,
    mutateFunction,
    updateProviders,
    adjustment,
    averageMrs,
  ]);
  if (loading) return <Loader />;
  return (
    <div>
      {isOpenSendDocument && (
        <SendDocument
          previewQuoteDocument={previewQuoteDocument}
          brokerEmail={brokerEmail}
          subjectivities={subjectivities}
          quoteProperties={quoteProperties}
          isQIP={isQIP}
          onClose={onCloseSendDocument}
        />
      )}
      {isOpenDeclination && (
        <DeclinationModal
          brokerEmail={brokerEmail}
          submitToSunlight={submitToSunlight}
          declination={declination}
          onClose={onCloseDeclination}
        />
      )}
      <Subnav
        leftContent={
          <Flex direction="column">
            <Flex>
              <Link as={RRLink} to={`/insights/${id}`}>
                <Text lineHeight="none" fontWeight="600" color="indigo.500" fontSize="sm">
                  Basic Info
                  <ChevronRightIcon mt="-2px" />
                </Text>
              </Link>
            </Flex>
            <Text lineHeight="none" fontSize="lg">
              Model Insights
            </Text>
          </Flex>
        }
        rightContent={
          <Flex alignItems="center" gap="16px">
            <SubmissionEventDisplay events={events} status={status} />
            <Center height="21px">
              <Divider color="gray.300" orientation="vertical" />
            </Center>
            <ButtonGroup>
              <NotificationWrapper
                label="Pricing data may be incorrect"
                showNotification={hasStalePricing}
              >
                <Button
                  isDisabled={isDisabled}
                  onClick={fetchPricingData}
                  mx="4px"
                  variant="outline"
                  colorScheme="indigo"
                  borderRadius={radii.base}
                >
                  <PriceCheckIcon isAnimating={isFetchingPricingData} />
                </Button>
              </NotificationWrapper>
              <StatusMenu />
              {!isDisabled ? (
                <CompleteReviewButton
                  isDisabled={isDisabled}
                  isLoading={isSubmitting}
                  onClickSubmit={submitToSunlight}
                  onClickDecline={onOpenDeclination}
                />
              ) : (
                !documentsAreSent &&
                !quoteResults?.isDeclined && (
                  <SendDocumentMenu
                    isLoading={isDisabled && !quoteProperties?.simplifyQuoteId}
                    onClickQIP={() => {
                      setIsQIP(true);
                      onOpenSendDocument();
                    }}
                    onClickQuote={() => {
                      setIsQIP(false);
                      onOpenSendDocument();
                    }}
                  />
                )
              )}
            </ButtonGroup>
          </Flex>
        }
      />
      {showDevil && <DevilBoy />}
      <Flex w="100%" direction="column" alignItems="center" pr="47px" pt="50px">
        <QuoteErrorCard sunlightErrors={sunlightErrors} />
        <Box
          m="30px 30px 30px 30px"
          w="100%"
          maxW="1040px"
          minW={{ lg: "930px", md: "700px", sm: "400px" }}
          pl="10px"
          pr="15px"
        >
          {quoteResults && <QuoteResultsCard quoteResults={quoteResults} />}
          <GroupCard
            averageAdjustedMrs={averageAdjustedMrs}
            srfAdjustment={adjustment}
            calculatedSrfAdjustment={calculatedSrfAdjustment}
            useCalculatedSrfAdjustment={useCalculatedSrfAdjustment}
            indigoPremium={indigoPremium || ""}
            targetPremium={targetPremium || ""}
            suggestedAction={suggestedAction}
            onSaveUseCalculatedSrfAdjustment={async (useCalculated: boolean) => {
              if (useCalculated !== useCalculatedSrfAdjustment) {
                await mutateFunction({
                  variables: {
                    input: {
                      id,
                      previewPremiumsAreStale: true,
                      srfAdjustment: calculatedSrfAdjustment,
                      useCalculatedSrfAdjustment: useCalculated,
                    },
                  },
                });
                await fetchAndSaveMrs();
              }
            }}
            onSaveAdjustment={async (adj: number) => {
              if (adj !== srfAdjustment || useCalculatedSrfAdjustment !== false) {
                await mutateFunction({
                  variables: {
                    input: {
                      id,
                      previewPremiumsAreStale: true,
                      srfAdjustment: adj,
                      useCalculatedSrfAdjustment: false,
                    },
                  },
                });
                await fetchAndSaveMrs();
              }
            }}
            name={entity?.name || "Provider Group"}
            averageMRS={averageMrs}
            bookedSRF={bookedSrf}
            providersCount={providers.length}
            county={entity?.address?.countyName || providers?.[0]?.address?.countyName || ""}
            customerId={entity?.simplifyCustomerId || ""}
          />
          {providers.map((provider, index) => {
            return (
              <ProviderCardWrapper
                refreshInsightsData={fetchAndSaveMrs}
                averageAdjustedMrs={averageAdjustedMrs}
                indigoPremium={
                  provider.previewPremium
                    ? formatAsDollar.format(provider.previewPremium)
                    : undefined
                }
                srfAdjustment={cProviders?.[index]?.srfAdjustment || 0}
                localAdjustedPercent={cProviders?.[index]?.localAdjustedPercent || 0}
                setSuggestedAction={setSuggestedAction}
                suggestedAction={suggestedAction}
                modelId={modelId}
                scheduledRatingFactor={scheduledRatingFactor}
                key={index}
                index={index}
                lengthOfSet={providers.length}
                bookedSRF={bookedSrf}
                averageMRS={averageMrs}
                provider={provider}
                customerId={provider?.simplifyCustomerId || ""}
              />
            );
          })}
          {midlevels.map((midlevel, index) => {
            return (
              <MidlevelCard
                indigoPremium={
                  midlevel.previewPremium
                    ? formatAsDollar.format(midlevel.previewPremium)
                    : undefined
                }
                index={index}
                lengthOfSet={midlevels.length || 0}
                mrsThreshold={threshold}
                mrs={averageMrs}
                key={index}
                name={`${midlevel.firstName} ${midlevel.lastName}`}
                county={midlevel?.address?.countyName || ""}
                customerId={midlevel?.simplifyCustomerId || ""}
              />
            );
          })}
        </Box>
      </Flex>
    </div>
  );
}
