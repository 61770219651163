import { useAnimatedNumber } from "hooks";

interface RolodexProps {
  num?: number;
  delay?: number;
}

export function Rolodex({ num, delay = 10 }: RolodexProps) {
  const displayNumber = useAnimatedNumber(num || 0, delay);
  return num === undefined ? <div>Unknown</div> : <div>{displayNumber.toFixed(2)}</div>;
}
