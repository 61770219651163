import { z } from "zod";
import { AmountValueCyberAggregateSchema, AmountValueCyberOccurrenceSchema, EntityExternalIdentifierSchema, } from "../general";
export var EntityInsuredItemSchema = z.object({
    Class: z.literal("clsInsuredItemFleetGroup"),
    InsuredItemTypeId: z.literal("Group"),
    GroupInsuredItemExternalIdentifier: z.literal("GroupGoesHereHOSPITAL"),
    ListofRisks: z.tuple([
        z.object({
            InsuredItemId: EntityExternalIdentifierSchema,
        }),
    ]),
});
export var EntityCyberCoverageSchema = z.object({
    OfferedServiceId: z.literal("Admitted - Medical Professional Liability Fleet^CMD"),
    CoverageClaimType: z.literal("2"),
    ExcludeFromCommission: z.literal(false),
    PriorActsDate: z.string(),
    ListofLimitsDeductibles: z.tuple([
        z.object({
            OfferedDeductibleLimitId: z.literal("Admitted - Medical Professional Liability Fleet^CMD^Aggregate Limit By Policy Term"),
            LimitDeductibleType: z.literal("4"),
            LimitDeductibleCategory: z.literal("8"),
            AmountValue: AmountValueCyberAggregateSchema,
        }),
        z.object({
            OfferedDeductibleLimitId: z.literal("Admitted - Medical Professional Liability Fleet^CMD^Occurrence Limit"),
            AmountValue: AmountValueCyberOccurrenceSchema,
            LimitDeductibleCategory: z.literal("2"),
        }),
    ]),
    InsuredItemId: z.string().includes("GroupGoesHereHOSPITAL"),
});
