import { useState, useEffect } from "react";
import { Flex, Text, Spinner } from "@chakra-ui/react";
import { formatStatusType } from "utils";
import { EventType, Event, EventState, StatusType } from "__generated__/graphql";
import "./submission-event-display.css";

interface SubmissionEventDisplayProps {
  events?: Event[];
  status?: StatusType;
}

function formatDisplayMessage(events: Event[]): string {
  let message: string = "";
  const customerEvents = events.filter((e) => e.type === EventType.CreateSlCustomer);
  const inFlightCustomerEvents = customerEvents.filter(
    (e) => e.state === EventState.Processing || e.state === EventState.Queued,
  );
  const allInFlightEvents = events.filter(
    (e) => e.state === EventState.Processing || e.state === EventState.Queued,
  );

  // When only the CreateQuoteOrchestration event is present show "Initializing"
  if (events.length === 1 && events[0].type === EventType.CreateQuoteOrchestration) {
    return "Initializing";
  }

  // When the CreateSlQuote event is in progress show "Creating quote"
  if (
    inFlightCustomerEvents.length === 0 &&
    events.find((event) => event.type === EventType.CreateSlQuote) !== undefined
  ) {
    return "Creating quote";
  }

  // When the CreateSlCustomer events are complete but CreateSlQuote hasn't kicked off show "Creating quote"
  if (inFlightCustomerEvents.length === 0 && customerEvents.length > 0) {
    return "Creating quote";
  }

  // When the CreateSlCustomer events are in progress show "Creating customer x of y"
  if (inFlightCustomerEvents.length > 0) {
    const currentCustomerIndex = customerEvents.length - inFlightCustomerEvents.length + 1;
    return `Creating customer ${currentCustomerIndex} of ${customerEvents.length}`;
  }

  // When all events other than the CreateQuoteOrchestration event are complete show "Finalizing"
  if (
    events.length > 1 &&
    allInFlightEvents.length === 1 &&
    allInFlightEvents[0].type === EventType.CreateQuoteOrchestration
  ) {
    return "Finalizing";
  }

  return message;
}

export function SubmissionEventDisplay({ events, status }: SubmissionEventDisplayProps) {
  const [isLeaving, setIsLeaving] = useState(false);
  const [displayedStatus, setDisplayedStatus] = useState<StatusType | undefined>();
  const [displayedEvents, setDisplayedEvents] = useState(() => formatDisplayMessage(events || []));
  const amountOfEventsComplete =
    events?.filter(
      (event) => event.state === EventState.Complete || event.state === EventState.Failed,
    )?.length || 0;

  const displayMessage = formatDisplayMessage(events || []);

  useEffect(() => {
    if (status !== displayedStatus || formatDisplayMessage(events || []) !== displayedEvents) {
      setIsLeaving(true);
      setTimeout(() => {
        setIsLeaving(false);
        setDisplayedStatus(status);
        setDisplayedEvents(displayMessage);
      }, 150);
    }
  }, [status, events, displayedStatus, displayedEvents, displayMessage]);

  return (
    <Flex className={isLeaving ? "fade-out" : "fade-in"} alignItems="center" gap="12px" mr="4px">
      <Text fontSize="xs" color="gray.500" lineHeight="none" style={{ textTransform: "uppercase" }}>
        {events !== undefined && events?.length !== 0 && amountOfEventsComplete !== events?.length
          ? displayedEvents
          : formatStatusType(displayedStatus)}
      </Text>
      {events !== undefined &&
        events?.length !== 0 &&
        amountOfEventsComplete !== events?.length && <Spinner color="indigo.500" />}
    </Flex>
  );
}
