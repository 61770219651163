export * from "./specialties";
export * from "./suffixes";
export * from "./carriers";
export * from "./urls";
export * from "./us-states";
export * from "./state_license_urls";
export var DEFAULT_MRS_VALUE = 0.8;
export var DEFAULT_INCONSISTENT_LOCATION_MRS_VALUE = 1;
export var LARGE_SUBMISSION_THRESHOLD = 1000;
export var AVERAGE_MRS_QUOTE_THRESHOLD = 1.25;
export var AVERAGE_MRS_QUOTE_THRESHOLD_LARGE = 1.5;
export var DEFAULT_CC_EMAIL_ADDRESSES = "travis@getindigo.com, egomez@getindigo.com, kgrassmuck@getindigo.com, saustin@getindigo.com, alexandra@getindigo.com, mnevins@getindigo.com";
export var DEFAULT_BCC_EMAIL_ADDRESSES = "quotes@getindigo.com";
