import { InputGroup, InputRightElement, InputProps, Input } from "@chakra-ui/react";

export function PercentInput(props: InputProps) {
  return (
    <InputGroup>
      <InputRightElement mt="5px" pointerEvents="none">
        %
      </InputRightElement>
      <Input {...props} pl="20px" />
    </InputGroup>
  );
}
