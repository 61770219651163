import { Outlet, Navigate } from "react-router";
import { useAuthenticator } from "@aws-amplify/ui-react";
import { useLocalAuthSession } from "hooks";
import { UserGroups } from "localTypes";

export function GroupRouter() {
  const currentUser = useLocalAuthSession();
  const isAdmin = !!currentUser?.userGroups?.includes(UserGroups.Underwriters);
  const isAllowed = !!currentUser?.userGroups?.includes(UserGroups.Brokers) || isAdmin;
  const { signOut } = useAuthenticator((context) => [context.user]);
  if (!isAdmin && !isAllowed) {
    signOut();
  }

  if (isAdmin) {
    return <Outlet />;
  } else if (isAllowed) {
    return <Navigate to="/broker" />;
  } else {
    return <Navigate to="/" />;
  }
}
