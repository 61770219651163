import { Stat, StatLabel, StatNumber, StatHelpText } from "@chakra-ui/react";
import { ReactNode } from "react";

interface DatumProps {
  label: string | ReactNode;
  value: string | ReactNode;
  helpText?: string | ReactNode;
}
export function Datum({ label, value, helpText }: DatumProps) {
  return (
    <Stat>
      <StatLabel
        style={{ whiteSpace: "nowrap" }}
        color="gray.450"
        fontWeight="normal"
        lineHeight="none"
        fontSize="sm"
      >
        {label}
      </StatLabel>
      <StatNumber fontSize="sm" fontWeight="bold" lineHeight="none">
        {Number.isNaN(value) ? "Unknown" : value}
      </StatNumber>
      <StatHelpText>{helpText}</StatHelpText>
    </Stat>
  );
}
