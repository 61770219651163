export var PROVIDER_SUFFIXES = {
    "1927": "DDS MD",
    "1931": "DO",
    "1936": "II DO",
    "1937": "II MD",
    "1938": "III DO",
    "1939": "III MD",
    "1940": "IV DO",
    "1941": "IV MD",
    "1942": "JR",
    "1946": "JR DO",
    "1947": "JR MD",
    "1960": "MD",
    "1961": "MD PhD",
    "1975": "PHD",
};
export var MIDLEVEL_SUFFIXES = {
    "1905": "APRN",
    "1913": "CNM",
    "1915": "CNP",
    "1922": "CRNA",
    "1925": "DC",
    "1926": "DDS",
    "1928": "DMD",
    "1930": "DNP",
    "1932": "DPM",
    "1933": "DPT",
    "1934": "FNP",
    "1942": "JR",
    "1944": "JR CRNA",
    "1945": "JR DDS",
    "1948": "JR PA-C",
    "1951": "LICSW",
    "1962": "MPT",
    "1969": "NP",
    "1970": "OD",
    "1971": "OT",
    "1972": "PA",
    "1973": "PA-C",
    "1975": "PHD",
    "1977": "PSYD",
    "1978": "PT",
    "1980": "PharmD",
    "1982": "RN",
};
