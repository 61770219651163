import { memo } from "react";
import { Center, Flex, Text } from "@chakra-ui/react";

import { Loader } from "components";
import { RiskState } from "./RiskState";
import { RiskLevel } from "@app-stack/types/risk_color";

interface RiskSummaryProps {
  riskBucket?: RiskLevel;
  isLoading?: boolean;
}

export const RiskSummary = memo(function RiskSummary({ riskBucket, isLoading }: RiskSummaryProps) {
  return (
    <Flex py="37px" alignItems="center" gap="14px">
      {!isLoading ? (
        <RiskState riskBucket={riskBucket || RiskLevel.UNKNOWN} />
      ) : (
        <Center w="100%">
          <Flex direction="column" justifyContent="center">
            <Loader />
            <Text>Assessing risk...</Text>
          </Flex>
        </Center>
      )}
    </Flex>
  );
});
