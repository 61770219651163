import { ReactNode } from "react";
import { Tooltip } from "@chakra-ui/react";
import "./notification-wrapper.css";

interface NotificationWrapperProps {
  children: ReactNode;
  showNotification: boolean;
  label?: string;
}
export function NotificationWrapper({
  label,
  children,
  showNotification,
}: NotificationWrapperProps) {
  return (
    <Tooltip
      placement="top"
      hasArrow={true}
      aria-label={label}
      label={showNotification ? label : ""}
    >
      <div data-testid="notification-wrapper" className="notification-wrapper">
        {showNotification && <div className="notification-dot" data-testid="notification-dot" />}
        {children}
      </div>
    </Tooltip>
  );
}
