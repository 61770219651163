import { z } from "zod";
import { IndividualExternalIdentifierSchema } from "../general";
/**
 * Provider insured item for individual provider quotes
 */
export var ProviderInsuredItemSchema = z.object({
    Class: z.literal("clsInsuredItemFleetGroup"),
    InsuredItemTypeId: z.literal("Group Individual Provider"),
    GroupInsuredItemExternalIdentifier: z.string().includes("GroupGoesHereINDIVIDUAL:"),
    ListofRisks: z.tuple([
        z.object({
            InsuredItemId: IndividualExternalIdentifierSchema,
        }),
    ]),
});
