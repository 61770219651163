import {
  Button,
  SimpleGrid,
  Flex,
  Input,
  FormErrorMessage,
  FormControl,
  FormLabel,
  Select,
  Card,
  CardHeader,
  CardBody,
  Collapse,
  useDisclosure,
  Text,
} from "@chakra-ui/react";
import { ChevronDownIcon, ChevronUpIcon } from "@chakra-ui/icons";
import { DatePicker, Datum } from "components";
import { CARRIERS } from "app-constants";
import { Control, UseFormSetValue, Controller, UseFormRegister } from "react-hook-form";
import { SubmissionUpdateInput, ProducerUpdateInput } from "__generated__/graphql";

interface QuoteReviewCardProps {
  producer: ProducerUpdateInput;
  register: UseFormRegister<SubmissionUpdateInput>;
  onChangeSelect: (e: React.ChangeEvent<HTMLSelectElement>) => void;
  control: Control<SubmissionUpdateInput>;
  setValue: UseFormSetValue<SubmissionUpdateInput>;
}
export function QuoteReviewCard({
  register,
  producer,
  onChangeSelect,
  control,
  setValue,
}: QuoteReviewCardProps) {
  const { isOpen, onToggle } = useDisclosure({ defaultIsOpen: true });

  return (
    <Card mb="12px">
      <CardHeader
        py="40px"
        aria-label="expand producer review card"
        variant="ghost"
        _hover={{ background: "transparent" }}
        textAlign="left"
        as={Button}
        onClick={onToggle}
      >
        <Flex w="100%" justifyContent="space-between">
          <Flex direction="column" mb="8px">
            <Text lineHeight="none" fontSize="3xl">
              Quote Summary
            </Text>
          </Flex>
          {!isOpen ? <ChevronDownIcon /> : <ChevronUpIcon />}
        </Flex>
      </CardHeader>
      <Collapse in={isOpen} animateOpacity>
        <CardBody>
          <Flex
            bgColor="indigo.100"
            gap="24px"
            padding="16px 8px"
            border="1px solid"
            borderColor="gray.200"
          >
            <Datum label="Agency" value={producer?.agency?.name} />
            <Datum label="Producer" value={producer?.name} />
            <Datum label="Account Manager" value={producer?.manager} />
            <Datum label="" value="" />
          </Flex>
          <SimpleGrid columns={4} gap="24px" p="8px" border="1px solid" borderColor="gray.200">
            <FormControl>
              <FormLabel
                htmlFor={`quoteProperties.contactEmail`}
                fontSize="md"
                mb="0px"
                fontWeight="normal"
              >
                Contact Email
              </FormLabel>
              <Input {...register(`quoteProperties.contactEmail`)} />
            </FormControl>
            <FormControl flexBasis="33%">
              <FormLabel
                htmlFor={`quoteProperties.carrier`}
                fontSize="md"
                mb="0px"
                fontWeight="normal"
              >
                Current Carrier
              </FormLabel>
              <Select
                id={`quoteProperties.currentCarrier`}
                size="md"
                m="0px"
                p="0px"
                {...register(`quoteProperties.currentCarrier`)}
                onChange={onChangeSelect}
              >
                {CARRIERS.map(({ name, id }) => {
                  return (
                    <option key={id} value={name}>
                      {name}
                    </option>
                  );
                })}
              </Select>
            </FormControl>
            <Controller
              rules={{ required: "Effective Date is required" }}
              render={({ fieldState, field }) => (
                <FormControl flexBasis="33%" isInvalid={Boolean(fieldState.error)}>
                  <FormLabel
                    htmlFor={`quoteProperties.effectiveDate`}
                    fontSize="md"
                    mb="0px"
                    fontWeight="normal"
                  >
                    Effective Date *
                  </FormLabel>
                  <DatePicker
                    invalid={Boolean(fieldState.error)}
                    selected={field?.value || ""}
                    onChange={(date: string) => {
                      setValue?.(`quoteProperties.effectiveDate`, date);
                    }}
                  />
                  <FormErrorMessage>
                    {fieldState.error && fieldState.error.message}
                  </FormErrorMessage>
                </FormControl>
              )}
              control={control}
              name={`quoteProperties.effectiveDate`}
            />
            <FormControl flexBasis="33%">
              <FormLabel
                htmlFor={`quoteProperties.expiringPremium`}
                fontSize="md"
                mb="0px"
                fontWeight="normal"
              >
                Expiring Premium
              </FormLabel>
              <Input
                type="number"
                {...register(`quoteProperties.expiringPremium`, { valueAsNumber: true })}
              />
            </FormControl>
          </SimpleGrid>
          <SimpleGrid
            columns={4}
            gap="24px"
            p="8px"
            border="1px solid"
            borderColor="gray.200"
            borderTop="0"
          >
            <FormControl flexBasis="33%">
              <FormLabel
                htmlFor={`quoteProperties.targetPremium`}
                fontSize="md"
                mb="0px"
                fontWeight="normal"
              >
                Target Premium
              </FormLabel>
              <Input
                type="number"
                {...register(`quoteProperties.targetPremium`, { valueAsNumber: true })}
              />
            </FormControl>
            <FormControl flexBasis="33%">
              <FormLabel
                htmlFor={`quoteProperties.renewalPremium`}
                fontSize="md"
                mb="0px"
                fontWeight="normal"
              >
                Renewal Premium
              </FormLabel>
              <Input
                type="number"
                {...register(`quoteProperties.renewalPremium`, { valueAsNumber: true })}
              />
            </FormControl>
          </SimpleGrid>
        </CardBody>
      </Collapse>
    </Card>
  );
}
