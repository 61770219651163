import { ChangeEvent, useCallback, useEffect, useState } from "react";
import { Flex, Box, Link, Button, ButtonGroup, Text, Center, Divider } from "@chakra-ui/react";
import { ChevronRightIcon } from "@chakra-ui/icons";
import { useParams, Link as RRLink, useNavigate } from "react-router-dom";
import {
  EntityReviewCard,
  Subnav,
  ProviderReviewCard,
  MidlevelReviewCard,
  QuoteReviewCard,
  QuoteResultsCard,
  QuoteResults,
  StatusMenu,
  FormError,
  SubmissionEventDisplay,
  QuoteErrorCard,
  Loader,
} from "components";
import { useForm } from "react-hook-form";
import {
  MidlevelUpdateInput,
  ProviderUpdateInput,
  SubmissionUpdateInput,
  ProducerUpdateInput,
  QuotePropertiesUpdateInput,
  EntityUpdateInput,
  StatusType,
  Limit,
  Deductible,
  LimitType,
  Event,
} from "__generated__/graphql";
import { useMutation } from "@apollo/client";
import {
  UPDATE_PROVIDERS,
  UPDATE_MIDLEVELS,
  UPDATE_ENTITIES,
  UPDATE_QUOTE_PROPERTIES,
} from "mutations";
import { LARGE_SUBMISSION_THRESHOLD } from "app-constants";

interface ReviewProps {
  quoteResults?: QuoteResults;
  providers: ProviderUpdateInput[];
  midlevels: MidlevelUpdateInput[];
  entities: EntityUpdateInput[];
  producer?: ProducerUpdateInput;
  quoteProperties?: QuotePropertiesUpdateInput;
  status?: StatusType;
  submitToSunlight: () => void;
  isSubmitting: boolean;
  isDisabled: boolean;
  events?: Event[];
  sunlightErrors?: string[];
  loading?: boolean;
}

export function Review({
  loading,
  quoteResults,
  sunlightErrors,
  entities,
  quoteProperties,
  producer,
  providers,
  midlevels,
  status,
  submitToSunlight,
  isSubmitting,
  isDisabled,
  events,
}: ReviewProps) {
  const { id } = useParams();
  const navigate = useNavigate();
  const [isSaving, setIsSaving] = useState(false);
  const [updateMidlevels] = useMutation(UPDATE_MIDLEVELS);
  const [updateEntities] = useMutation(UPDATE_ENTITIES);
  const [updateProviders] = useMutation(UPDATE_PROVIDERS);
  const [updateQuoteProperties] = useMutation(UPDATE_QUOTE_PROPERTIES, {
    refetchQueries: ["GetSubmission"],
    awaitRefetchQueries: true,
  });
  const defaultMidlevels = midlevels?.map((midlevel) => ({
    ...midlevel,
    limit: midlevel.limit || Limit["1M_3M"],
    limitType: midlevel.limitType || LimitType.Separate,
  }));
  const defaultEntities = entities?.map((entity) => ({
    ...entity,
    limit: entity.limit || Limit["1M_3M"],
    entityCharges: entity.entityCharges || LimitType.Separate,
  }));
  const defaultProviders = providers?.map((provider) => ({
    ...provider,
    limit: provider.limit || Limit["1M_3M"],
    limitType: provider.limitType || LimitType.Separate,
    deductible: provider.deductible || Deductible.None,
  }));
  const {
    register,
    handleSubmit,
    getValues,
    setValue,
    control,
    formState: { errors },
  } = useForm<SubmissionUpdateInput>({
    defaultValues: {
      id,
      providers: defaultProviders,
      midlevels: defaultMidlevels,
      quoteProperties,
      entities: defaultEntities,
    },
  });

  const onSubmit = useCallback(
    async (submission: SubmissionUpdateInput) => {
      await updateProviders({
        variables: {
          submissionId: id,
          providers: submission.providers,
        },
      });
      await updateEntities({
        variables: {
          submissionId: id,
          entities: submission.entities,
        },
      });
      await updateMidlevels({
        variables: {
          submissionId: id,
          midlevels: submission.midlevels,
        },
      });
      await updateQuoteProperties({
        variables: {
          submissionId: id,
          quoteProperties: submission.quoteProperties,
        },
      });
    },
    [updateQuoteProperties, updateProviders, updateEntities, updateMidlevels, id],
  );

  const handleBlur = useCallback(() => {
    const data = getValues();
    onSubmit(data);
  }, [getValues, onSubmit]);

  useEffect(() => {
    const inputs = document.querySelectorAll("input");
    inputs.forEach((input) => {
      input.addEventListener("blur", handleBlur);
    });
    return () => {
      inputs.forEach((input) => {
        input.removeEventListener("blur", handleBlur);
      });
    };
  }, [handleBlur]);

  const handleSelectChange = useCallback(
    (event: ChangeEvent<HTMLSelectElement>) => {
      const { name, value } = event.target;
      setValue(name as keyof SubmissionUpdateInput, value);
      onSubmit(getValues());
    },
    [setValue, getValues, onSubmit],
  );

  const hasLotsOfThings =
    (providers?.length || 0) + (midlevels?.length || 0) > LARGE_SUBMISSION_THRESHOLD;

  if (loading) return <Loader />;
  return (
    <div style={{ height: "100%", paddingBottom: "30px" }}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Subnav
          leftContent={
            <Flex direction="column">
              <Flex>
                <Link as={RRLink} to={`/insights/${id}`}>
                  <Text lineHeight="none" fontWeight="600" color="indigo.500" fontSize="sm">
                    Basic Info
                    <ChevronRightIcon mt="-2px" />
                  </Text>
                </Link>
                {hasLotsOfThings && (
                  <Link as={RRLink} to={`/insights/${id}/model-insights`}>
                    <Text lineHeight="none" fontWeight="600" color="indigo.500" fontSize="sm">
                      Model Insights
                      <ChevronRightIcon mt="-2px" />
                    </Text>
                  </Link>
                )}
              </Flex>
              <Text lineHeight="none" fontSize="lg">
                Roster Review
              </Text>
            </Flex>
          }
          rightContent={
            <Flex alignItems="center" gap="16px">
              <SubmissionEventDisplay events={events} status={status} />
              <Center height="21px">
                <Divider color="gray.300" orientation="vertical" />
              </Center>
              <ButtonGroup gap="8px">
                <StatusMenu />
                {!hasLotsOfThings ? (
                  <Button
                    isLoading={isSaving}
                    onClick={handleSubmit(async () => {
                      try {
                        setIsSaving(true);
                        await onSubmit(getValues());
                        if (Object.keys(errors).length === 0) {
                          navigate(`/insights/${id}/model-insights`);
                        }
                      } catch (e) {
                        console.error(e);
                      } finally {
                        setIsSaving(false);
                      }
                    })}
                    colorScheme="indigo"
                  >
                    Continue
                  </Button>
                ) : (
                  <Button
                    isDisabled={isDisabled}
                    isLoading={isSubmitting}
                    loadingText="Submitting..."
                    onClick={handleSubmit(async () => {
                      try {
                        await onSubmit(getValues());
                        submitToSunlight();
                      } catch (e) {
                        console.error(e);
                      }
                    })}
                    mx="4px"
                    w="160px"
                    colorScheme="indigo"
                  >
                    {isDisabled ? "Submitted" : "Submit to Sunlight"}
                  </Button>
                )}
              </ButtonGroup>
            </Flex>
          }
        />
        <Flex w="100%" direction="column" alignItems="center" pr="47px" pt="50px">
          <QuoteErrorCard sunlightErrors={sunlightErrors || []} />
          <Box w="1040px" m="30px 30px 30px 30px" pl="10px" pr="15px">
            {quoteResults && <QuoteResultsCard quoteResults={quoteResults} />}
            <Box m="30px 10px">
              {Object.keys(errors)?.length > 0 && (
                <FormError message={"Please correct the following form errors"} />
              )}
            </Box>
            {producer && (
              <QuoteReviewCard
                setValue={setValue}
                control={control}
                register={register}
                onChangeSelect={handleSelectChange}
                producer={producer}
              />
            )}
            {entities?.map((entity, index) => {
              return index === 0 ? (
                <EntityReviewCard
                  setValue={setValue}
                  control={control}
                  key={index}
                  onChangeSelect={handleSelectChange}
                  register={register}
                  errors={errors}
                  entity={entity}
                  index={index}
                />
              ) : null;
            })}
            {providers?.map((provider, index) => (
              <ProviderReviewCard
                setValue={setValue}
                control={control}
                key={index}
                onChangeSelect={handleSelectChange}
                register={register}
                errors={errors}
                provider={provider}
                index={index}
                lengthOfSet={providers.length}
              />
            ))}
            {midlevels?.map((midlevel, index) => (
              <MidlevelReviewCard
                setValue={setValue}
                control={control}
                key={index}
                onChangeSelect={handleSelectChange}
                register={register}
                errors={errors}
                midlevel={midlevel}
                index={index}
                lengthOfSet={midlevels.length}
              />
            ))}
          </Box>
        </Flex>
      </form>
    </div>
  );
}
