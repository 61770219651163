import validator from "validator";
import { z } from "zod";
import { AmountValueCyberAggregateSchema, AmountValueCyberOccurrenceSchema, AmountValueSchema, DeductibleStructureFactorSchema, SharedOrSeparateLimitsSchema, } from "../general";
/**
 * Schema for provider coverage on an individual provider quote.
 */
export var ProviderCoverageSchema = z.object({
    OfferedServiceId: z.literal("Admitted - Medical Professional Liability Fleet^TPLPI"),
    ExcludeFromCommission: z.literal(false),
    CoverageClaimType: z.literal("2"),
    PriorActsDate: z.string(),
    ListofLimitsDeductibles: z.tuple([
        z.object({
            OfferedDeductibleLimitId: z.literal("Admitted - Medical Professional Liability Fleet^TPLPI^Aggregate Limit By Policy Term"),
            LimitDeductibleType: z.literal("4"),
            LimitDeductibleCategory: z.literal("8"),
            "PolicyCoverage-Filed": z.string().refine(function (val) { return validator.isIn(val, ["1901", "1903"]); }),
            AmountValue: AmountValueSchema,
        }),
        z.object({
            OfferedDeductibleLimitId: z.literal("Admitted - Medical Professional Liability Fleet^TPLPI^Occurrence Limit"),
            AmountValue: AmountValueSchema,
            LimitDeductibleType: z.literal("2"),
            LimitDeductibleCategory: z.literal("2"),
            "PolicyCoverage-Filed": z.string().refine(function (val) { return validator.isIn(val, ["1901", "1903"]); }),
        }),
    ]),
    InsuredItemId: z.string().includes("GroupGoesHereINDIVIDUAL:"),
    ServiceExternalIdentifier: z.string().includes("CoverageExternalIdentifierINDIVIDUAL:"),
    MPLIndividualProviderSharedOrSeparateLimits: z
        .string()
        .refine(function (val) { return validator.isIn(val, ["3101", "3102"]); }),
    MPLIndividualProviderDeductibleStructureFactor: z
        .string()
        .optional()
        .refine(function (val) { return val === undefined || validator.isIn(val, ["3101"]); }),
});
/**
 * Schema for provider cyber coverage on an individual provider quote.
 */
export var ProviderCyberCoverageSchema = z.object({
    OfferedServiceId: z.literal("Admitted - Medical Professional Liability Fleet^CMD"),
    CoverageClaimType: z.literal("2"),
    ExcludeFromCommission: z.literal(false),
    PriorActsDate: z.string(),
    ListofLimitsDeductibles: z.tuple([
        z.object({
            OfferedDeductibleLimitId: z.literal("Admitted - Medical Professional Liability Fleet^CMD^Aggregate Limit By Policy Term"),
            LimitDeductibleType: z.literal("4"),
            LimitDeductibleCategory: z.literal("8"),
            AmountValue: AmountValueCyberAggregateSchema,
        }),
        z.object({
            OfferedDeductibleLimitId: z.literal("Admitted - Medical Professional Liability Fleet^CMD^Occurrence Limit"),
            AmountValue: AmountValueCyberOccurrenceSchema,
            LimitDeductibleCategory: z.literal("2"),
        }),
    ]),
    InsuredItemId: z.string().includes("GroupGoesHereINDIVIDUAL:"),
});
/**
 * Schema for provider south carolina medical malpractice coverage on an individual provider quote.
 */
export var ProviderSCMMACoverageSchema = z.object({
    OfferedServiceId: z.literal("Admitted - Medical Professional Liability Fleet^SCMMAI"),
    CoverageClaimType: z.literal("3"),
    ExcludeFromCommission: z.literal(false),
    PriorActsDate: z.string(),
    ListofLimitsDeductibles: z.tuple([
        z.object({
            OfferedDeductibleLimitId: z.literal("Admitted - Medical Professional Liability Fleet^SCMMAI^Aggregate Limit By Policy Term"),
            LimitDeductibleType: z.literal("4"),
            LimitDeductibleCategory: z.literal("8"),
            AmountValue: AmountValueSchema,
        }),
        z.object({
            OfferedDeductibleLimitId: z.literal("Admitted - Medical Professional Liability Fleet^SCMMAI^Occurrence Limit"),
            AmountValue: AmountValueSchema,
            LimitDeductibleCategory: z.literal("2"),
        }),
    ]),
    InsuredItemId: z.string().includes("GroupGoesHereINDIVIDUAL:"),
});
/**
 * Schema for provider pennsylvania medical malpractice coverage on an individual provider quote.
 */
export var ProviderPCFPAICoverageSchema = z.object({
    OfferedServiceId: z.literal("Admitted - Medical Professional Liability Fleet^PCFPAI"),
    CoverageClaimType: z.literal("2"),
    ExcludeFromCommission: z.literal(true),
    PCFPriorActsDates: z.string(),
    ListofLimitsDeductibles: z.tuple([
        z.object({
            OfferedDeductibleLimitId: z.literal("Admitted - Medical Professional Liability Fleet^PCFPAI^Aggregate Limit By Policy Term"),
            LimitDeductibleType: z.literal("4"),
            LimitDeductibleCategory: z.literal("8"),
            AmountValue: AmountValueSchema,
        }),
        z.object({
            OfferedDeductibleLimitId: z.literal("Admitted - Medical Professional Liability Fleet^PCFPAI^Occurrence Limit"),
            AmountValue: AmountValueSchema,
            LimitDeductibleCategory: z.literal("2"),
        }),
    ]),
    InsuredItemId: z.string().includes("GroupGoesHereINDIVIDUAL:"),
});
/**
 * Schema for provider coverage when attached to an entity quote.
 */
export var EntityProviderCoverageSchema = z.object({
    OfferedServiceId: z.literal("Admitted - Medical Professional Liability Fleet^TPLPI"),
    CoverageClaimType: z.literal("2"),
    InsuredItemId: z.string().includes("GroupGoesHereINDIVIDUAL:"),
    ServiceExternalIdentifier: z.string().includes("CoverageExternalIdentifierINDIVIDUAL:"),
    ListofLimitsDeductibles: z.tuple([
        z.object({
            OfferedDeductibleLimitId: z.literal("Admitted - Medical Professional Liability Fleet^TPLPI^Aggregate Limit By Policy Term"),
            LimitDeductibleType: z.literal("4"),
            LimitDeductibleCategory: z.literal("8"),
            "PolicyCoverage-Filed": z.literal("1903"),
            AmountValue: AmountValueSchema,
        }),
        z.object({
            OfferedDeductibleLimitId: z.literal("Admitted - Medical Professional Liability Fleet^TPLPI^Occurrence Limit"),
            AmountValue: AmountValueSchema,
            LimitDeductibleType: z.literal("2"),
            LimitDeductibleCategory: z.literal("2"),
            "PolicyCoverage-Filed": z.literal("1903"),
        }),
    ]),
    MPLIndividualProviderSharedOrSeparateLimits: SharedOrSeparateLimitsSchema,
    MPLIndividualProviderDeductibleStructureFactor: DeductibleStructureFactorSchema,
});
/**
 * Schema for provider cyber coverage when attached to an entity quote.
 */
export var EntityProviderCyberCoverageSchema = z.object({
    OfferedServiceId: z.literal("Admitted - Medical Professional Liability Fleet^CMD"),
    CoverageClaimType: z.literal("2"),
    PriorActsDate: z.string(),
    InsuredItemId: z.string().includes("GroupGoesHereINDIVIDUAL:"),
    ListofLimitsDeductibles: z.tuple([
        z.object({
            OfferedDeductibleLimitId: z.literal("Admitted - Medical Professional Liability Fleet^CMD^Aggregate Limit By Policy Term"),
            LimitDeductibleType: z.literal("4"),
            LimitDeductibleCategory: z.literal("8"),
            AmountValue: AmountValueCyberAggregateSchema,
        }),
        z.object({
            OfferedDeductibleLimitId: z.literal("Admitted - Medical Professional Liability Fleet^CMD^Occurrence Limit"),
            AmountValue: AmountValueCyberOccurrenceSchema,
            LimitDeductibleCategory: z.literal("2"),
            LimitDeductibleType: z.literal("2"),
            "PolicyCoverage-Filed": z.literal("1903"),
        }),
    ]),
});
/**
 * Schema for provider south carolina medical malpractice coverage when attached to an entity quote.
 */
export var EntityProviderSCMMACoverageSchema = z.object({
    OfferedServiceId: z.literal("Admitted - Medical Professional Liability Fleet^SCMMAI"),
    CoverageClaimType: z.literal("3"),
    PriorActsDate: z.string(),
    InsuredItemId: z.string().includes("GroupGoesHereINDIVIDUAL:"),
    ListofLimitsDeductibles: z.tuple([
        z.object({
            OfferedDeductibleLimitId: z.literal("Admitted - Medical Professional Liability Fleet^SCMMAI^Aggregate Limit By Policy Term"),
            LimitDeductibleType: z.literal("4"),
            LimitDeductibleCategory: z.literal("8"),
            AmountValue: AmountValueSchema,
        }),
        z.object({
            OfferedDeductibleLimitId: z.literal("Admitted - Medical Professional Liability Fleet^SCMMAI^Occurrence Limit"),
            AmountValue: AmountValueSchema,
            LimitDeductibleCategory: z.literal("2"),
            LimitDeductibleType: z.literal("2"),
            "PolicyCoverage-Filed": z.literal("1903"),
        }),
    ]),
});
/**
 * Schema for provider pennsylvania medical malpractice coverage when attached to an entity quote.
 */
export var EntityProviderPCFPAICoverageSchema = z.object({
    OfferedServiceId: z.literal("Admitted - Medical Professional Liability Fleet^PCFPAI"),
    CoverageClaimType: z.literal("2"),
    ExcludeFromCommission: z.literal(true),
    PCFPriorActsDates: z.string(),
    InsuredItemId: z.string().includes("GroupGoesHereINDIVIDUAL:"),
    ListofLimitsDeductibles: z.tuple([
        z.object({
            OfferedDeductibleLimitId: z.literal("Admitted - Medical Professional Liability Fleet^PCFPAI^Aggregate Limit By Policy Term"),
            LimitDeductibleType: z.literal("4"),
            LimitDeductibleCategory: z.literal("8"),
            AmountValue: AmountValueSchema,
        }),
        z.object({
            OfferedDeductibleLimitId: z.literal("Admitted - Medical Professional Liability Fleet^PCFPAI^Occurrence Limit"),
            AmountValue: AmountValueSchema,
            LimitDeductibleCategory: z.literal("2"),
            LimitDeductibleType: z.literal("2"),
            "PolicyCoverage-Filed": z.literal("1903"),
        }),
    ]),
});
