import { Flex } from "@chakra-ui/react";

interface FormErrorProps {
  message?: string;
}

export function FormError({ message }: FormErrorProps) {
  return message !== undefined ? (
    <div data-testid="formError">
      <Flex
        mb="12px"
        border="1px"
        borderColor="gray.200"
        w="100%"
        bgColor="red.100"
        alignItems="center"
        p="8px 24px 8px 24px"
        justifyContent="space-between"
        color="red.500"
        gap="8px"
        borderRadius="3px"
      >
        {" "}
        {message}
      </Flex>
    </div>
  ) : null;
}
