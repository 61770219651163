import validator from "validator";
import { z } from "zod";
import { IndividualExternalIdentifierSchema } from "../general";
/**
 * Provider premium factor on an individual provider quote.
 */
export var ProviderPremiumFactorSchema = z.object({
    Class: z.literal("clsPriceFactorWithMultipleFactorsForPolicyMPL"),
    TargetFactor: z.number(),
    OfferedPriceFactorVersionId: z.literal("Schedule Rating Indigo"),
    ListofApplicableInsuredItems: z.tuple([
        z.object({
            InsuredItemInterfaceId: IndividualExternalIdentifierSchema,
        }),
    ]),
    ListofSubscribedServices: z.tuple([
        z.object({
            SubscribedServiceId: z.string().includes("CoverageExternalIdentifierINDIVIDUAL:"),
        }),
    ]),
    PriceFactorDiscountOrSurcharge: z.string().refine(function (val) { return validator.isIn(val, ["1", "2"]); }),
});
export var BaseAmaProviderPremiumFactorSchema = z.object({
    Class: z.literal("clsPriceFactorWithMultipleFactorsForPolicyMPL"),
    OfferedPriceFactorVersionId: z.literal("Affiliated Medical Society Amount"),
    ListofApplicableInsuredItems: z.tuple([
        z.object({
            InsuredItemInterfaceId: IndividualExternalIdentifierSchema,
        }),
    ]),
    ListofSubscribedServices: z.tuple([
        z.object({
            SubscribedServiceId: z.string().includes("CoverageExternalIdentifierINDIVIDUAL:"),
        }),
    ]),
    PriceFactorDiscountOrSurcharge: z.literal(1),
});
/**
 * AMA provider premium factor on an individual provider quote.
 */
export var AmaProviderPremiumFactorSchema = BaseAmaProviderPremiumFactorSchema.merge(z.object({ TargetFactor: z.literal("400") }));
/**
 * AMA provider premium factor on an entity quote.
 */
export var EntityAmaProviderPremiumFactorSchema = BaseAmaProviderPremiumFactorSchema.merge(z.object({ PriceFactorPremium: z.literal("400") }));
