import { useEffect } from "react";
import {
  Button,
  SimpleGrid,
  Flex,
  FormErrorMessage,
  FormControl,
  FormLabel,
  Select,
  Card,
  CardHeader,
  CardBody,
  Collapse,
  useDisclosure,
  Text,
} from "@chakra-ui/react";
import { ChevronDownIcon, ChevronUpIcon } from "@chakra-ui/icons";
import { Datum, DatePicker } from "components";
import {
  Control,
  UseFormSetValue,
  Controller,
  FieldErrors,
  UseFormRegister,
} from "react-hook-form";
import { SubmissionUpdateInput, LimitType, Limit, EntityUpdateInput } from "__generated__/graphql";
import { addressToString, formatLimit } from "utils";

interface EntityReviewCardProps {
  index: number;
  entity: EntityUpdateInput;
  register: UseFormRegister<SubmissionUpdateInput>;
  errors?: FieldErrors<SubmissionUpdateInput>;
  setValue: UseFormSetValue<SubmissionUpdateInput>;
  onChangeSelect: (e: React.ChangeEvent<HTMLSelectElement>) => void;
  control: Control<SubmissionUpdateInput>;
}

export function EntityReviewCard({
  register,
  errors,
  index,
  entity,
  onChangeSelect,
  setValue,
  control,
}: EntityReviewCardProps) {
  const e = errors?.entities?.[index];
  const { onOpen, isOpen, onToggle } = useDisclosure();

  useEffect(() => {
    if (!isOpen && e !== undefined) {
      onOpen();
    }
  }, [errors, isOpen, onOpen, e]);

  return (
    <Card mb="12px">
      <CardHeader
        py="40px"
        textAlign="left"
        aria-label="expand provider review card"
        variant="ghost"
        _hover={{ background: "transparent" }}
        as={Button}
        onClick={onToggle}
      >
        <Flex w="100%" justifyContent="space-between">
          <Flex direction="column" mb="8px">
            <Text color="gray.450" fontWeight="normal" lineHeight="shorter">
              Medical Practice
            </Text>
            <Text lineHeight="none" fontSize="3xl">
              {`${entity.name}`}
            </Text>
          </Flex>
          {!isOpen ? <ChevronDownIcon /> : <ChevronUpIcon />}
        </Flex>
      </CardHeader>
      <Collapse in={isOpen} animateOpacity>
        <CardBody>
          <Flex
            bgColor="indigo.100"
            gap="24px"
            padding="16px 8px"
            border="1px solid"
            borderColor="gray.200"
          >
            <Datum label="Address" value={addressToString(entity?.address ?? {})} />
          </Flex>
          <SimpleGrid columns={4} gap="24px" p="8px" border="1px solid" borderColor="gray.200">
            <FormControl isInvalid={!!e?.limit?.message}>
              <FormLabel
                htmlFor={`entities.${index}.limit`}
                fontSize="md"
                mb="0px"
                fontWeight="normal"
              >
                Limit *
              </FormLabel>
              <Select
                id={`entities.${index}.limit`}
                size="md"
                m="0px"
                p="0px"
                {...register(`entities.${index}.limit`, {
                  required: "Limit is required",
                })}
                onChange={onChangeSelect}
              >
                {Object.values(Limit).map((limit) => {
                  return (
                    <option key={limit} value={limit}>
                      {formatLimit(limit)}
                    </option>
                  );
                })}
              </Select>
              <FormErrorMessage>{e?.limit?.message}</FormErrorMessage>
            </FormControl>
            <FormControl isInvalid={!!e?.entityCharges?.message}>
              <FormLabel
                htmlFor={`entities.${index}.limit`}
                fontSize="md"
                mb="0px"
                fontWeight="normal"
              >
                Medical Practice Charges *
              </FormLabel>
              <Select
                id={`entities.${index}.limit`}
                size="md"
                m="0px"
                p="0px"
                {...register(`entities.${index}.entityCharges`, {
                  required: "Entity charges selection is required",
                })}
                onChange={onChangeSelect}
              >
                {Object.values(LimitType).map((limitType) => {
                  return (
                    <option key={limitType} value={limitType}>
                      {limitType}
                    </option>
                  );
                })}
              </Select>
              <FormErrorMessage>{e?.limit?.message}</FormErrorMessage>
            </FormControl>
            <Controller
              rules={{ required: "Retro date is required" }}
              render={({ fieldState, field }) => (
                <FormControl isInvalid={Boolean(fieldState.error)}>
                  <FormLabel
                    htmlFor={`entities.${index}.retroDate`}
                    fontSize="md"
                    mb="0px"
                    fontWeight="normal"
                  >
                    Retro Date *
                  </FormLabel>
                  <DatePicker
                    id={`entities.${index}.retroDate`}
                    invalid={Boolean(fieldState.error)}
                    selected={field?.value || ""}
                    onChange={(date: string) => {
                      setValue?.(`entities.${index}.retroDate`, date);
                    }}
                  />
                  <FormErrorMessage>{e?.retroDate?.message}</FormErrorMessage>
                </FormControl>
              )}
              control={control}
              name={`entities.${index}.retroDate`}
            />
          </SimpleGrid>
        </CardBody>
      </Collapse>
    </Card>
  );
}
