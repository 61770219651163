import { ChangeEvent, useEffect, useState } from "react";
import {
  FieldErrors,
  UseFormRegister,
  Controller,
  Control,
  UseFormSetValue,
  UseFormClearErrors,
} from "react-hook-form";
import {
  Grid,
  GridItem,
  Checkbox,
  Divider,
  Flex,
  FormErrorMessage,
  FormLabel,
  FormControl,
  IconButton,
  Input,
  Select,
} from "@chakra-ui/react";
import { WarningIcon, CloseIcon } from "@chakra-ui/icons";

import { STATES, INDIGO_SPECIALTIES } from "app-constants";
import { InputMaybe, AddressInput, SubmissionUpdateInput } from "__generated__/graphql";
import { addressToString } from "utils";

import { AddressOption, AddressInputField } from "components";

interface InfoFormProps {
  onChangeSelect?: (e: ChangeEvent<HTMLSelectElement>) => void;
  address?: InputMaybe<AddressInput>;
  index: number;
  control: Control<SubmissionUpdateInput>;
  register: UseFormRegister<SubmissionUpdateInput>;
  setValue: UseFormSetValue<SubmissionUpdateInput>;
  errors?: FieldErrors<SubmissionUpdateInput>;
  removeProvider: () => void;
  clearErrors: UseFormClearErrors<SubmissionUpdateInput>;
}

export function InfoForm({
  register,
  setValue,
  address,
  index,
  errors,
  control,
  removeProvider,
  onChangeSelect,
  clearErrors,
}: InfoFormProps) {
  const e = errors?.providers?.[index];
  const [selectedAddress, setSelectedAddress] = useState<AddressOption | null>(() => {
    if (address) {
      return {
        label: addressToString(address),
        value: address,
      };
    }
    return null;
  });

  useEffect(() => {
    if (selectedAddress === null && address) {
      setSelectedAddress({
        label: addressToString(address),
        value: address,
      });
    }
  }, [selectedAddress, address, setSelectedAddress]);

  return (
    <>
      {index !== 0 && <Divider my="50px" />}
      {index !== 0 && (
        <Flex justifyContent="flex-end">
          <IconButton
            onClick={removeProvider}
            size="xs"
            variant="ghost"
            colorScheme="indigo"
            aria-label="Remove provider"
            icon={<CloseIcon />}
          />
        </Flex>
      )}
      <Flex gap="12px">
        <FormControl isInvalid={!!e?.firstName?.message}>
          <FormLabel
            htmlFor={`providers.${index}.firstName`}
            fontSize="md"
            mb="0px"
            fontWeight="normal"
          >
            First Name *
          </FormLabel>
          <Input
            id={`providers.${index}.firstName`}
            type="text"
            {...register(`providers.${index}.firstName`, {
              required: "First name is required",
            })}
          />
          <FormErrorMessage>
            <WarningIcon fontSize="xxs" mr="3.5px" mt="3.5px" color="red.500" />
            {e?.firstName?.message}
          </FormErrorMessage>
        </FormControl>
        <FormControl isInvalid={!!e?.lastName?.message}>
          <FormLabel
            htmlFor={`providers.${index}.lastName`}
            fontSize="md"
            mb="0px"
            fontWeight="normal"
          >
            Last Name *
          </FormLabel>
          <Input
            id={`providers.${index}.lastName`}
            type="text"
            {...register(`providers.${index || 0}.lastName`, {
              required: "Last name is required",
            })}
          />
          <FormErrorMessage>
            <WarningIcon fontSize="xxs" mr="3.5px" mt="3.5px" color="red.500" />
            {e?.lastName?.message}
          </FormErrorMessage>
        </FormControl>
      </Flex>
      <FormControl isInvalid={!!e?.indigoSpecialty?.message}>
        <FormLabel
          htmlFor={`providers.${index}.indigoSpecialty`}
          fontSize="md"
          mb="0px"
          fontWeight="normal"
        >
          Indigo Specialty *
        </FormLabel>
        <Select
          id={`providers.${index}.indigoSpecialty`}
          size="md"
          m="0px"
          p="0px"
          {...register(`providers.${index}.indigoSpecialty`, {
            required: "Specialty is required",
          })}
          onChange={(e) => {
            clearErrors(`providers.${index}.indigoSpecialty`);
            onChangeSelect?.(e) || register(`providers.${index}.indigoSpecialty`).onChange(e);
          }}
        >
          <option></option>
          {INDIGO_SPECIALTIES.map((spec) => (
            <option key={spec.value} value={spec.name}>
              {spec.name}
            </option>
          ))}
        </Select>
        <FormErrorMessage>
          <WarningIcon fontSize="xxs" mr="3.5px" mt="3.5px" color="red.500" />
          {e?.indigoSpecialty?.message}
        </FormErrorMessage>
      </FormControl>
      <Grid
        gap="12px"
        templateColumns={{ base: "1fr", md: "repeat(1, 1fr)", lg: "repeat(12, 1fr)" }}
      >
        <GridItem colSpan={3}>
          <Controller
            rules={{ required: "Address is required" }}
            render={({ fieldState }) => (
              <FormControl isInvalid={Boolean(fieldState.error)}>
                <FormLabel
                  htmlFor={`providers.${index}.address.streetName`}
                  fontSize="md"
                  mb="0px"
                  fontWeight="normal"
                >
                  Address *
                </FormLabel>
                <AddressInputField
                  isInvalid={Boolean(fieldState.error)}
                  id={`providers.${index}.address.streetName`}
                  onChange={(option: AddressOption | null) => {
                    clearErrors(`providers.${index}.address`);
                    setSelectedAddress(option);
                    setValue?.(`providers.${index}.address`, option?.value || null);
                    setValue?.(`providers.${index}.address.city`, option?.value?.city || "");
                    setValue?.(`providers.${index}.address.state`, option?.value?.state || "");
                    setValue?.(`providers.${index}.address.zip`, option?.value?.zip || "");
                    setValue?.(
                      `providers.${index}.address.secondary`,
                      option?.value?.secondary || "",
                    );
                    setValue?.(
                      `providers.${index}.address.streetName`,
                      option?.value?.streetName || "",
                    );
                  }}
                  value={selectedAddress}
                />
                <FormErrorMessage>
                  <WarningIcon fontSize="xxs" mr="3.5px" mt="3.5px" color="red.500" />
                  {fieldState.error && fieldState.error.message}
                </FormErrorMessage>
              </FormControl>
            )}
            control={control}
            name={`providers.${index}.address.streetName`}
          />
        </GridItem>
        <GridItem colSpan={3}>
          <FormControl>
            <FormLabel
              htmlFor={`providers.${index}.address.secondary`}
              fontSize="md"
              mb="0px"
              fontWeight="normal"
              style={{ whiteSpace: "nowrap" }}
            >
              Address Line 2
            </FormLabel>
            <Input
              id={`providers.${index}.address.secondary`}
              type="text"
              {...register(`providers.${index}.address.secondary`)}
            />
          </FormControl>
        </GridItem>
        <GridItem colSpan={3}>
          <Controller
            name={`providers.${index}.address.city`}
            rules={{ required: "City is required" }}
            control={control}
            render={({ field, fieldState }) => (
              <FormControl isInvalid={!!(fieldState.error && fieldState.error.message)}>
                <FormLabel
                  htmlFor={`providers.${index}.address.city`}
                  fontSize="md"
                  mb="0px"
                  fontWeight="normal"
                  style={{ whiteSpace: "nowrap" }}
                >
                  City *
                </FormLabel>
                <Input
                  id={`providers.${index}.address.city`}
                  type="text"
                  {...field}
                  value={field?.value || ""}
                />
                <FormErrorMessage>
                  <WarningIcon fontSize="xxs" mr="3.5px" mt="3.5px" color="red.500" />
                  {fieldState.error && fieldState.error.message}
                </FormErrorMessage>
              </FormControl>
            )}
          />
        </GridItem>
        <GridItem colSpan={1}>
          <FormControl isInvalid={!!e?.address?.state?.message}>
            <FormLabel
              htmlFor={`providers.${index}.address.state`}
              fontSize="md"
              mb="0px"
              fontWeight="normal"
              style={{ whiteSpace: "nowrap" }}
            >
              State *
            </FormLabel>
            <Select
              id={`providers.${index}.address.state`}
              size="md"
              m="0px"
              p="0px"
              {...register(`providers.${index}.address.state`, {
                required: "State is required",
              })}
              onChange={onChangeSelect || register(`providers.${index}.address.state`).onChange}
            >
              <option></option>
              {STATES.map((state) => (
                <option key={state.abbreviation} value={state.abbreviation}>
                  {state.abbreviation}
                </option>
              ))}
            </Select>
            <FormErrorMessage>
              <WarningIcon fontSize="xxs" mr="3.5px" mt="3.5px" color="red.500" />
              {e?.address?.state?.message}
            </FormErrorMessage>
          </FormControl>
        </GridItem>
        <GridItem colSpan={2}>
          <FormControl isInvalid={!!e?.address?.zip?.message}>
            <FormLabel
              htmlFor={`providers.${index}.address.zip`}
              fontSize="md"
              mb="0px"
              fontWeight="normal"
              style={{ whiteSpace: "nowrap" }}
            >
              Zip Code *
            </FormLabel>
            <Input
              id={`providers.${index}.address.zip`}
              type="text"
              {...register(`providers.${index}.address.zip`, {
                required: "Zip code is required",
              })}
            />
            <FormErrorMessage>
              <WarningIcon fontSize="xxs" mr="3.5px" mt="3.5px" color="red.500" />
              {e?.address?.zip?.message}
            </FormErrorMessage>
          </FormControl>
        </GridItem>
      </Grid>
      <Grid
        gap="12px"
        pt="16px"
        templateColumns={{ base: "1fr", md: "repeat(1, 1fr)", lg: "repeat(12, 1fr)" }}
      >
        <GridItem colSpan={6}>
          <Controller
            name={`providers.${index}.address.isSecondary`}
            control={control}
            render={({ field: { onChange, value } }) => (
              <Checkbox
                isChecked={!!value}
                value={`${value}`}
                onChange={(e) => {
                  const bValue = e.target.value === "true" ? true : false;
                  onChange(!bValue);
                }}
                key={index}
              >
                Secondary Practice Address
              </Checkbox>
            )}
          />
        </GridItem>
        <GridItem colSpan={6}>
          <Controller
            name={`providers.${index}.isUserClient`}
            control={control}
            render={({ field: { onChange, value } }) => (
              <Checkbox
                isChecked={!!value}
                value={`${value}`}
                onChange={(e) => {
                  const bValue = e.target.value === "true" ? true : false;
                  onChange(!bValue);
                }}
                key={index}
              >
                Provider is my client
              </Checkbox>
            )}
          />
        </GridItem>
      </Grid>
    </>
  );
}
