import { createContext, useContext, ReactNode } from "react";

const StalePricingContext = createContext<any>(null);

export function useStalePricing() {
  const stalePricing = useContext(StalePricingContext);

  return stalePricing;
}

interface StalePricingProviderProps {
  children: ReactNode;
  stalePricing: boolean;
}

export function StalePricingProvider({ children, stalePricing }: StalePricingProviderProps) {
  return (
    <StalePricingContext.Provider value={stalePricing}>{children}</StalePricingContext.Provider>
  );
}
