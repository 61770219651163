import { Flex, Badge, Text } from "@chakra-ui/react";
import { RiskLevel } from "@app-stack/types/risk_color";

interface RiskProps {
  riskBucket: RiskLevel;
}
export function RiskState({ riskBucket }: RiskProps) {
  let color;
  let copy;
  let subtext;
  switch (riskBucket) {
    case RiskLevel.LOW:
      color = "green";
      copy = "This provider appears to have a low risk profile.";
      subtext =
        "Please provide a few more pieces of information and we will fast track a competitive quote back to you. ";
      break;

    case RiskLevel.MODERATE:
      color = "orange";
      copy = "This provider appears to have a moderate risk profile.";
      subtext =
        "Please provide a few more pieces of information and we will get back to you with a moderately competitive quote. ";
      break;
    case RiskLevel.HIGH:
      color = "red";
      copy = "This submission is currently outside our risk appetite.";
      subtext =
        "We suggest not continuing with this application currently, but our risk profile is always changing, so please check back in the future! ";
      break;
    default:
      color = "gray";
      copy = "This provider appears to have an unknown risk profile.";
      subtext = "We will need a few more data points to properly assess the risk. ";
  }
  return (
    <Flex alignItems="center" justifyContent="center" gap="13px" direction={["column", "row"]}>
      <Badge
        style={{ borderRadius: "2px", fontSize: "xm", textTransform: "none", fontWeight: "bold" }}
        variant="solid"
        colorScheme={color}
        data-testid="riskProfileBadge"
      >
        Risk Profile{" "}
        <span aria-hidden="false" data-testid={riskBucket} style={{ display: "none" }}>
          {riskBucket}
        </span>
      </Badge>
      <Flex direction={["column", "column"]}>
        <Flex fontSize="xm" fontWeight="700" direction={["column", "row"]}>
          <Text pr="3px">{copy}</Text>{" "}
        </Flex>
        <Text fontSize="xm">{subtext}</Text>
      </Flex>
    </Flex>
  );
}
