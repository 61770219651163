import { z } from "zod";
import { AmountValueCyberAggregateSchema, AmountValueCyberOccurrenceSchema, AmountValueSchema, DeductibleStructureFactorSchema, } from "../general";
/**
 * Entity coverage for entity quotes
 */
export var HealthcareEntityCoverageSchema = z.object({
    OfferedServiceId: z.literal("Admitted - Medical Professional Liability Fleet^TPLP"),
    CoverageClaimType: z.literal("2"),
    PriorActsDate: z.string(),
    InsuredItemId: z.literal("GroupGoesHereHOSPITAL"),
    ListofLimitsDeductibles: z.tuple([
        z.object({
            OfferedDeductibleLimitId: z.literal("Admitted - Medical Professional Liability Fleet^TPLP^Aggregate Limit By Policy Term"),
            LimitDeductibleType: z.literal("4"),
            LimitDeductibleCategory: z.literal("8"),
            AmountValue: AmountValueSchema,
        }),
        z.object({
            OfferedDeductibleLimitId: z.literal("Admitted - Medical Professional Liability Fleet^TPLP^Occurrence Limit"),
            AmountValue: AmountValueSchema,
        }),
    ]),
    MPLIndividualProviderDeductibleStructureFactor: DeductibleStructureFactorSchema,
});
/**
 * Entity cyber coverage for entity quotes
 */
export var HealthcareEntityCyberCoverageSchema = z.object({
    OfferedServiceId: z.literal("Admitted - Medical Professional Liability Fleet^CMD"),
    CoverageClaimType: z.literal("2"),
    ExcludeFromCommission: z.literal(false),
    PriorActsDate: z.string(),
    ListofLimitsDeductibles: z.tuple([
        z.object({
            OfferedDeductibleLimitId: z.literal("Admitted - Medical Professional Liability Fleet^CMD^Aggregate Limit By Policy Term"),
            LimitDeductibleType: z.literal("4"),
            LimitDeductibleCategory: z.literal("8"),
            AmountValue: AmountValueCyberAggregateSchema,
        }),
        z.object({
            OfferedDeductibleLimitId: z.literal("Admitted - Medical Professional Liability Fleet^CMD^Occurrence Limit"),
            AmountValue: AmountValueCyberOccurrenceSchema,
            LimitDeductibleCategory: z.literal("2"),
        }),
    ]),
    InsuredItemId: z.literal("GroupGoesHereHOSPITAL"),
});
/**
 * Entity south carolina medical malpractice coverage for entity quotes
 */
export var HealthcareEntitySCMMACoverageSchema = z.object({
    OfferedServiceId: z.literal("Admitted - Medical Professional Liability Fleet^SCMMA"),
    CoverageClaimType: z.literal("3"),
    ExcludeFromCommission: z.literal(false),
    PriorActsDate: z.string(),
    ListofLimitsDeductibles: z.tuple([
        z.object({
            OfferedDeductibleLimitId: z.literal("Admitted - Medical Professional Liability Fleet^SCMMA^Aggregate Limit By Policy Term"),
            LimitDeductibleType: z.literal("4"),
            LimitDeductibleCategory: z.literal("8"),
            AmountValue: AmountValueSchema,
        }),
        z.object({
            OfferedDeductibleLimitId: z.literal("Admitted - Medical Professional Liability Fleet^SCMMA^Occurrence Limit"),
            AmountValue: AmountValueSchema,
            LimitDeductibleCategory: z.literal("2"),
        }),
    ]),
    InsuredItemId: z.literal("GroupGoesHereHOSPITAL"),
});
/**
 * Entity pennsylvania medical malpractice coverage for entity quotes
 */
export var HealthcareEntityPCFPACoverageSchema = z.object({
    OfferedServiceId: z.literal("Admitted - Medical Professional Liability Fleet^PCFPA"),
    CoverageClaimType: z.literal("2"),
    ExcludeFromCommission: z.literal(true),
    PCFPriorActsDates: z.string(),
    ListofLimitsDeductibles: z.tuple([
        z.object({
            OfferedDeductibleLimitId: z.literal("Admitted - Medical Professional Liability Fleet^PCFPA^Aggregate Limit By Policy Term"),
            LimitDeductibleType: z.literal("4"),
            LimitDeductibleCategory: z.literal("8"),
            AmountValue: AmountValueSchema,
        }),
        z.object({
            OfferedDeductibleLimitId: z.literal("Admitted - Medical Professional Liability Fleet^PCFPA^Occurrence Limit"),
            AmountValue: AmountValueSchema,
            LimitDeductibleCategory: z.literal("2"),
        }),
    ]),
    InsuredItemId: z.literal("GroupGoesHereHOSPITAL"),
});
