import { z } from "zod";
export var ExternalServicingActorsSchema = z.tuple([
    z.object({
        Class: z.literal("clsMedicalProfessionalLiabilityContractServicingActor"),
        EntityType: z.literal("8"),
        CarrierId: z.literal("IMIC"),
    }),
    z.object({
        Class: z.literal("clsMedicalProfessionalLiabilityContractServicingActor"),
        EntityType: z.literal("9"),
        CarrierId: z.literal("IMIC"),
    }),
    z.object({
        Class: z.literal("clsMedicalProfessionalLiabilityContractServicingActor"),
        EntityType: z.literal("10"),
        AgencyId: z.string().min(1),
        CommissionRuleId: z.string().min(1),
        Commission: z.string().min(1),
    }),
    z
        .object({
        Class: z.literal("clsMedicalProfessionalLiabilityContractServicingActor"),
        EntityType: z.literal("1"),
        AgencyId: z.string().min(1),
        AgentId: z.string().min(1),
    })
        .optional(),
    z
        .object({
        Class: z.literal("clsMedicalProfessionalLiabilityContractServicingActor"),
        EntityType: z.literal("1"),
        AgencyId: z.string().min(1),
        AgentId: z.string().min(1),
    })
        .optional(),
]);
export var InternalServicingActorsSchema = z.tuple([
    z.object({
        Class: z.literal("clsMedicalProfessionalLiabilityContractServicingActor"),
        InternalEntityType: z.literal("3"),
        UserId: z.string().min(1),
    }),
    z.object({
        Class: z.literal("clsMedicalProfessionalLiabilityContractServicingActor"),
        InternalEntityType: z.literal("1905"),
        UserId: z.literal("Travis.watts"),
    }),
    z.object({
        Class: z.literal("clsMedicalProfessionalLiabilityContractServicingActor"),
        InternalEntityType: z.literal("1905"),
        UserId: z.literal("saustin"),
    }),
    z.object({
        Class: z.literal("clsMedicalProfessionalLiabilityContractServicingActor"),
        InternalEntityType: z.literal("1905"),
        UserId: z.literal("kgrassmuck"),
    }),
    z.object({
        Class: z.literal("clsMedicalProfessionalLiabilityContractServicingActor"),
        InternalEntityType: z.literal("1905"),
        UserId: z.literal("egomez"),
    }),
]);
