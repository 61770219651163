import { ReactNode } from "react";
import { Box } from "@chakra-ui/react";

interface CardFooterProps {
  children?: ReactNode | string;
}
export function CardFooter({ children }: CardFooterProps) {
  return (
    <Box
      px="32px"
      pt="35px"
      pb="5px"
      fontSize="xm"
      backgroundColor="gray.100"
      borderTop="1px"
      borderColor="gray.150"
    >
      {children}
    </Box>
  );
}
