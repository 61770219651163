import validator from "validator";
import { z } from "zod";
import { EntityExternalIdentifierSchema } from "../general";
import { MidlevelSubRiskSchema } from "../midlevel/risk";
import { ProviderSubRiskSchema } from "../provider/risk";
/**
 * Entity risk for entity quotes
 */
export var EntityListOfRisksForWebAPISchema = z.tuple([
    z.object({
        Class: z.literal("clsInsuredItemHospitalOrClinic"),
        InsuredItemTypeId: z.literal("Entity"),
        InsuredItemExternalIdentifier: EntityExternalIdentifierSchema,
        "MPL-Entity-EntityType": z.string().refine(function (val) { return validator.isIn(val, ["3101", "3102"]); }),
        "MPL-Entity-PercentageofPremium": z
            .number()
            .int()
            .refine(function (val) { return validator.isIn(val.toString(), ["10", "0"]); }),
        RiskEffectiveDate: z.string(),
        OriginalEffectiveDate: z.string(),
        FirstRatingState: z.string(),
        FirstRatingCounty: z.string(),
        InsuredItemStatus: z.literal("2"),
        RiskCreatedByThisContractVersionNumber: z.literal(1),
        IsPrimary: z.literal(1),
        CustomerId: z.string().includes("|clsIndividualProviderCustomer"),
        FIPS: z.string().min(1),
        TargetPremium: z.number().default(0),
        CurrentPremiumEntity: z.number().default(0),
        ListofSubRisksForWebAPI: z.array(ProviderSubRiskSchema.or(MidlevelSubRiskSchema)),
    }),
]);
