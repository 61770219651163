var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { cardTheme } from "./card";
import { inputTheme } from "./input";
import { selectTheme } from "./select";
import { modalTheme } from "./modal";
import { containerTheme } from "./container";
import { badgeTheme } from "./badge";
import { formErrorTheme } from "./form-error";
export var components = {
    Badge: __assign({ baseStyle: {
            borderRadius: "16px",
            padding: "6px 18px 8px",
            fontSize: "1.125rem",
            fontWeight: "800",
        } }, badgeTheme),
    Container: containerTheme,
    Card: cardTheme,
    Input: inputTheme,
    Select: selectTheme,
    Modal: modalTheme,
    FormError: formErrorTheme,
    Button: {
        baseStyle: {
            paddingBottom: "5px",
            borderRadius: "3px",
            margin: "5px",
        },
        variants: {
            ghost: {
                bg: "transparrent",
            },
            outline: {
                bg: "white",
                borderColor: "indigo.500",
                color: "indigo.500",
            },
        },
    },
    Checkbox: {
        baseStyle: {
            control: {
                marginTop: "8px",
            },
            label: {
                fontWeight: "medium",
                color: "gray.900",
                width: "100%",
            },
        },
    },
};
