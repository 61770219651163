import { ReactNode } from "react";
import { Flex, Box, Text } from "@chakra-ui/react";
import { SuggestedAction } from "localTypes";
import { CardAction, Datum, RangeDisplay, Rolodex } from "components";
import { AVERAGE_MRS_QUOTE_THRESHOLD, AVERAGE_MRS_QUOTE_THRESHOLD_LARGE } from "app-constants";

interface GroupCardSummaryProps {
  hasLargeGroup: boolean;
  averageMRS?: number;
  suggestedAction?: SuggestedAction;
  mrsReadable?: number;
}
export function GroupCardSummary({
  hasLargeGroup,
  mrsReadable,
  averageMRS,
  suggestedAction,
}: GroupCardSummaryProps) {
  const threshold = hasLargeGroup ? AVERAGE_MRS_QUOTE_THRESHOLD_LARGE : AVERAGE_MRS_QUOTE_THRESHOLD;
  let actionComponent: ReactNode = null;
  switch (suggestedAction) {
    case SuggestedAction.Quote:
      actionComponent = <CardAction message="Meets Group Threshold" variant="success" />;
      break;
    case SuggestedAction.Decline:
      actionComponent = <CardAction message="Does Not Meets Group Threshold" variant="error" />;
      break;
    case SuggestedAction.Review:
      actionComponent = <CardAction message="Needs Review" variant="warning" />;
      break;
    default:
      break;
  }

  let backgroundColor = "gray.100";
  switch (suggestedAction) {
    case SuggestedAction.Quote:
      backgroundColor = "green.50";
      break;
    case SuggestedAction.Decline:
      backgroundColor = "red.100";
      break;
    case SuggestedAction.Review:
      backgroundColor = "yellow.100";
      break;
    default:
      break;
  }

  return (
    <Flex
      padding="16px"
      backgroundColor={backgroundColor}
      justifyContent={{ lg: "space-between", md: "space-around", base: "space-around" }}
      alignItems="center"
      gap="18px"
      borderRadius="3px"
      direction={{ lg: "row", md: "column", base: "column" }}
    >
      <Box textAlign="left" w="100%">
        <Datum
          label={
            <Text color="black" fontWeight="bold">
              Average MRS
            </Text>
          }
          value={<Rolodex num={mrsReadable} />}
        />
        <RangeDisplay
          colorScheme={
            averageMRS && averageMRS >= threshold
              ? "red"
              : suggestedAction === SuggestedAction.Review
                ? "yellow"
                : "green"
          }
          threshold={threshold}
          value={averageMRS}
          min={0}
          max={2}
        />
      </Box>
      <Flex
        w="100%"
        h="100%"
        justifyContent="end"
        textAlign="right"
        pl="20px"
        borderLeft={{ lg: "1px", base: "0px" }}
        borderLeftColor={{ lg: "gray.300", base: "none" }}
      >
        {actionComponent}
      </Flex>
    </Flex>
  );
}
