import { ReactElement } from "react";
import { useRadio, Box, UseRadioProps } from "@chakra-ui/react";

interface CustomRadioProps {
  children: ReactElement | string;
  radioProps: UseRadioProps;
}

export function CustomRadio({ children, radioProps }: CustomRadioProps) {
  const { getInputProps, getRadioProps } = useRadio(radioProps);

  const inputProps = getInputProps();
  const checkboxProps = getRadioProps();
  return (
    <Box as="label">
      <input {...inputProps} />
      <Box
        {...checkboxProps}
        cursor="pointer"
        borderWidth="1px"
        borderRadius="md"
        boxShadow="sm"
        borderColor="gray.150"
        backgroundColor="#F9F8F7"
        width="100%"
        _checked={{}}
        _focus={{
          boxShadow: "outline",
        }}
        px={5}
        py={3}
      >
        {children}
      </Box>
    </Box>
  );
}
