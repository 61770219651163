import { Routes, Route, useParams } from "react-router-dom";
import { ProviderInfo } from "./provider-info";
import { ResolveNpiWrapper } from "./resolve-npi";
import { Submit } from "./submit";
import { Confirm } from "./confirm";
import { NotFound } from "routes/not-found";
import { useQuery } from "@apollo/client";
import { GET_SUBMISSION } from "queries";
import { Loader } from "components";

export function Inquiry() {
  const { id } = useParams();
  const { loading, data } = useQuery(GET_SUBMISSION, {
    variables: { id },
  });

  return loading ? (
    <Loader />
  ) : (
    <div>
      <Routes>
        <Route path="provider-info" element={<ProviderInfo submission={data?.getSubmission} />} />
        <Route
          path="resolve-npi"
          element={<ResolveNpiWrapper submission={data?.getSubmission} />}
        />
        <Route path="submit" element={<Submit submission={data?.getSubmission} />} />
        <Route path="confirm" element={<Confirm />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </div>
  );
}
