import { useCallback } from "react";
import { useParams } from "react-router-dom";
import {
  InputGroup,
  InputLeftAddon,
  ModalFooter,
  Button,
  Text,
  ModalContent,
  Modal,
  ModalOverlay,
  ModalHeader,
  ModalBody,
  Flex,
  ModalCloseButton,
  Select,
  FormControl,
  FormLabel,
  Input,
  FormErrorMessage,
} from "@chakra-ui/react";
import { WarningIcon } from "@chakra-ui/icons";
import { ADD_DECLINATION } from "mutations";
import { GET_SUBMISSION } from "queries";
import { useMutation } from "@apollo/client";
import { useForm } from "react-hook-form";
import { DeclinationReason, DeclinationInput } from "__generated__/graphql";
import { formatDeclinationReason } from "utils";
import { DEFAULT_CC_EMAIL_ADDRESSES, DEFAULT_BCC_EMAIL_ADDRESSES } from "app-constants";

interface DeclinationModalProps {
  onClose: () => void;
  declination?: DeclinationInput;
  submitToSunlight: () => void;
  brokerEmail?: string;
}
export function DeclinationModal({
  submitToSunlight,
  declination,
  onClose,
  brokerEmail,
}: DeclinationModalProps) {
  const [addDeclination, { loading }] = useMutation(ADD_DECLINATION, {
    refetchQueries: [GET_SUBMISSION],
    awaitRefetchQueries: true,
  });
  const { id } = useParams();
  const {
    register,
    watch,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<DeclinationInput>({
    defaultValues: {
      emailAddresses: {
        to: brokerEmail || "",
        cc: DEFAULT_CC_EMAIL_ADDRESSES,
        bcc: DEFAULT_BCC_EMAIL_ADDRESSES,
      },
      otherReason: "",
      ...declination,
    },
  });
  const reason = watch("reason");
  const onSubmit = useCallback(
    async (submission: DeclinationInput) => {
      try {
        await addDeclination({
          variables: {
            submissionId: id,
            declination: submission,
          },
        });
        submitToSunlight();
        onClose();
      } catch (e) {
        console.error(e);
      }
    },
    [submitToSunlight, onClose, addDeclination, id],
  );
  return (
    <Modal size="xl" isOpen={true} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader pb="4px">
          <Flex direction="column" mb="8px">
            <Text lineHeight="none" fontSize="3xl">
              Declination
            </Text>
          </Flex>
        </ModalHeader>
        <ModalCloseButton />
        <form data-testid="declination-form" onSubmit={handleSubmit(onSubmit)}>
          <ModalBody pb="20px" pt="16px">
            <Flex direction="column" gap="8px">
              <FormControl isInvalid={!!errors?.emailAddresses?.to?.message}>
                <InputGroup>
                  <InputLeftAddon
                    w="60px"
                    fontWeight="bold"
                    backgroundColor="white"
                    borderColor="indigo.300"
                    borderRight="none"
                  >
                    <FormLabel fontWeight="bold" htmlFor={`emailAddresses.to`}>
                      To
                    </FormLabel>
                  </InputLeftAddon>

                  <Input
                    data-testid="emailAddresses.to"
                    borderLeft="none"
                    id="emailAddresses.to"
                    type="text"
                    {...register("emailAddresses.to", {
                      required: "'To' is required",
                    })}
                  />
                </InputGroup>
                <FormErrorMessage>
                  <WarningIcon fontSize="xxs" mr="3.5px" mt="3.5px" color="red.500" />
                  {errors?.emailAddresses?.to?.message}
                </FormErrorMessage>
              </FormControl>
              <FormControl>
                <InputGroup>
                  <InputLeftAddon
                    w="60px"
                    fontWeight="bold"
                    backgroundColor="white"
                    borderColor="indigo.300"
                    borderRight="none"
                  >
                    <FormLabel fontWeight="bold" htmlFor={`emailAddresses.cc`}>
                      Cc
                    </FormLabel>
                  </InputLeftAddon>
                  <Input
                    borderLeft="none"
                    id="emailAddresses.cc"
                    type="text"
                    {...register("emailAddresses.cc")}
                  />
                </InputGroup>
              </FormControl>
              <FormControl>
                <InputGroup>
                  <InputLeftAddon
                    w="60px"
                    fontWeight="bold"
                    backgroundColor="white"
                    borderColor="indigo.300"
                    borderRight="none"
                  >
                    <FormLabel fontWeight="bold" htmlFor={`emailAddresses.bcc`}>
                      Bcc
                    </FormLabel>
                  </InputLeftAddon>
                  <Input
                    borderLeft="none"
                    id="emailAddresses.bcc"
                    type="text"
                    {...register("emailAddresses.bcc")}
                  />
                </InputGroup>
              </FormControl>
              <FormControl isInvalid={!!errors?.reason?.message}>
                <FormLabel fontWeight="bold" htmlFor="reason" fontSize="md" mb="0px">
                  Declination Reason
                </FormLabel>
                <Select
                  data-testid="reason"
                  id="reason"
                  size="md"
                  m="0px"
                  p="0px"
                  {...register("reason", { required: "'Declination Reason' is required" })}
                  onChange={(e) => {
                    setValue("otherReason", "");
                    register("reason").onChange(e);
                  }}
                >
                  <option></option>
                  {Object.values(DeclinationReason).map((reason) => {
                    return (
                      <option key={reason} value={reason}>
                        {formatDeclinationReason(reason)}
                      </option>
                    );
                  })}
                </Select>
                <FormErrorMessage>
                  <WarningIcon fontSize="xxs" mr="3.5px" mt="3.5px" color="red.500" />
                  {errors?.reason?.message}
                </FormErrorMessage>
              </FormControl>
            </Flex>
            {reason === DeclinationReason.OtherMultiple && (
              <FormControl isInvalid={Boolean(errors.otherReason?.message)}>
                <FormLabel fontWeight="bold" htmlFor="otherReason" fontSize="md" mb="0px">
                  Other Reason
                </FormLabel>
                <Input
                  data-testid="otherReason"
                  id="otherReason"
                  type="text"
                  {...register("otherReason", {
                    required:
                      "'Other Reason' is required if 'Declination Reason' is 'Other/Multiple'",
                  })}
                />
                <FormErrorMessage>
                  <WarningIcon fontSize="xxs" mr="3.5px" mt="3.5px" color="red.500" />
                  {errors?.otherReason?.message}
                </FormErrorMessage>
              </FormControl>
            )}
          </ModalBody>
          <ModalFooter bgColor="gray.100" pb="24px">
            <Button
              isLoading={loading}
              mx="0"
              type="submit"
              data-testid="submit"
              colorScheme="indigo"
              w="100%"
            >
              Send Declination
            </Button>
          </ModalFooter>
        </form>
      </ModalContent>
    </Modal>
  );
}
