import { ChangeEvent, useState, useCallback } from "react";
import { useForm } from "react-hook-form";
import { useMutation } from "@apollo/client";
import { UPDATE_SUBMISSION, DELETE_PROVIDER } from "mutations";
import { GET_SUBMISSION } from "queries";
import { useParams, useNavigate } from "react-router-dom";
import { Button, Card, CardHeader, CardBody, Container, Flex, IconButton } from "@chakra-ui/react";
import { AddIcon, CloseIcon } from "@chakra-ui/icons";
import { useAnalytics } from "providers";
import { SubmissionUpdateInput, ProviderUpdateInput } from "__generated__/graphql";
import { generateId } from "utils";
import { InfoForm } from "./InfoForm";
import { RAT_EVENTS } from "analytics";

interface ProviderInfoProps {
  submission: SubmissionUpdateInput;
}
export function ProviderInfo({ submission }: ProviderInfoProps) {
  const navigate = useNavigate();
  const { track } = useAnalytics();
  const { id } = useParams();
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [deleteProvider] = useMutation(DELETE_PROVIDER);
  const [mutateFunction] = useMutation(UPDATE_SUBMISSION, {
    refetchQueries: [GET_SUBMISSION],
    awaitRefetchQueries: true,
  });
  const {
    control,
    handleSubmit,
    setValue,
    watch,
    register,
    clearErrors,
    formState: { errors },
  } = useForm<SubmissionUpdateInput>({
    defaultValues: { ...submission, providers: submission?.providers || [] },
  });

  const formData = watch();
  const onSubmit = useCallback(
    async (submission: SubmissionUpdateInput) => {
      setIsSaving(true);
      try {
        if (submission.producer && submission.producer.id === undefined) {
          submission.producer.id = generateId();
        }
        await mutateFunction({
          variables: {
            input: {
              id,
              providers: submission.providers,
              entities: submission.entities || undefined,
              midlevels: submission.midlevels || undefined,
              producer: submission.producer || undefined,
            },
          },
        });
        navigate(`/broker/inquiry/${id}/resolve-npi`);
      } catch (e) {
        console.error(e);
      } finally {
        setIsSaving(false);
      }
    },
    [navigate, id, mutateFunction],
  );
  const handleSelectChange = useCallback(
    (event: ChangeEvent<HTMLSelectElement>) => {
      const { name, value } = event.target;
      setValue(name as keyof SubmissionUpdateInput, value);
    },
    [setValue],
  );

  return (
    <Container pt="100px" maxW="833px">
      <Card
        maxWidth="833px"
        boxShadow="0px 0px 5px 0px rgba(0, 0, 0, 0.10)"
        border="1px"
        borderColor="#DEDEDE"
      >
        <CardHeader p="0" bg="indigo.50" fontSize="sm">
          <Flex px="25px" py="15px" alignItems="center" justifyContent="space-between">
            Let’s find the provider. Please let us know...
            <IconButton
              onClick={() => navigate("/broker")}
              size="xs"
              variant="ghost"
              aria-label="Exit inquiry"
              icon={<CloseIcon />}
            />
          </Flex>
        </CardHeader>
        <CardBody>
          <form onSubmit={handleSubmit(onSubmit)}>
            {formData?.providers?.map((provider: ProviderUpdateInput, index: number) => {
              const address = watch(`providers.${index}.address`);
              return (
                <InfoForm
                  clearErrors={clearErrors}
                  setValue={setValue}
                  onChangeSelect={handleSelectChange}
                  register={register}
                  errors={errors}
                  key={provider.id}
                  address={address}
                  control={control}
                  index={index}
                  removeProvider={() => {
                    let updatedProviders: ProviderUpdateInput[] = [];
                    if (formData?.providers) {
                      updatedProviders = [
                        ...formData.providers.filter((p) => p.id !== provider.id),
                      ];
                    }
                    setValue("providers", updatedProviders);
                    deleteProvider({
                      variables: {
                        input: {
                          submissionId: id,
                          providerId: provider.id,
                        },
                      },
                    });
                  }}
                />
              );
            })}
            <Flex mt={5} direction={["column", "row"]}>
              <Button
                isLoading={isSaving}
                onClick={() => {
                  track(RAT_EVENTS.MATCH_PROFILES);
                }}
                colorScheme="indigo"
                type="submit"
              >
                Let's Match Profiles
              </Button>
              <Button
                leftIcon={<AddIcon boxSize="0.75rem" mt="5px" />}
                onClick={() => {
                  let updatedProviders: ProviderUpdateInput[] = [];
                  if (formData?.providers) {
                    updatedProviders = [...formData.providers];
                  }
                  updatedProviders.push({ id: generateId() });
                  setValue("providers", updatedProviders);
                }}
                variant="outline"
              >
                Another Provider
              </Button>
            </Flex>
          </form>
        </CardBody>
      </Card>
    </Container>
  );
}
