import { useEffect, useRef } from "react";
import {
  Box,
  Text,
  TabIndicator,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  IconButton,
  useToast,
  Flex,
} from "@chakra-ui/react";
import { format, parseISO } from "date-fns";
import { Note, NoteType } from "__generated__/graphql";

import { CloseIcon } from "@chakra-ui/icons";
import { DELETE_NOTE } from "mutations";
import { useMutation } from "@apollo/client";
import { useParams } from "react-router-dom";
import "./notes.css";

interface NotesProps {
  notes?: Note[];
  setNoteType: (noteType: NoteType) => void;
  noteType: NoteType;
  brokerNotes?: Note;
}

interface NoteProps {
  note: Note;
}

export function NoteComponent({ note }: NoteProps) {
  return (
    <Box className="fadeIn" mb="40px">
      <Text fontSize="sm" color="gray.500" fontWeight="500">
        {note.content}
      </Text>
      <Text pt="10px" fontSize="sm" color="gray.500" fontWeight="500">
        {" "}
        {note?.author?.split("@")[0]} {note?.createdAt !== undefined ? "-" : ""}{" "}
        {note?.createdAt && format(parseISO(note?.createdAt || ""), "M/d/yyyy")}
      </Text>
    </Box>
  );
}

export function Notes({ brokerNotes, notes, setNoteType, noteType }: NotesProps) {
  const notesEnd = useRef<null | HTMLDivElement>(null);

  const scrollToBottom = () => {
    notesEnd.current?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    scrollToBottom();
  }, [notes]);

  const [deleteNote] = useMutation(DELETE_NOTE, {
    refetchQueries: ["GetSubmission"],
  });
  const { id } = useParams();
  const toast = useToast();

  return (
    <Tabs
      defaultIndex={noteType === NoteType.WriteUp ? 0 : 1}
      flex="1"
      overflow="scroll"
      variant="unstyled"
    >
      <TabList justifyContent="space-between">
        <Tab
          flex="1"
          fontSize="xs"
          fontWeight="bold"
          color="gray.450"
          onClick={() => {
            setNoteType(NoteType.WriteUp);
          }}
          _selected={{ color: "black" }}
        >
          WRITE-UP
        </Tab>
        <Tab
          flex="1"
          fontSize="xs"
          fontWeight="bold"
          color="gray.450"
          onClick={() => setNoteType(NoteType.Subjectivity)}
          _selected={{ color: "black" }}
        >
          SUBJECTIVITIES
        </Tab>
      </TabList>
      <TabIndicator mt="-1.5px" height="2px" bg="indigo.500" />

      <TabPanels>
        <TabPanel>
          {brokerNotes?.content && (
            <Flex
              direction="column"
              background="linear-gradient(0deg, rgba(249,249,253,1) 35%, rgba(255,255,255,1) 100%)"
              borderRadius="5px"
              pb="7px"
              mb="10px"
            >
              <Text fontSize="sm" fontWeight="bold" color="gray.500">
                Producer Note:
              </Text>
              <Flex
                padding="8px"
                flex="none"
                justifyContent="space-between"
                alignItems="flex-start"
                direction="column"
              >
                <Text fontSize="sm" color="gray.500" fontWeight="500">
                  {brokerNotes.content}
                </Text>
                <Text pt="10px" fontSize="sm" color="gray.500" fontWeight="500">
                  {brokerNotes.author}
                </Text>
              </Flex>
            </Flex>
          )}
          {notes &&
            notes
              .filter((note) => note.type === NoteType.WriteUp)
              .map((note) => (
                <Flex
                  flex="none"
                  justifyContent="space-between"
                  alignItems="flex-start"
                  key={note.id}
                >
                  <NoteComponent note={note} />
                  <IconButton
                    onClick={async () => {
                      try {
                        await deleteNote({
                          variables: { input: { submissionId: id, noteId: note.id } },
                        });
                      } catch (e) {
                        toast({
                          title: "Error",
                          description: "Unable to delete the note!",
                          duration: 5000,
                          status: "error",
                          isClosable: true,
                        });
                        console.error(e);
                      }
                    }}
                    variant="ghost"
                    color="gray.400"
                    aria-label="Delete note"
                    m="0px"
                    icon={<CloseIcon fontSize="0.6rem" />}
                  />
                </Flex>
              ))}
        </TabPanel>
        <TabPanel>
          {notes &&
            notes
              .filter((note) => note.type === NoteType.Subjectivity)
              .map((note) => (
                <Flex
                  key={note.id}
                  flex="none"
                  justifyContent="space-between"
                  alignItems="flex-start"
                >
                  <NoteComponent key={note.id} note={note} />
                  <IconButton
                    onClick={async () => {
                      try {
                        await deleteNote({
                          variables: { input: { submissionId: id, noteId: note.id } },
                        });
                      } catch (e) {
                        toast({
                          title: "Error",
                          description: "Unable to delete the note!",
                          duration: 5000,
                          status: "error",
                          isClosable: true,
                        });
                        console.error(e);
                      }
                    }}
                    variant="ghost"
                    color="gray.400"
                    aria-label="Delete note"
                    m="0px"
                    icon={<CloseIcon fontSize="0.6rem" />}
                  />
                </Flex>
              ))}
        </TabPanel>
        <div ref={notesEnd} />
      </TabPanels>
    </Tabs>
  );
}
